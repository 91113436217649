import React, { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { toast } from 'react-toastify'
import moment from 'moment'

import Grid from '@mui/material/Grid'
import Paper from '@mui/material/Paper'
import Stack from '@mui/material/Stack'
import Typography from '@mui/material/Typography'
import EventTwoToneIcon from '@mui/icons-material/EventTwoTone'
import MedicalInformationTwoToneIcon from '@mui/icons-material/MedicalInformationTwoTone'
import IconButton from '@mui/material/IconButton'
import EditIcon from '@mui/icons-material/Edit'
import Button from '@mui/material/Button'
import ArrowBackTwoToneIcon from '@mui/icons-material/ArrowBackTwoTone'
import CheckCircleTwoToneIcon from '@mui/icons-material/CheckCircleTwoTone'
import HighlightOffTwoToneIcon from '@mui/icons-material/HighlightOffTwoTone'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell, { tableCellClasses } from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import DeleteIcon from '@mui/icons-material/Delete'
import MoreVertIcon from '@mui/icons-material/MoreVert'
import MenuItem from '@mui/material/MenuItem'
import Menu from '@mui/material/Menu'
import ListItemIcon from '@mui/material/ListItemIcon'
import Chip from '@mui/material/Chip'
import LinearProgress from '@mui/material/LinearProgress'
import ArrowForwardIosSharpIcon from '@mui/icons-material/ArrowForwardIosSharp'
import MuiAccordion from '@mui/material/Accordion'
import MuiAccordionSummary from '@mui/material/AccordionSummary'
import MuiAccordionDetails from '@mui/material/AccordionDetails'
import { styled } from '@mui/material/styles'

import ConfirmationDialog from '../../../common/ConfirmationDialog'
import {
  getAppointment,
  deleteAppointment,
  reset,
  resetError,
} from '../../../../../features/appointments/AppointmentsSlice'
import { getPatient } from '../../../../../features/patients/PatientSlice'

const Accordion = styled((props) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
  border: `1px solid ${theme.palette.divider}`,
  '&:not(:last-child)': {
    borderBottom: 0,
  },
  '&:before': {
    display: 'none',
  },
}))

const AccordionSummary = styled((props) => (
  <MuiAccordionSummary
    expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: '0.9rem' }} />}
    {...props}
  />
))(({ theme }) => ({
  backgroundColor:
    theme.palette.mode === 'dark'
      ? 'rgba(255, 255, 255, .05)'
      : 'rgba(0, 0, 0, .03)',
  flexDirection: 'row-reverse',
  '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
    transform: 'rotate(90deg)',
  },
  '& .MuiAccordionSummary-content': {
    marginLeft: theme.spacing(1),
  },
}))

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  padding: theme.spacing(2),
  borderTop: '1px solid rgba(0, 0, 0, .125)',
}))

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: '#00796b',
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}))

function AppointmentView() {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const { id, appointmentId } = useParams()

  const { appointment, isLoading, isError, isSuccess, message } = useSelector(
    (state) => state.appointments
  )

  const [activeAppointment, setActiveAppointment] = useState(null)
  const [anchorElAppointment, setAnchorElAppointment] = useState(null)
  const [confirmOpen, setConfirmOpen] = useState(false)

  useEffect(() => {
    dispatch(getAppointment(appointmentId))
  }, [dispatch, appointmentId])

  useEffect(() => {
    if (appointment) {
      setActiveAppointment(appointment)
    }
  }, [appointment])

  useEffect(() => {
    if (isError) {
      toast.error(message)
      dispatch(resetError())
    }

    if (isSuccess) {
      toast.error('Paciente eliminado!')
      dispatch(reset())
      dispatch(getPatient(id))
      navigate(`/dashboard/patients/${id}/appointments`)
    }
  }, [isError, isSuccess, message, dispatch, navigate, id])

  const handleOpenAppointmentMenu = (event) => {
    setAnchorElAppointment(event.currentTarget)
  }

  const handleCloseAppointmentMenu = () => {
    setAnchorElAppointment(null)
  }

  const handleDelete = () => {
    setAnchorElAppointment(null)
    setConfirmOpen(true)
  }

  return (
    <>
      <Stack
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        sx={{ mt: 1, mb: 3 }}
      >
        <div>
          <Button
            variant="contained"
            size="small"
            disabled={false}
            startIcon={<ArrowBackTwoToneIcon />}
            onClick={() => navigate(`/dashboard/patients/${id}/appointments`)}
          >
            Regresar
          </Button>
        </div>
        <div>
          <Stack direction="row" alignItems="center" spacing={1}>
            <Button
              variant="contained"
              color="secondary"
              size="small"
              disabled={false}
              startIcon={<EditIcon />}
              onClick={() =>
                navigate(
                  `/dashboard/patients/${id}/appointments/${appointmentId}/edit`
                )
              }
            >
              Editar
            </Button>
            <IconButton onClick={handleOpenAppointmentMenu}>
              <MoreVertIcon />
            </IconButton>
            <Menu
              sx={{ mt: '45px' }}
              id="menu-patient"
              anchorEl={anchorElAppointment}
              anchorOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              keepMounted
              transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              open={Boolean(anchorElAppointment)}
              onClose={handleCloseAppointmentMenu}
            >
              <MenuItem onClick={handleDelete}>
                <ListItemIcon>
                  <DeleteIcon fontSize="small" />
                </ListItemIcon>
                Eliminar
              </MenuItem>
            </Menu>
          </Stack>
        </div>
      </Stack>
      {activeAppointment &&
        (!isLoading ? (
          <div>
            <Accordion defaultExpanded={true}>
              <AccordionSummary
                aria-controls="principald-content"
                id="principald-header"
              >
                <Typography>Órgano Dental</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Grid
                  container
                  justifyContent="space-evenly"
                  alignItems="center"
                  spacing={2}
                  sx={{ p: 2 }}
                >
                  <Grid item xs={6} md={3}>
                    <Stack
                      direction="row"
                      spacing={1}
                      sx={{ alignItems: 'center' }}
                    >
                      <EventTwoToneIcon />
                      <Typography variant="subtitle2">Fecha</Typography>
                    </Stack>
                  </Grid>
                  <Grid item xs={6} md={3}>
                    <Typography variant="body2">
                      {moment(activeAppointment.createdAt).format(
                        'DD/MMM/YYYY hh:mm a'
                      )}
                    </Typography>
                  </Grid>

                  <Grid item xs={6} md={3}>
                    <Stack
                      direction="row"
                      spacing={1}
                      sx={{ alignItems: 'center' }}
                    >
                      <MedicalInformationTwoToneIcon />
                      <Typography variant="subtitle2">Órgano Dental</Typography>
                    </Stack>
                  </Grid>
                  <Grid item xs={6} md={3}>
                    {activeAppointment.dentalOrgan.map((dentalOrgan, index) => (
                      <Chip key={index} label={dentalOrgan} sx={{ mr: 1 }} />
                    ))}
                  </Grid>
                </Grid>
              </AccordionDetails>
            </Accordion>
            <Accordion defaultExpanded={true}>
              <AccordionSummary
                aria-controls="clinicalExamd-content"
                id="clinicalExamd-header"
              >
                <Typography>Examen Clínico</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Grid
                  container
                  justifyContent="space-evenly"
                  alignItems="center"
                  spacing={2}
                  sx={{ p: 2 }}
                >
                  {Object.values(activeAppointment.clinicalExam).map(
                    (test, index) => {
                      const names = [
                        'Frío',
                        'Calor',
                        'Aire',
                        'Percusión',
                        'Espontáneo',
                        'Cambio de color',
                      ]
                      return (
                        <Grid item xs={12} sm={4} md={2} key={index}>
                          <Stack
                            direction="row"
                            alignItems="center"
                            spacing={1}
                          >
                            <Typography variant="body2">
                              {names[index]}
                            </Typography>
                            {test ? (
                              <CheckCircleTwoToneIcon color="primary" />
                            ) : (
                              <HighlightOffTwoToneIcon />
                            )}
                          </Stack>
                        </Grid>
                      )
                    }
                  )}
                </Grid>
              </AccordionDetails>
            </Accordion>
            <Accordion defaultExpanded={true}>
              <AccordionSummary
                aria-controls="radioGraph-content"
                id="radioGraph-header"
              >
                <Typography>
                  Interpretación Radiográfica - Dx Pulpar - Dx Periapical -
                  Pronóstico - Tratamiento
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Stack direction="column" alignItems="flex-start" spacing={0}>
                  <Typography variant="subtitle2">
                    Interpretación Radiográfica:
                  </Typography>
                  <Typography variant="body2">
                    {activeAppointment.radiographicInterpretation
                      ? activeAppointment.radiographicInterpretation
                      : `-`}
                  </Typography>
                </Stack>
                <Stack direction="column" alignItems="flex-start" spacing={0}>
                  <Typography variant="subtitle2">Dx Pulpar:</Typography>
                  <Typography variant="body2">
                    {activeAppointment.dxPulpar
                      ? activeAppointment.dxPulpar
                      : `-`}
                  </Typography>
                </Stack>
                <Stack direction="column" alignItems="flex-start" spacing={0}>
                  <Typography variant="subtitle2">Dx Periapical:</Typography>
                  <Typography variant="body2">
                    {activeAppointment.dxPeriapical
                      ? activeAppointment.dxPeriapical
                      : `-`}
                  </Typography>
                </Stack>
                <Stack direction="column" alignItems="flex-start" spacing={0}>
                  <Typography variant="subtitle2">Pronóstico:</Typography>
                  <Typography variant="body2">
                    {activeAppointment.diagnosis
                      ? activeAppointment.diagnosis
                      : `-`}
                  </Typography>
                </Stack>
                <Stack direction="column" alignItems="flex-start" spacing={0}>
                  <Typography variant="subtitle2">Tratamiento:</Typography>
                  <Typography variant="body2">
                    {activeAppointment.treatment
                      ? activeAppointment.treatment
                      : `-`}
                  </Typography>
                </Stack>
                <Stack direction="column" alignItems="flex-start" spacing={0}>
                  <Typography variant="subtitle2">Observaciones:</Typography>
                  <Typography variant="body2">
                    {activeAppointment.observations
                      ? activeAppointment.observations
                      : `-`}
                  </Typography>
                </Stack>
              </AccordionDetails>
            </Accordion>
            <Accordion defaultExpanded={true}>
              <AccordionSummary
                aria-controls="rootCanal-content"
                id="rootCanal-header"
              >
                <Typography>Conductoterapia</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <TableContainer
                  component={Paper}
                  sx={{ backgroundColor: '#f5f5f5' }}
                >
                  <Table
                    sx={{ minWidth: 350 }}
                    size="small"
                    aria-label="root canal therapy"
                  >
                    <TableHead>
                      <TableRow>
                        <StyledTableCell>Conducto</StyledTableCell>
                        <StyledTableCell align="right">MM</StyledTableCell>
                        <StyledTableCell align="center">
                          Referencia
                        </StyledTableCell>
                        <StyledTableCell align="right">
                          1er inst
                        </StyledTableCell>
                        <StyledTableCell align="center">
                          Lima maestra
                        </StyledTableCell>
                        <StyledTableCell align="right">
                          Cono principal
                        </StyledTableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {activeAppointment.rootCanalTherapy.map((row, i) => (
                        <TableRow
                          key={i}
                          sx={{
                            '&:last-child td, &:last-child th': {
                              border: 0,
                            },
                          }}
                        >
                          <TableCell component="th" scope="row">
                            {row.conduct ? row.conduct : `-`}
                          </TableCell>
                          <TableCell align="right">
                            {row.mm ? row.mm : `-`}
                          </TableCell>
                          <TableCell align="center">
                            {row.reference ? row.reference : `-`}
                          </TableCell>
                          <TableCell align="right">
                            {row.firstInst ? row.firstInst : `-`}
                          </TableCell>
                          <TableCell align="center">
                            {row.masterLime ? row.masterLime : `-`}
                          </TableCell>
                          <TableCell align="right">
                            {row.principalCone ? row.principalCone : `-`}
                          </TableCell>
                        </TableRow>
                      ))}
                      {activeAppointment.rootCanalTherapy.length <= 0 && (
                        <TableRow
                          sx={{
                            '&:last-child td, &:last-child th': {
                              border: 0,
                            },
                          }}
                        >
                          <TableCell component="th" scope="row">
                            -
                          </TableCell>
                          <TableCell align="right">-</TableCell>
                          <TableCell align="center">-</TableCell>
                          <TableCell align="right">-</TableCell>
                          <TableCell align="center">-</TableCell>
                          <TableCell align="right">-</TableCell>
                        </TableRow>
                      )}
                    </TableBody>
                  </Table>
                </TableContainer>
              </AccordionDetails>
            </Accordion>
            <Accordion defaultExpanded={true}>
              <AccordionSummary aria-controls="cita-content" id="cita-header">
                <Typography>Citas</Typography>
              </AccordionSummary>
              <AccordionDetails>
                {activeAppointment.comments.map((comment, i) => (
                  <Stack
                    key={i}
                    direction="row"
                    alignItems="center"
                    spacing={2}
                  >
                    <Typography variant="subtitle2">Cita {i + 1}:</Typography>
                    <Typography variant="body2">
                      {comment ? comment : `-`}
                    </Typography>
                  </Stack>
                ))}
                {activeAppointment.comments.length <= 0 && (
                  <Stack direction="row" alignItems="center" spacing={2}>
                    <Typography variant="subtitle2">Cita 1:</Typography>
                    <Typography variant="body2">-</Typography>
                  </Stack>
                )}
              </AccordionDetails>
            </Accordion>
            <Accordion defaultExpanded={true}>
              <AccordionSummary aria-controls="costs-content" id="costs-header">
                <Typography>Costos</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Grid container alignItems="center" spacing={2}>
                  <Grid item xs={12}>
                    <Paper
                      sx={{ p: 2, width: '100%', backgroundColor: '#f5f5f5' }}
                    >
                      <Grid
                        container
                        direction="row"
                        justifyContent="flex-end"
                        alignItems="center"
                      >
                        <Grid item xs={10}>
                          <Typography variant="subtitle2" align="right">
                            Costo Tx :
                          </Typography>
                        </Grid>
                        <Grid item xs={2}>
                          <Typography
                            variant="body2"
                            align="right"
                            color="textSecondary"
                          >
                            ${Number(activeAppointment.costTx).toLocaleString()}
                          </Typography>
                        </Grid>
                      </Grid>
                      <Grid
                        container
                        direction="row"
                        justifyContent="flex-end"
                        alignItems="center"
                      >
                        <Grid item xs={10}>
                          <Typography variant="subtitle2" align="right">
                            Costo Poste :
                          </Typography>
                        </Grid>
                        <Grid item xs={2}>
                          <Typography
                            variant="body2"
                            align="right"
                            color="textSecondary"
                          >
                            $
                            {Number(
                              activeAppointment.costPost
                            ).toLocaleString()}
                          </Typography>
                        </Grid>
                      </Grid>

                      <Grid
                        container
                        direction="row"
                        justifyContent="flex-end"
                        alignItems="center"
                      >
                        <Grid item xs={10}>
                          <Typography variant="subtitle2" align="right">
                            Costo Resina :
                          </Typography>
                        </Grid>
                        <Grid item xs={2}>
                          <Typography
                            variant="body2"
                            align="right"
                            color="textSecondary"
                          >
                            $
                            {activeAppointment.costResin
                              ? Number(
                                  activeAppointment.costResin
                                ).toLocaleString()
                              : 0}
                          </Typography>
                        </Grid>
                      </Grid>
                      <Grid
                        container
                        direction="row"
                        justifyContent="flex-end"
                        alignItems="center"
                        sx={{ mt: 1 }}
                      >
                        <Grid item xs={10} sx={{ pt: 1 }}>
                          <Typography variant="subtitle2" align="right">
                            Total :
                          </Typography>
                        </Grid>
                        <Grid
                          item
                          xs={2}
                          sx={{
                            pt: 1,
                            borderTop: 1,
                            borderColor: 'common.white',
                          }}
                        >
                          <Typography variant="subtitle2" align="right">
                            ${Number(activeAppointment.total).toLocaleString()}
                          </Typography>
                        </Grid>
                      </Grid>
                    </Paper>
                  </Grid>
                </Grid>
              </AccordionDetails>
            </Accordion>
          </div>
        ) : (
          <div>
            <Paper variant="outlined" sx={{ minHeight: 600 }}>
              <LinearProgress />
            </Paper>
          </div>
        ))}
      <ConfirmationDialog
        keepMounted
        title="Eliminar Registro"
        children={
          <>
            <Typography variant="body1">
              ¿Está seguro que desea eliminar el registro?
            </Typography>
            <Typography variant="caption">
              *Si tenia saldo pendiente por pagar se eliminará dicha cantidad de
              los totales del paciente.
            </Typography>
          </>
        }
        open={confirmOpen}
        setOpen={setConfirmOpen}
        onConfirm={() => {
          dispatch(deleteAppointment(appointmentId))
        }}
      />
    </>
  )
}

export default AppointmentView
