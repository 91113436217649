import HeroHeader from '../../components/public/common/HeroHeader'
import Services from '../../components/public/services/Services'

const headerData = {
  title: `Servicios`,
  imgUrl: `/static/img/headers/services.webp`,
}

function ServicesPage() {
  return (
    <>
      <HeroHeader title={headerData.title} imgUrl={headerData.imgUrl} />
      <Services />
    </>
  )
}

export default ServicesPage
