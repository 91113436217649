import { useState } from 'react'
import { Routes, Route, Navigate } from 'react-router-dom'
import { Link, useParams } from 'react-router-dom'
import Box from '@mui/material/Box'
import Tabs from '@mui/material/Tabs'
import Tab from '@mui/material/Tab'
import tabItems from './tabItems'
import Overview from './overview/Overview'
import AppointmentsPage from '../../../../pages/admin/AppointmentsPage'
// import PersonalInfo from './datos-personales/PersonalInfo'
import MedicalHistory from './historia-clinica/MedicalHistory'
import PaymentsPage from '../../../../pages/admin/PaymentsPage'

function TabsComponent() {
  const { id } = useParams()

  const [value, setValue] = useState(0)

  const handleChange = (event, newValue) => {
    setValue(newValue)
  }

  return (
    <>
      <Box sx={{ width: '100%', typography: 'body1' }}>
        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
          <Tabs
            value={value}
            onChange={handleChange}
            variant="scrollable"
            scrollButtons="auto"
            aria-label="paciente tabs"
          >
            {tabItems.map((tab, i) => (
              <Tab
                key={i}
                component={Link}
                to={`/dashboard/patients/${id}${tab.to}`}
                label={tab.label}
                icon={tab.icon}
                iconPosition="start"
              />
            ))}
          </Tabs>
        </Box>
      </Box>
      <Box sx={{ p: 2, minHeight: 500 }}>
        <Routes>
          <Route index element={<Overview />} />
          <Route path="appointments/*" element={<AppointmentsPage />} />
          {/* <Route path="personal-data/*" element={<PersonalInfo />} /> */}
          <Route path="medical-history/*" element={<MedicalHistory />} />
          <Route path="payments/*" element={<PaymentsPage />} />
          <Route path="*" element={<Navigate to="/" replace />} />
        </Routes>
      </Box>
    </>
  )
}

export default TabsComponent
