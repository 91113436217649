import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
} from 'react-router-dom'
import { ToastContainer } from 'react-toastify'
import { createTheme, ThemeProvider } from '@mui/material/styles'
import { teal, purple } from '@mui/material/colors'
import { esES } from '@mui/x-data-grid'
// import { esES as pickersEsES } from '@mui/x-date-pickers'
import { esES as coreEsES } from '@mui/material/locale'
import CssBaseline from '@mui/material/CssBaseline'
import Landing from './pages/public/Landing'
import Login from './pages/public/Login'
import Dashboard from './pages/admin/Dashboard'
import PrivateRoute from './components/PrivateRoute'
import 'react-toastify/dist/ReactToastify.css'
import 'moment/locale/es'

const mdTheme = createTheme(
  {
    palette: {
      // mode: 'dark',
      primary: {
        main: teal[500],
      },
      secondary: {
        main: purple[500],
      },
    },
  },
  esES, // x-data-grid translations
  // pickersEsES, // x-date-pickers translations
  coreEsES // core translations
)

function App() {
  return (
    <ThemeProvider theme={mdTheme}>
      <CssBaseline />
      <Router>
        <Routes>
          {/* <Route path="/" element={<Navigate to="/dashboard" replace />} /> */}
          <Route path="/*" element={<Landing />} />
          <Route path="/login" element={<Login />} />
          <Route
            path="/dashboard/*"
            element={
              <PrivateRoute>
                <Dashboard />
              </PrivateRoute>
            }
          />
          <Route path="*" element={<Navigate to="/" replace />} />
        </Routes>
      </Router>
      <ToastContainer />
    </ThemeProvider>
  )
}

export default App
