import { useEffect, useState, useMemo } from 'react'
import { useNavigate } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import PropTypes from 'prop-types'
import moment from 'moment'

import {
  DataGrid,
  GridToolbar,
  GridFooterContainer,
  GridFooter,
} from '@mui/x-data-grid'
import Box from '@mui/material/Box'
import LinearProgress from '@mui/material/LinearProgress'
import Typography from '@mui/material/Typography'

import { getExpenses } from '../../../features/expenses/ExpenseSlice'

function CustomFooterTotalComponent(props) {
  return (
    <GridFooterContainer>
      <Box sx={{ padding: '10px', display: 'flex' }}>
        <Typography variant="button" gutterBottom>
          Total: ${props.total.toLocaleString()}
        </Typography>
      </Box>
      <GridFooter
        sx={{
          border: 'none',
        }}
      />
    </GridFooterContainer>
  )
}

CustomFooterTotalComponent.propTypes = {
  total: PropTypes.number,
}

const ExpensesList = ({ dateRange }) => {
  const dispatch = useDispatch()
  const navigate = useNavigate()

  const { expenses, isLoading } = useSelector((state) => state.expenses)
  const [filteredExpenses, setFilteredExpenses] = useState([])
  const [totalFetched, setTotalFetched] = useState(0)
  const [total, setTotal] = useState(0)

  useEffect(() => {
    dispatch(getExpenses())
  }, [dispatch])

  useEffect(() => {
    if (expenses) {
      // Set filtered expenses
      const filtered = expenses
        .filter((item) => {
          const expenseDate = moment(item.createdAt).format('YYYY-MM-DD')
          return (
            moment(expenseDate).isSameOrAfter(dateRange[0]) &&
            moment(expenseDate).isSameOrBefore(dateRange[1])
          )
        })
        .sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt))
      setFilteredExpenses(filtered)
    }
  }, [expenses, dateRange])

  useEffect(() => {
    if (filteredExpenses) {
      const sum = filteredExpenses
        .map((item) => item.amount)
        .reduce((a, b) => a + b, 0)
      setTotalFetched(sum)
    }
  }, [filteredExpenses])

  const columns = useMemo(
    () => [
      {
        field: 'createdAt',
        headerName: 'Fecha',
        type: 'date',
        width: 140,
        hideable: false,
        valueGetter: ({ value }) => value && moment(value).format('MM/DD/YYYY'),
        valueFormatter: ({ value }) =>
          value && moment(value).format('DD/MMM/YYYY'),
      },
      {
        field: 'notes',
        headerName: 'Notas',
        minWidth: 200,
        flex: 1,
        hideable: true,
      },
      {
        field: 'amount',
        headerName: 'Cantidad',
        type: 'number',
        minWidth: 100,
        flex: 1,
        hideable: false,
        valueFormatter: ({ value }) => value && `$${value.toLocaleString()}`,
      },
    ],
    []
  )

  return (
    <Box sx={{ height: 600, width: '100%' }}>
      <DataGrid
        rows={filteredExpenses}
        columns={columns}
        loading={isLoading}
        getRowId={(row) => row._id}
        onRowClick={(params) => navigate(`/dashboard/expenses/${params.id}`)}
        pageSize={25}
        disableDensitySelector
        disableSelectionOnClick
        components={{
          Toolbar: GridToolbar,
          LoadingOverlay: LinearProgress,
          Footer: CustomFooterTotalComponent,
        }}
        componentsProps={{
          toolbar: {
            showQuickFilter: true,
            quickFilterProps: { debounceMs: 500 },
          },
          footer: { total },
        }}
        onStateChange={(state) => {
          const visibleRows = state.filter.visibleRowsLookup
          let visibleItems = []
          for (const [id, value] of Object.entries(visibleRows)) {
            if (value === true) {
              visibleItems.push(id)
            }
          }

          const res = filteredExpenses.filter((item) =>
            visibleItems.includes(item._id)
          )
          const total = res
            .map((item) => item.amount)
            .reduce((a, b) => a + b, 0)

          Object.keys(visibleRows).length === 0
            ? setTotal(totalFetched)
            : setTotal(total)
        }}
      />
    </Box>
  )
}

export default ExpensesList
