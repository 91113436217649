import React, { useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useNavigate, useParams } from 'react-router-dom'
import { toast } from 'react-toastify'
import moment from 'moment'

import Paper from '@mui/material/Paper'
import Divider from '@mui/material/Divider'
import Grid from '@mui/material/Grid'
import Stack from '@mui/material/Stack'
import Box from '@mui/material/Box'
import FormHelperText from '@mui/material/FormHelperText'
import Typography from '@mui/material/Typography'
import Button from '@mui/material/Button'
import InputAdornment from '@mui/material/InputAdornment'
import SaveIcon from '@mui/icons-material/Save'
import ClearIcon from '@mui/icons-material/Clear'
import CircularProgress from '@mui/material/CircularProgress'
import FormControl from '@mui/material/FormControl'
import FormLabel from '@mui/material/FormLabel'
import OutlinedInput from '@mui/material/OutlinedInput'
import Alert from '@mui/material/Alert'

import { useForm, Controller } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import paymentSchema from '../../../../../validators/paymentSchema'
import {
  createPayment,
  reset,
} from '../../../../../features/payments/paymentsSlice'
import { getPatient } from '../../../../../features/patients/PatientSlice'

const AddPayment = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const { id } = useParams()
  const date = moment().format('DD/MMM/YYYY')

  const { patient } = useSelector((state) => state.patients)
  const { isLoading, isError, isSuccess, message } = useSelector(
    (state) => state.payments
  )

  const [balanceDue, setBalanceDue] = useState(0)

  const {
    control,
    formState: { errors },
    handleSubmit,
  } = useForm({
    mode: 'onChange',
    resolver: yupResolver(paymentSchema),
  })

  useEffect(() => {
    // Calculate the patient balance due
    let totalCost = 0
    let totalPay = 0

    patient.appointments.map((a, i) => (totalCost += a.total))
    patient.payments.map((p, i) => (totalPay += p.amount))

    setBalanceDue(totalCost - totalPay)
  }, [patient])

  useEffect(() => {
    if (isError) {
      toast.error(message)
      dispatch(reset())
    }

    if (isSuccess) {
      dispatch(reset())
      dispatch(getPatient(id))
      toast.success('Nuevo pago aplicado')
      navigate(`/dashboard/patients/${id}/payments`)
    }

    // dispatch(reset())
  }, [dispatch, isError, isSuccess, message, navigate, id])

  const onSubmit = (data, e) => {
    e.preventDefault()
    const formData = {
      ...data,
      patient: id,
    }
    // console.log(formData)
    dispatch(createPayment(formData))
  }

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Paper variant="outlined">
          <Stack
            direction="row"
            justifyContent="space-between"
            alignItems="center"
            spacing={1}
            sx={{ p: 2 }}
          >
            <Typography variant="h6">Nuevo Pago</Typography>
            <Typography variant="body2" color="textSecondary">
              Fecha: <strong>{date}</strong>
            </Typography>
          </Stack>
          <Divider />
          <Box
            component="form"
            autoComplete="off"
            noValidate
            sx={{ p: 2 }}
            onSubmit={handleSubmit(onSubmit)}
          >
            <Grid container spacing={2} sx={{ mb: 4 }}>
              <Grid item xs={12}>
                <Alert severity="warning">
                  Balance Actual:{' '}
                  <strong>${Number(balanceDue).toLocaleString()}</strong>
                </Alert>
              </Grid>
              <Grid item xs={12}>
                <Controller
                  name="amount"
                  control={control}
                  defaultValue={''}
                  render={({ field }) => (
                    <FormControl fullWidth>
                      <FormLabel
                        id="amount"
                        error={!!errors.amount}
                        sx={{ typography: 'subtitle2', pb: 1 }}
                      >
                        Total a Pagar
                      </FormLabel>
                      <OutlinedInput
                        {...field}
                        placeholder="0"
                        type="number"
                        error={!!errors.amount}
                        startAdornment={
                          <InputAdornment position="start">$</InputAdornment>
                        }
                      />
                      <FormHelperText id="amount" error={!!errors.amount}>
                        {errors.amount && errors.amount.message}
                      </FormHelperText>
                    </FormControl>
                  )}
                />
              </Grid>
              <Grid item xs={12} md={12}>
                <Controller
                  name="notes"
                  control={control}
                  defaultValue=""
                  render={({ field }) => (
                    <FormControl {...field} fullWidth>
                      <FormLabel
                        id="notes"
                        sx={{ typography: 'subtitle2', pb: 1 }}
                      >
                        Notas
                      </FormLabel>
                      <OutlinedInput
                        multiline
                        rows={2}
                        placeholder="No. de Referencia, Abono a Cita #..."
                      />
                    </FormControl>
                  )}
                />
              </Grid>
            </Grid>
            <Stack direction="row" spacing={1} sx={{ py: 2 }}>
              <Box sx={{ position: 'relative' }}>
                <Button
                  type="submit"
                  variant="contained"
                  color="primary"
                  size="small"
                  startIcon={<SaveIcon />}
                  disabled={isLoading}
                >
                  Guardar
                </Button>
                {isLoading && (
                  <CircularProgress
                    size={24}
                    sx={{
                      position: 'absolute',
                      top: '50%',
                      left: '50%',
                      marginTop: '-12px',
                      marginLeft: '-12px',
                    }}
                  />
                )}
              </Box>
              <Button
                size="small"
                startIcon={<ClearIcon />}
                onClick={() => navigate(`/dashboard/patients/${id}/payments`)}
              >
                Cancelar
              </Button>
            </Stack>
          </Box>
        </Paper>
      </Grid>
    </Grid>
  )
}

export default AddPayment
