import React, { useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useNavigate, useParams, Link as RouterLink } from 'react-router-dom'
import { toast } from 'react-toastify'
import moment from 'moment'
import Grid from '@mui/material/Grid'
import Stack from '@mui/material/Stack'
import Box from '@mui/material/Box'
import TextField from '@mui/material/TextField'
import Typography from '@mui/material/Typography'
import Button from '@mui/material/Button'
import InputLabel from '@mui/material/InputLabel'
import MenuItem from '@mui/material/MenuItem'
import FormControl from '@mui/material/FormControl'
import Select from '@mui/material/Select'
import FormHelperText from '@mui/material/FormHelperText'
import SaveIcon from '@mui/icons-material/Save'
import ClearIcon from '@mui/icons-material/Clear'
import CircularProgress from '@mui/material/CircularProgress'
import Paper from '@mui/material/Paper'
import IconButton from '@mui/material/IconButton'
import HomeIcon from '@mui/icons-material/Home'
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight'
import Link from '@mui/material/Link'

import { useForm, Controller } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import patientSchema from '../../../validators/patientSchema'
import {
  getPatient,
  updatePatient,
  reset as resetState,
} from '../../../features/patients/PatientSlice'

const PatientEdit = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const { id } = useParams()
  const date = moment().format('DD/MMM/YYYY')

  const {
    control,
    reset,
    formState: { errors },
    handleSubmit,
  } = useForm({
    mode: 'onChange',
    resolver: yupResolver(patientSchema),
  })

  const { patient, isLoading, isError, isSuccess, message } = useSelector(
    (state) => state.patients
  )

  useEffect(() => {
    dispatch(getPatient(id))
  }, [dispatch, id])

  useEffect(() => {
    if (patient) {
      reset(patient)
    }
  }, [patient, reset])

  useEffect(() => {
    if (isError) {
      toast.error(message)
    }

    if (isSuccess && patient) {
      toast.success('Datos del paciente actualizados')
      navigate(`/dashboard/patients/${id}`)
    }

    dispatch(resetState())
  }, [dispatch, isError, isSuccess, message, navigate, patient, id])

  const onSubmit = (data, e) => {
    e.preventDefault()
    const patientData = {
      id,
      data,
    }

    dispatch(updatePatient(patientData))
  }

  return (
    <>
      <Paper sx={{ p: 2, mb: 2 }}>
        <Stack direction="row" justifyContent="space-between" spacing={1}>
          <Typography sx={{ fontWeight: 'bold' }} variant="h5">
            Editar Paciente
          </Typography>
          <Stack direction="row" alignItems="center" spacing={1}>
            <IconButton
              aria-label="home"
              size="small"
              color="primary"
              onClick={() => navigate(`/dashboard`)}
            >
              <HomeIcon fontSize="small" />
            </IconButton>
            <KeyboardArrowRightIcon fontSize="small" />
            <Link
              component={RouterLink}
              to="/dashboard/patients"
              color="inherit"
              variant="subtitle2"
              underline="none"
            >
              Pacientes
            </Link>
          </Stack>
        </Stack>
      </Paper>
      <Paper sx={{ p: 2, mb: 2 }}>
        <Stack direction="row" justifyContent="flex-end" spacing={1}>
          {/* <Typography sx={{ fontWeight: 'bold' }} variant="h5">
            Editar Paciente
          </Typography> */}
          <Typography variant="body2" color="textSecondary">
            Fecha: <strong>{date}</strong>
          </Typography>
        </Stack>
        <Box
          component="form"
          autoComplete="off"
          noValidate
          sx={{ mb: 2 }}
          onSubmit={handleSubmit(onSubmit)}
        >
          <Grid container spacing={2} sx={{ mb: 4 }}>
            <Grid item xs={12}>
              <Typography variant="subtitle2">Historia Clínica</Typography>
            </Grid>
            <Grid item xs={12} md={6}>
              <Controller
                name="referredBy"
                control={control}
                defaultValue=""
                render={({ field }) => (
                  <TextField
                    {...field}
                    label="Remitido por"
                    size="small"
                    fullWidth
                  />
                )}
              />
            </Grid>
          </Grid>
          <Grid container spacing={2} sx={{ mb: 4 }}>
            <Grid item xs={12}>
              <Typography variant="subtitle2">
                Ficha de Identificación
              </Typography>
            </Grid>
            <Grid item xs={12} md={6}>
              <Controller
                name="name"
                control={control}
                defaultValue=""
                render={({ field }) => (
                  <TextField
                    {...field}
                    label="Nombre *"
                    size="small"
                    fullWidth
                    error={!!errors.name}
                    helperText={errors.name && errors.name.message}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} md={3}>
              <Controller
                name="age"
                control={control}
                defaultValue=""
                render={({ field }) => (
                  <TextField
                    {...field}
                    label="Edad *"
                    size="small"
                    type="number"
                    fullWidth
                    error={!!errors.age}
                    helperText={errors.age && errors.age.message}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} md={3}>
              <Controller
                name="gender"
                control={control}
                defaultValue=""
                render={({ field }) => (
                  <FormControl fullWidth size="small" error={!!errors.gender}>
                    <InputLabel id="label-sexo">Sexo *</InputLabel>
                    <Select {...field} labelId="label-sexo" label="Sexo *">
                      <MenuItem value="Masculino">Masculino</MenuItem>
                      <MenuItem value="Femenino">Femenino</MenuItem>
                    </Select>
                    {errors.gender && (
                      <FormHelperText>{errors.gender.message}</FormHelperText>
                    )}
                  </FormControl>
                )}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <Controller
                name="maritalStatus"
                control={control}
                defaultValue=""
                render={({ field }) => (
                  <TextField
                    {...field}
                    label="Estado Civil *"
                    size="small"
                    fullWidth
                    error={!!errors.maritalStatus}
                    helperText={
                      errors.maritalStatus && errors.maritalStatus.message
                    }
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <Controller
                name="occupation"
                control={control}
                defaultValue=""
                render={({ field }) => (
                  <TextField
                    {...field}
                    label="Ocupación *"
                    size="small"
                    fullWidth
                    error={!!errors.occupation}
                    helperText={errors.occupation && errors.occupation.message}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <Controller
                name="address"
                control={control}
                defaultValue=""
                render={({ field }) => (
                  <TextField
                    {...field}
                    label="Dirección *"
                    size="small"
                    fullWidth
                    error={!!errors.address}
                    helperText={errors.address && errors.address.message}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <Controller
                name="phone"
                control={control}
                defaultValue=""
                render={({ field }) => (
                  <TextField
                    {...field}
                    label="Teléfono *"
                    size="small"
                    fullWidth
                    error={!!errors.phone}
                    helperText={errors.phone && errors.phone.message}
                  />
                )}
              />
            </Grid>
          </Grid>
          <Stack direction="row" spacing={1} sx={{ mt: 4 }}>
            <Box sx={{ position: 'relative' }}>
              <Button
                type="submit"
                variant="contained"
                color="primary"
                size="small"
                startIcon={<SaveIcon />}
                disabled={isLoading}
              >
                Guardar cambios
              </Button>
              {isLoading && (
                <CircularProgress
                  size={24}
                  sx={{
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    marginTop: '-12px',
                    marginLeft: '-12px',
                  }}
                />
              )}
            </Box>
            <Button
              size="small"
              startIcon={<ClearIcon />}
              onClick={() => navigate(`/dashboard/patients/${id}`)}
            >
              Cancelar
            </Button>
          </Stack>
        </Box>
      </Paper>
    </>
  )
}

export default PatientEdit
