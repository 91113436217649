import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import moment from 'moment'
import Link from '@mui/material/Link'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import Title from '../common/Title'

function preventDefault(event) {
  event.preventDefault()
}

export default function RecentPayments() {
  const { payments } = useSelector((state) => state.payments)
  const [last5, setLast5] = useState([])

  useEffect(() => {
    if (payments)
      setLast5(
        payments
          .slice(-10)
          .sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt))
      )
  }, [payments])

  return (
    <>
      <Title>Pagos Recientes</Title>
      <Table size="small">
        <TableHead>
          <TableRow>
            <TableCell>Fecha</TableCell>
            <TableCell>Paciente</TableCell>
            {/* <TableCell>Ship To</TableCell>
            <TableCell>Payment Method</TableCell> */}
            <TableCell align="right">Cantidad Pagada</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {last5.map((payment) => (
            <TableRow key={payment._id}>
              <TableCell>
                {moment(payment.createdAt).format('DD MMM YYYY')}
              </TableCell>
              <TableCell>{payment.patient.name}</TableCell>
              {/* 
              <TableCell>{row.shipTo}</TableCell>
              <TableCell>{row.paymentMethod}</TableCell> */}
              <TableCell align="right">{`$${payment.amount.toLocaleString()}`}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
      <Link color="primary" href="#" onClick={preventDefault} sx={{ mt: 3 }}>
        {/* Ver todos los pagos */}
      </Link>
    </>
  )
}
