import React from 'react'
import { Routes, Route, Navigate } from 'react-router-dom'
// import Paper from '@mui/material/Paper'
// import Box from '@mui/material/Box'

import Patients from '../../components/admin/patients/Patients'
import AddPatient from '../../components/admin/patients/AddPatient'
import PatientView from '../../components/admin/patients/PatientView'
import PatientEdit from '../../components/admin/patients/PatientEdit'
// import AppBreadcrumb from '../../components/admin/layout/AppBreadcrumb'

/* const breadcrumbNameMap = {
  '/dashboard': 'Dashboard',
  '/dashboard/patients': 'Pacientes',
  '/dashboard/patients/new': 'Nuevo Paciente',
  edit: 'Editar Paciente',
} */

const PatientsPage = () => {
  return (
    <>
      {/* <AppBreadcrumb breadcrumbNameMap={breadcrumbNameMap} /> */}
      {/* <Paper>
        <Box sx={{ p: 2, minHeight: 500 }}> */}
          <Routes>
            <Route index element={<Patients />} />
            <Route path="new" element={<AddPatient />} />
            <Route path=":id/*" element={<PatientView />} />
            <Route path=":id/edit" element={<PatientEdit />} />
            <Route path="*" element={<Navigate to="/" replace />} />
          </Routes>
        {/* </Box>
      </Paper> */}
    </>
  )
}

export default PatientsPage
