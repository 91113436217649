import React, { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { toast } from 'react-toastify'
import moment from 'moment'

import Grid from '@mui/material/Grid'
import Paper from '@mui/material/Paper'
import Stack from '@mui/material/Stack'
import Typography from '@mui/material/Typography'
import Divider from '@mui/material/Divider'
import EventTwoToneIcon from '@mui/icons-material/EventTwoTone'
import LocalAtmTwoToneIcon from '@mui/icons-material/LocalAtmTwoTone'
import IconButton from '@mui/material/IconButton'
import Tooltip from '@mui/material/Tooltip'
import EditIcon from '@mui/icons-material/Edit'
import Button from '@mui/material/Button'
import ArrowBackTwoToneIcon from '@mui/icons-material/ArrowBackTwoTone'
import DeleteIcon from '@mui/icons-material/Delete'
import MoreVertIcon from '@mui/icons-material/MoreVert'
import MenuItem from '@mui/material/MenuItem'
import Menu from '@mui/material/Menu'
import ListItemIcon from '@mui/material/ListItemIcon'

import ConfirmationDialog from '../../../common/ConfirmationDialog'
import {
  getPayment,
  deletePayment,
  reset,
  resetError,
} from '../../../../../features/payments/paymentsSlice'
import { getPatient } from '../../../../../features/patients/PatientSlice'

function PaymentView() {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const { id, paymentId } = useParams()

  const { payment, isError, isSuccess, message } = useSelector(
    (state) => state.payments
  )

  const [activePayment, setActivePayment] = useState(null)
  const [anchorElPayment, setAnchorElPayment] = useState(null)
  const [confirmOpen, setConfirmOpen] = useState(false)

  useEffect(() => {
    dispatch(getPayment(paymentId))
  }, [dispatch, paymentId])

  useEffect(() => {
    if (payment) {
      setActivePayment(payment)
    }
  }, [payment])

  useEffect(() => {
    if (isError) {
      toast.error(message)
      dispatch(resetError())
    }

    if (isSuccess) {
      toast.error('Pago eliminado!')
      dispatch(reset())
      dispatch(getPatient(id))
      navigate(`/dashboard/patients/${id}/payments`)
    }
  }, [isError, isSuccess, message, dispatch, navigate, id])

  const handleOpenPaymentMenu = (event) => {
    setAnchorElPayment(event.currentTarget)
  }

  const handleClosePaymentMenu = () => {
    setAnchorElPayment(null)
  }

  const handleDelete = () => {
    setAnchorElPayment(null)
    setConfirmOpen(true)
  }

  return (
    <>
      <Button
        variant="contained"
        color="primary"
        size="small"
        disabled={false}
        sx={{
          mt: 1,
          mb: 3,
        }}
        startIcon={<ArrowBackTwoToneIcon />}
        onClick={() => navigate(`/dashboard/patients/${id}/payments`)}
      >
        Volver al listado
      </Button>
      {activePayment && (
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Paper variant="outlined">
              <Stack
                direction="row"
                justifyContent="space-between"
                alignItems="center"
                sx={{ p: 2 }}
              >
                <Typography variant="subtitle2">Datos del pago</Typography>
                <Stack direction="row" alignItems="center" spacing={1}>
                  <Tooltip title="Editar">
                    <IconButton
                      aria-label="editar"
                      size="small"
                      onClick={() =>
                        navigate(
                          `/dashboard/patients/${id}/payments/${paymentId}/edit`
                        )
                      }
                    >
                      <EditIcon />
                    </IconButton>
                  </Tooltip>
                  <IconButton onClick={handleOpenPaymentMenu}>
                    <MoreVertIcon />
                  </IconButton>
                  <Menu
                    sx={{ mt: '45px' }}
                    id="menu-patient"
                    anchorEl={anchorElPayment}
                    anchorOrigin={{
                      vertical: 'top',
                      horizontal: 'right',
                    }}
                    keepMounted
                    transformOrigin={{
                      vertical: 'top',
                      horizontal: 'right',
                    }}
                    open={Boolean(anchorElPayment)}
                    onClose={handleClosePaymentMenu}
                  >
                    <MenuItem onClick={handleDelete}>
                      <ListItemIcon>
                        <DeleteIcon fontSize="small" />
                      </ListItemIcon>
                      Eliminar
                    </MenuItem>
                  </Menu>
                </Stack>
              </Stack>
              <Divider />
              <Grid
                container
                justifyContent="space-evenly"
                alignItems="center"
                spacing={2}
                sx={{ p: 2 }}
              >
                <Grid item xs={6} md={3}>
                  <Stack
                    direction="row"
                    spacing={1}
                    sx={{ alignItems: 'center' }}
                  >
                    <EventTwoToneIcon />
                    <Typography variant="subtitle2">Fecha</Typography>
                  </Stack>
                </Grid>
                <Grid item xs={6} md={3}>
                  <Typography variant="body2">
                    {moment(activePayment.createdAt).format('DD/MMM/YYYY')}
                  </Typography>
                </Grid>

                <Grid item xs={6} md={3}>
                  <Stack
                    direction="row"
                    spacing={1}
                    sx={{ alignItems: 'center' }}
                  >
                    <LocalAtmTwoToneIcon />
                    <Typography variant="subtitle2">Total Pagado</Typography>
                  </Stack>
                </Grid>
                <Grid item xs={6} md={3}>
                  <Typography variant="body2">
                    {`$${Number(activePayment.amount).toLocaleString()}`}
                  </Typography>
                </Grid>
                {activePayment.notes && (
                  <Grid
                    container
                    justifyContent="space-evenly"
                    alignItems="center"
                    spacing={2}
                    sx={{ p: 2 }}
                  >
                    <Grid item xs={12}>
                      <Typography variant="subtitle2">Notas</Typography>
                    </Grid>
                    <Grid item xs={12}>
                      <Typography variant="body2">
                        {activePayment.notes}
                      </Typography>
                    </Grid>
                  </Grid>
                )}
              </Grid>
            </Paper>
            <ConfirmationDialog
              keepMounted
              title="Eliminar Pago"
              children={
                <>
                  <Typography variant="body1">
                    ¿Está seguro que desea eliminar el pago?
                  </Typography>
                  <Typography variant="caption">
                    *El saldo pendiente por pagar se recalculará acorde los
                    pagos restantes.
                  </Typography>
                </>
              }
              open={confirmOpen}
              setOpen={setConfirmOpen}
              onConfirm={() => {
                dispatch(deletePayment(paymentId))
              }}
            />
          </Grid>
        </Grid>
      )}
    </>
  )
}

export default PaymentView
