import { useState } from 'react'
import { Routes, Route, Navigate } from 'react-router-dom'
import Box from '@mui/material/Box'
import Toolbar from '@mui/material/Toolbar'
import Container from '@mui/material/Container'
import ResponsiveAppBar from '../../components/admin/layout/ResponsiveAppBar'
import ResponsiveDrawer from '../../components/admin/layout/ResponsiveDrawer'
// import LinearProgress from '@mui/material/LinearProgress'

import HomePage from './HomePage'
import BalancePage from './BalancePage'
import PatientsPage from './PatientsPage'
import ExpensesPage from './ExpensesPage'
import PaymentsReportPage from './reports/PaymentsReportPage'

const drawerWidth = 240

function Dashboard() {
  const [mobileOpen, setMobileOpen] = useState(false)
  // const { isLoading } = useSelector((state) => state.pacientes)
  return (
    <>
      <Box sx={{ display: 'flex' }}>
        <ResponsiveAppBar
          drawerWidth={drawerWidth}
          open={mobileOpen}
          onOpenChange={setMobileOpen}
        />
        <ResponsiveDrawer
          drawerWidth={drawerWidth}
          open={mobileOpen}
          onOpenChange={setMobileOpen}
        />
        <Box
          component="main"
          sx={{
            backgroundColor: (theme) => theme.palette.grey[200],
            flexGrow: 1,
            height: '100vh',
            overflow: 'auto',
          }}
        >
          <Toolbar />
          {/* <LinearProgress /> */}
          <Container maxWidth="lg" sx={{ mt: 4, mb: 4 }}>
            <Routes>
              <Route index element={<HomePage />} />
              <Route path="balance/*" element={<BalancePage />} />
              <Route path="patients/*" element={<PatientsPage />} />
              <Route path="expenses/*" element={<ExpensesPage />} />
              <Route
                path="reports/payments/*"
                element={<PaymentsReportPage />}
              />
              <Route path="*" element={<Navigate to="/" replace />} />
            </Routes>
          </Container>
        </Box>
      </Box>
    </>
  )
}

export default Dashboard
