import React, { useEffect, useState, useMemo } from 'react'
import { useNavigate } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import { DataGrid, GridToolbar } from '@mui/x-data-grid'
import moment from 'moment'
// import Paper from '@mui/material/Paper'
import Box from '@mui/material/Box'
// import VisibilityIcon from '@mui/icons-material/Visibility'
// import EditIcon from '@mui/icons-material/Edit'
// import IconButton from '@mui/material/IconButton'
// import Tooltip from '@mui/material/Tooltip'
// import PageviewTwoToneIcon from '@mui/icons-material/PageviewTwoTone'
import LinearProgress from '@mui/material/LinearProgress'
import { getPatients, reset } from '../../../features/patients/PatientSlice'
import { toast } from 'react-toastify'

const PatientsList = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()

  const { patients, isLoading, isSuccess, isError, message } = useSelector(
    (state) => state.patients
  )
  const [pageSize, setPageSize] = useState(10)

  useEffect(() => {
    if (isError) {
      toast.error(message)
    }

    if (isSuccess) {
      toast.success('Paciente eliminado')
      dispatch(getPatients())
    }
    dispatch(reset())
  }, [isError, isSuccess, message, dispatch])

  useEffect(() => {
    dispatch(getPatients())
  }, [dispatch])

  // const viewPatient = useCallback(
  //   (id) => () => {
  //     navigate(`/dashboard/patients/${id}`)
  //   },
  //   [navigate]
  // )

  // const editPatient = useCallback(
  //   (id) => () => {
  //     navigate(`/dashboard/patients/${id}/edit`)
  //   },
  //   [navigate]
  // )

  const columns = useMemo(
    () => [
      {
        field: 'name',
        headerName: 'Nombre',
        minWidth: 200,
        flex: 1,
        hideable: false,
      },
      {
        field: 'age',
        headerName: 'Edad',
        type: 'number',
        minWidth: 100,
        hide: true,
      },
      {
        field: 'phone',
        headerName: 'Teléfono',
        minWidth: 140,
        flex: 1,
      },
      {
        field: 'gender',
        headerName: 'Sexo',
        flex: 1,
        hide: true,
      },
      {
        field: 'maritalStatus',
        headerName: 'Estado Civil',
        flex: 1,
        hide: true,
      },
      {
        field: 'occupation',
        headerName: 'Ocupación',
        flex: 1,
        hide: true,
      },
      {
        field: 'address',
        headerName: 'Dirección',
        flex: 1,
        hide: true,
      },
      {
        field: 'referredBy',
        headerName: 'Remitido Por',
        flex: 1,
        hide: true,
      },
      {
        field: 'createdAt',
        headerName: 'Fecha Alta',
        type: 'date',
        width: 120,
        hide: true,
        valueGetter: ({ value }) => value && moment(value).format('DD/MM/YYYY'),
      },
      // {
      //   field: 'Acciones',
      //   type: 'actions',
      //   width: 80,
      //   hideable: false,
      //   getActions: (params) => [
      //     // <GridActionsCellItem
      //     //   label="Detalles"
      //     //   onClick={viewPatient(params.id)}
      //     // >
      //     //   <>
      //     //     <Tooltip title="Abrir Expediente">
      //     //       <IconButton aria-label="abrir expediente" size="small">
      //     //         <PageviewTwoToneIcon />
      //     //       </IconButton>
      //     //     </Tooltip>
      //     //   </>
      //     // </GridActionsCellItem>,
      //     // <GridActionsCellItem
      //     //   icon={<PageviewTwoToneIcon />}
      //     //   color="primary"
      //     //   label="Detalles"
      //     //   onClick={viewPatient(params.id)}
      //     // />,
      //     // <GridActionsCellItem
      //     //   icon={<EditIcon />}
      //     //   color="primary"
      //     //   label="Editar"
      //     //   onClick={editPatient(params.id)}
      //     // />,
      //     // <GridActionsCellItem
      //     //   icon={<DeleteIcon />}
      //     //   label="Eliminar"
      //     //   onClick={deletePatient(params.id)}
      //     //   showInMenu
      //     // />,
      //   ],
      // },
    ],
    []
  )

  return (
    <Box sx={{ height: 600, width: '100%' }}>
      <DataGrid
        rows={patients}
        columns={columns}
        loading={isLoading}
        getRowId={(row) => row._id}
        onRowClick={(params) => navigate(`/dashboard/patients/${params.id}`)}
        pageSize={pageSize}
        onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
        rowsPerPageOptions={[10]}
        disableColumnMenu
        disableColumnFilter
        disableDensitySelector
        disableSelectionOnClick
        components={{ Toolbar: GridToolbar, LoadingOverlay: LinearProgress }}
        componentsProps={{
          toolbar: {
            showQuickFilter: true,
            quickFilterProps: { debounceMs: 500 },
          },
        }}
      />
    </Box>
  )
}

export default PatientsList
