import Box from '@mui/material/Box'
import Fab from '@mui/material/Fab'
import WhatsAppIcon from '@mui/icons-material/WhatsApp'

function FabButton() {
  return (
    <Box sx={{ '& > :not(style)': { m: 1 } }}>
      <Fab
        color="primary"
        href="https://wa.me/6694310008"
        aria-label="send-us-whatsapp"
        sx={{
          position: 'fixed',
          bottom: (theme) => ({ xs: theme.spacing(1), sm: theme.spacing(2) }),
          right: (theme) => ({ xs: theme.spacing(1), sm: theme.spacing(2) }),
          backgroundColor: '#25D366',
        }}
      >
        <WhatsAppIcon fontSize="large" />
      </Fab>
    </Box>
  )
}

export default FabButton
