import React, { useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useParams, useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify'
import moment from 'moment'

import Grid from '@mui/material/Grid'
import Paper from '@mui/material/Paper'
import Stack from '@mui/material/Stack'
import Typography from '@mui/material/Typography'
import Divider from '@mui/material/Divider'
import RecordVoiceOverTwoToneIcon from '@mui/icons-material/RecordVoiceOverTwoTone'
import PhoneAndroidTwoToneIcon from '@mui/icons-material/PhoneAndroidTwoTone'
import PlaceTwoToneIcon from '@mui/icons-material/PlaceTwoTone'
import Filter9PlusTwoToneIcon from '@mui/icons-material/Filter9PlusTwoTone';
import WcTwoToneIcon from '@mui/icons-material/WcTwoTone';
import MaleTwoToneIcon from '@mui/icons-material/MaleTwoTone'
import WorkTwoToneIcon from '@mui/icons-material/WorkTwoTone'
import IconButton from '@mui/material/IconButton'
import Tooltip from '@mui/material/Tooltip'
import EditIcon from '@mui/icons-material/Edit'
import DeleteIcon from '@mui/icons-material/Delete'
import MoreVertIcon from '@mui/icons-material/MoreVert'
import MenuItem from '@mui/material/MenuItem'
import Menu from '@mui/material/Menu'
import ListItemIcon from '@mui/material/ListItemIcon'
import TodayTwoToneIcon from '@mui/icons-material/TodayTwoTone'

import {
  deletePatient,
  reset,
} from '../../../../../features/patients/PatientSlice'

import Title from '../../../common/Title'
import ConfirmationDialog from '../../../common/ConfirmationDialog'

function PersonalInfo() {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const { id } = useParams()

  const { patient, isSuccess, isError, message } = useSelector(
    (state) => state.patients
  )

  const [confirmOpen, setConfirmOpen] = useState(false)
  const [anchorElPatient, setAnchorElPatient] = useState(null)

  useEffect(() => {
    if (isError) {
      toast.error(message)
    }

    if (isSuccess) {
      toast.error('Paciente eliminado!')
      dispatch(reset())
      navigate(`/dashboard/patients`)
    }
  }, [isError, isSuccess, message, dispatch, navigate])

  const handleDelete = () => {
    setAnchorElPatient(null)
    setConfirmOpen(true)
  }
  const handleOpenUserMenu = (event) => {
    setAnchorElPatient(event.currentTarget)
  }
  const handleClosePatientMenu = () => {
    setAnchorElPatient(null)
  }

  return (
    <>
      {patient && (
        <Paper
          variant="outlined"
          sx={{ p: 2, display: 'flex', flexDirection: 'column' }}
        >
          <Grid
            container
            spacing={2}
            justifyContent="flex-start"
            alignItems="center"
          >
            <Grid item xs={12}>
              <Stack direction="column" spacing={1}>
                <Stack
                  direction="row"
                  justifyContent="space-between"
                  alignItems="center"
                >
                  <Title>Datos Personales</Title>
                  <Stack
                    direction="row"
                    justifyContent="flex-end"
                    alignItems="center"
                  >
                    <Tooltip title="Editar">
                      <IconButton
                        aria-label="editar"
                        size="small"
                        onClick={() =>
                          navigate(`/dashboard/patients/${patient._id}/edit`)
                        }
                      >
                        <EditIcon />
                      </IconButton>
                    </Tooltip>
                    <IconButton onClick={handleOpenUserMenu}>
                      <MoreVertIcon />
                    </IconButton>
                    <Menu
                      sx={{ mt: '45px' }}
                      id="menu-patient"
                      anchorEl={anchorElPatient}
                      anchorOrigin={{
                        vertical: 'top',
                        horizontal: 'right',
                      }}
                      keepMounted
                      transformOrigin={{
                        vertical: 'top',
                        horizontal: 'right',
                      }}
                      open={Boolean(anchorElPatient)}
                      onClose={handleClosePatientMenu}
                    >
                      <MenuItem onClick={handleDelete}>
                        <ListItemIcon>
                          <DeleteIcon fontSize="small" />
                        </ListItemIcon>
                        Eliminar
                      </MenuItem>
                    </Menu>
                  </Stack>
                </Stack>
              </Stack>
            </Grid>
          </Grid>
          <Grid
            container
            justifyContent="space-evenly"
            alignItems="center"
            spacing={2}
            sx={{ pt: 1 }}
          >
            <Grid item xs={6} sm={3}>
              <Stack direction="row" spacing={1} sx={{ alignItems: 'center' }}>
                <RecordVoiceOverTwoToneIcon />
                <Typography variant="subtitle2">Remitido por</Typography>
              </Stack>
            </Grid>
            <Grid item xs={6} sm={3}>
              <Typography variant="body2">{patient.referredBy}</Typography>
            </Grid>
            <Divider />
            <Grid item xs={6} sm={3}>
              <Stack direction="row" spacing={1} sx={{ alignItems: 'center' }}>
                <PhoneAndroidTwoToneIcon />
                <Typography variant="subtitle2">Teléfono</Typography>
              </Stack>
            </Grid>
            <Grid item xs={6} sm={3}>
              <Typography variant="body2">{patient.phone}</Typography>
            </Grid>
            <Grid item xs={6} sm={3}>
              <Stack direction="row" spacing={1} sx={{ alignItems: 'center' }}>
                <PlaceTwoToneIcon />
                <Typography variant="subtitle2">Dirección</Typography>
              </Stack>
            </Grid>
            <Grid item xs={6} sm={3}>
              <Typography variant="body2">{patient.address}</Typography>
            </Grid>
            <Grid item xs={6} sm={3}>
              <Stack direction="row" spacing={1} sx={{ alignItems: 'center' }}>
                <TodayTwoToneIcon />
                <Typography variant="subtitle2">Fecha Alta</Typography>
              </Stack>
            </Grid>
            <Grid item xs={6} sm={3}>
              <Typography variant="body2">
                {moment(patient.createdAt).format('DD/MMM/YYYY')}
              </Typography>
            </Grid>
          </Grid>
          <Grid
            container
            justifyContent="space-evenly"
            alignItems="center"
            spacing={2}
            sx={{ pt: 2 }}
          >
            <Grid item xs={6} sm={3}>
              <Stack direction="row" spacing={1} sx={{ alignItems: 'center' }}>
                <Filter9PlusTwoToneIcon />
                <Typography variant="subtitle2">Edad</Typography>
              </Stack>
            </Grid>
            <Grid item xs={6} sm={3}>
              <Typography variant="body2">{patient.age}</Typography>
            </Grid>
            <Grid item xs={6} sm={3}>
              <Stack direction="row" spacing={1} sx={{ alignItems: 'center' }}>
                <MaleTwoToneIcon />
                <Typography variant="subtitle2">Sexo</Typography>
              </Stack>
            </Grid>
            <Grid item xs={6} sm={3}>
              <Typography variant="body2">{patient.gender}</Typography>
            </Grid>
            <Grid item xs={6} sm={3}>
              <Stack direction="row" spacing={1} sx={{ alignItems: 'center' }}>
                <WcTwoToneIcon />
                <Typography variant="subtitle2">Estado Civil</Typography>
              </Stack>
            </Grid>
            <Grid item xs={6} sm={3}>
              <Typography variant="body2">{patient.maritalStatus}</Typography>
            </Grid>
            <Grid item xs={6} sm={3}>
              <Stack direction="row" spacing={1} sx={{ alignItems: 'center' }}>
                <WorkTwoToneIcon />
                <Typography variant="subtitle2">Ocupación</Typography>
              </Stack>
            </Grid>
            <Grid item xs={6} sm={3}>
              <Typography variant="body2">{patient.occupation}</Typography>
            </Grid>
          </Grid>
          <ConfirmationDialog
            keepMounted
            title="Confirmación"
            children={
              <>
                <Typography variant="body1">
                  ¿Está seguro que desea eliminar el paciente?
                </Typography>
                <Typography variant="body1" sx={{ fontWeight: 'bold', mb: 2 }}>
                  {patient.name}
                </Typography>
                <Stack direction="column">
                  <Typography variant="caption" sx={{ fontWeight: 'bold' }}>
                    *Esta acción es irreversible.
                  </Typography>
                  <Typography variant="caption">
                    *Todos los registros relacionados con el paciente se
                    eliminarán.
                  </Typography>
                </Stack>
              </>
            }
            open={confirmOpen}
            setOpen={setConfirmOpen}
            onConfirm={() => {
              dispatch(deletePatient(id))
            }}
          />
        </Paper>
      )}
    </>
  )
}

export default PersonalInfo
