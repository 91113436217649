import { useState, useEffect } from 'react'
import { useSelector } from 'react-redux'
import Button from '@mui/material/Button'
import Stack from '@mui/material/Stack'
import DialogTitle from '@mui/material/DialogTitle'
import DialogContent from '@mui/material/DialogContent'
import DialogActions from '@mui/material/DialogActions'
import Dialog from '@mui/material/Dialog'
import Typography from '@mui/material/Typography'
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline'
import Chip from '@mui/material/Chip'
import Grid from '@mui/material/Grid'
import { styled } from '@mui/material/styles'

const teeth1 = [18, 17, 16, 15, 14, 13, 12, 11]
const teeth2 = [21, 22, 23, 24, 25, 26, 27, 28]
const teeth3 = [48, 47, 46, 45, 44, 43, 42, 41]
const teeth4 = [31, 32, 33, 34, 35, 36, 37, 38]

const Tooth = styled(Chip)(({ theme }) => ({
  // ...theme.typography.subtitle2,
  // color: theme.palette.text.secondary,
  // fontWeight: 'bold',
}))

const Odontogram = (props) => {
  const { children, open, setOpen, onConfirm, ...other } = props
  const { dentalOrgan } = useSelector((state) => state.appointments)
  const [dentalOrgans, setDentalOrgans] = useState([])

  useEffect(() => {
    setDentalOrgans(dentalOrgan)
  }, [dentalOrgan])

  const handleClick = (v) => {
    if (dentalOrgans.includes(v)) {
      remove(v)
    } else {
      setDentalOrgans([...dentalOrgans, v])
    }
  }
  const remove = (v) => {
    setDentalOrgans((current) => current.filter((t) => t !== v))
  }
  const checkIfSelected = (v) => {
    if (dentalOrgans.includes(v)) return 'primary'
  }

  return (
    <Dialog
      maxWidth="md"
      sx={{
        '& .MuiDialog-paper': {
          maxHeight: 600,
        },
      }}
      open={open}
      onClose={() => setOpen(false)}
      {...other}
    >
      <DialogTitle>
        <Stack direction="row" spacing={1} alignItems="center">
          <Typography variant="h5" sx={{ fontWeight: 'bold' }}>
            Odontograma
          </Typography>
        </Stack>
      </DialogTitle>
      <DialogContent dividers>
        <Grid container minWidth={800}>
          <Grid item xs={6} md={6} sx={{ p: 2 }}>
            <Stack
              direction="row"
              justifyContent={{ xs: 'center', md: 'flex-end' }}
              alignItems="center"
              spacing={1}
            >
              {teeth1.map((t) => (
                <Tooth
                  key={t}
                  label={t}
                  color={checkIfSelected(t)}
                  onClick={() => handleClick(t)}
                />
              ))}
            </Stack>
          </Grid>
          <Grid item xs={6} md={6} sx={{ p: 2 }}>
            <Stack
              direction="row"
              justifyContent={{ xs: 'center', md: 'flex-start' }}
              alignItems="center"
              spacing={1}
            >
              {teeth2.map((t) => (
                <Tooth
                  key={t}
                  label={t}
                  color={checkIfSelected(t)}
                  onClick={() => handleClick(t)}
                />
              ))}
            </Stack>
          </Grid>
          <Grid item xs={6} md={6} sx={{ p: 2 }}>
            <Stack
              direction="row"
              justifyContent={{ xs: 'center', md: 'flex-end' }}
              alignItems="center"
              spacing={1}
            >
              {teeth3.map((t) => (
                <Tooth
                  key={t}
                  label={t}
                  color={checkIfSelected(t)}
                  onClick={() => handleClick(t)}
                />
              ))}
            </Stack>
          </Grid>
          <Grid item xs={6} md={6} sx={{ p: 2 }}>
            <Stack
              direction="row"
              justifyContent={{ xs: 'center', md: 'flex-start' }}
              alignItems="center"
              spacing={1}
            >
              {teeth4.map((t) => (
                <Tooth
                  key={t}
                  label={t}
                  color={checkIfSelected(t)}
                  onClick={() => handleClick(t)}
                />
              ))}
            </Stack>
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions sx={{ justifyContent: 'space-between' }}>
        <Button onClick={() => setOpen(false)}>Cancelar</Button>
        <Button
          variant="contained"
          color="primary"
          startIcon={<CheckCircleOutlineIcon />}
          onClick={() => {
            setOpen(false)
            onConfirm(dentalOrgans)
          }}
        >
          Confirmar
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default Odontogram
