import { Routes, Route, Navigate } from 'react-router-dom'

import Payments from '../../components/admin/patients/tabs/payments/Payments'
import AddPayment from '../../components/admin/patients/tabs/payments/AddPayment'
import PaymentView from '../../components/admin/patients/tabs/payments/PaymentView'
import PaymentEdit from '../../components/admin/patients/tabs/payments/PaymentEdit'

const PaymentsPage = () => {
  return (
    <Routes>
      <Route index element={<Payments />} />
      <Route path="new" element={<AddPayment />} />
      <Route path=":paymentId" element={<PaymentView />} />
      <Route path=":paymentId/edit" element={<PaymentEdit />} />
      <Route path="*" element={<Navigate to="/" replace />} />
    </Routes>
  )
}

export default PaymentsPage
