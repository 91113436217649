// import AccountCircleTwoToneIcon from '@mui/icons-material/AccountCircleTwoTone'
import ListAltTwoToneIcon from '@mui/icons-material/ListAltTwoTone'
import DateRangeTwoToneIcon from '@mui/icons-material/DateRangeTwoTone'
import MedicalInformationTwoToneIcon from '@mui/icons-material/MedicalInformationTwoTone'
import LocalAtmTwoToneIcon from '@mui/icons-material/LocalAtmTwoTone'

export const tabItems = [
  {
    label: 'General',
    value: 'overview',
    to: '',
    icon: <ListAltTwoToneIcon />,
  },
  {
    label: 'Historial',
    value: 'appointments',
    to: '/appointments',
    icon: <DateRangeTwoToneIcon />,
  },
  {
    label: 'Pagos',
    value: 'payments',
    to: '/payments',
    icon: <LocalAtmTwoToneIcon />,
  },
  // {
  //   label: 'Datos Personales',
  //   value: 'personalData',
  //   to: '/personal-data',
  //   icon: <AccountCircleTwoToneIcon />,
  // },
  {
    label: 'Historia Clínica',
    value: 'medicalHistory',
    to: '/medical-history',
    icon: <MedicalInformationTwoToneIcon />,
  },
]

export default tabItems
