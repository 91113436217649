import axios from 'axios'

const API_URL = '/api/v1/medical-history/'

// Create new medical history
const createMedicalHistory = async (data, token) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  }

  const response = await axios.post(`${API_URL}`, data, config)

  return response.data
}

// Get medical history
const getMedicalHistory = async (id, token) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  }

  const response = await axios.get(`${API_URL}${id}`, config)

  return response.data
}

// Update medical history
const updateMedicalHistory = async (medicalHistoryData, token) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  }

  const { id, data } = medicalHistoryData
  const response = await axios.put(`${API_URL}${id}`, data, config)

  return response.data
}

// Delete medical history
const deleteMedicalHistory = async (id, token) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  }

  const response = await axios.delete(`${API_URL}${id}`, config)

  return response.data
}

const medicalHistoryService = {
  createMedicalHistory,
  getMedicalHistory,
  updateMedicalHistory,
  deleteMedicalHistory,
}

export default medicalHistoryService
