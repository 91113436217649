import Box from '@mui/material/Box'
import Container from '@mui/material/Container'
import Typography from '@mui/material/Typography'

function Location() {
  return (
    <Container component="section" sx={{ my: 12 }}>
      <Typography
        component="h1"
        variant="h4"
        color="inherit"
        align="center"
        sx={{ fontWeight: 'bold' }}
      >
        {'Ubicación'}
      </Typography>
      <Box sx={{ mt: 8, display: 'flex', flexWrap: 'wrap' }}>
        <iframe
          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3666.138241900577!2d-106.44042068255617!3d23.238056!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x869f534d64890ae3%3A0x6b4ff9f9a8706362!2sEspecialista%20en%20Endodoncia%20Dr.%20Diego%20Mart%C3%ADn%20Pantoja!5e0!3m2!1ses-419!2smx!4v1690681510148!5m2!1ses-419!2smx"
          title="Dr. Diego Martin Pantoja"
          width="100%"
          height="600"
          style={{ border: 0 }}
          allowFullScreen=""
          loading="lazy"
          referrerPolicy="no-referrer-when-downgrade"
        ></iframe>
      </Box>
    </Container>
  )
}

export default Location
