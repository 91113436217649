import React, { useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useParams } from 'react-router-dom'
import { toast } from 'react-toastify'
import Paper from '@mui/material/Paper'
import Divider from '@mui/material/Divider'
import Grid from '@mui/material/Grid'
import Stack from '@mui/material/Stack'
import Box from '@mui/material/Box'
import TextField from '@mui/material/TextField'
import Typography from '@mui/material/Typography'
import Button from '@mui/material/Button'
// import InputLabel from '@mui/material/InputLabel'
// import MenuItem from '@mui/material/MenuItem'
// import Select from '@mui/material/Select'
// import FormHelperText from '@mui/material/FormHelperText'
import Radio from '@mui/material/Radio'
import RadioGroup from '@mui/material/RadioGroup'
import FormControlLabel from '@mui/material/FormControlLabel'
import FormControl from '@mui/material/FormControl'
import FormLabel from '@mui/material/FormLabel'
import SaveIcon from '@mui/icons-material/Save'
import ClearIcon from '@mui/icons-material/Clear'
import CircularProgress from '@mui/material/CircularProgress'
// import Chip from '@mui/material/Chip'
import MuiSelect from '../../../common/MuiSelect'
import { useForm, Controller } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import medicalHistorySchema from '../../../../../validators/medicalHistorySchema'
import {
  createMedicalHistory,
  reset,
} from '../../../../../features/medicalHistory/MedicalHistorySlice'
import { getPatient } from '../../../../../features/patients/PatientSlice'
import Input from '../../../common/Input'

const healthConditions = [
  { name: 'Excelente', value: 'excellent' },
  { name: 'Buena', value: 'good' },
  { name: 'Aceptable', value: 'acceptable' },
  { name: 'Mala', value: 'bad' },
]
const diseases = [
  { name: 'Alcoholismo', value: 'Alcoholismo' },
  { name: 'Anemia', value: 'Anemia' },
  { name: 'Alergias', value: 'Alergias' },
  { name: 'Asma', value: 'Asma' },
  { name: 'Cáncer', value: 'Cáncer' },
  { name: 'Cardiopatía', value: 'Cardiopatía' },
  { name: 'Colitis', value: 'Colitis' },
  { name: 'Diabetes', value: 'Diabetes' },
  { name: 'Drogadicción', value: 'Drogadicción' },
  { name: 'Epilepsia', value: 'Epilepsia' },
  { name: 'Enfermedades Infecciosas', value: 'Enfermedades Infecciosas' },
  { name: 'Enfermedades venéreas', value: 'Enfermedades venéreas' },
  { name: 'Enfermedades Mentales', value: 'Enfermedades Mentales' },
  { name: 'Fiebre Reumática', value: 'Fiebre Reumática' },
  { name: 'Gastritis', value: 'Gastritis' },
  { name: 'Glaucoma', value: 'Glaucoma' },
  { name: 'Hepatitis', value: 'Hepatitis' },
  { name: 'Herpes simple', value: 'Herpes simple' },
  { name: 'Hígado', value: 'Hígado' },
  {
    name: 'Hipertensión arterial (presión alta)',
    value: 'Hipertensión arterial (presión alta)',
  },
  {
    name: 'Hipotensión arterial (presión baja)',
    value: 'Hipotensión arterial (presión baja)',
  },
  { name: 'Lesiones de cabeza/cuello', value: 'Lesiones de cabeza/cuello' },
  { name: 'Migraña', value: 'Migraña' },
  { name: 'Riñón', value: 'Riñón' },
  { name: 'Respiratorias', value: 'Respiratorias' },
  { name: 'Sinusitis', value: 'Sinusitis' },
  { name: 'Úlceras', value: 'Úlceras' },
]

function AddMedicalHistory({ onCancel }) {
  const dispatch = useDispatch()
  const { id } = useParams()

  const { isLoading, isError, isSuccess, message } = useSelector(
    (state) => state.medicalHistory
  )

  const {
    control,
    formState: { errors },
    handleSubmit,
  } = useForm({
    mode: 'onChange',
    resolver: yupResolver(medicalHistorySchema),
  })

  useEffect(() => {
    if (isError) {
      toast.error(message)
    }

    if (isSuccess) {
      toast.success('Historia Clinica agregada correctamente!')
      dispatch(reset())
      dispatch(getPatient(id))
    }

    dispatch(reset())
  }, [isError, isSuccess, message, dispatch,id])

  const [optionalValues, setOptionalValues] = useState({
    medicalTreatmentRadio: '',
    allergicMedicinesRadio: '',
    allergicMaterialsRadio: '',
    anesthesiaUnusualReactionRadio: '',
  })

  const handleChange = (e) => {
    setOptionalValues({ ...optionalValues, [e.target.name]: e.target.value })
  }

  const onSubmit = (data, e) => {
    e.preventDefault()
    const formData = {
      ...data,
      previouslyAnesthetized: data.previouslyAnesthetized === 'yes',
      pregnancy: data.pregnancy === 'yes',
      patient: id,
    }

    dispatch(createMedicalHistory(formData))
  }

  return (
    <>
      <Paper variant="outlined">
        <Typography variant="subtitle2" sx={{ p: 2 }}>
          Datos personales sobre su estado de salud
        </Typography>
        <Divider />
        <Box
          component="form"
          autoComplete="off"
          noValidate
          sx={{ my: 1, p: 2 }}
          onSubmit={handleSubmit(onSubmit)}
        >
          <Grid container spacing={3} sx={{ mb: 4 }}>
            <Grid item xs={12} lg={6}>
              <Controller
                name="healthCondition"
                control={control}
                defaultValue=""
                render={({ field }) => (
                  <MuiSelect
                    field={field}
                    options={healthConditions}
                    error={errors.healthCondition}
                    id="health-condition"
                    label="¿Cómo catalogaría su salud?*"
                    size="small"
                    fullWidth
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} lg={6}>
              <Controller
                name="lastPhysicalExam"
                control={control}
                defaultValue=""
                render={({ field }) => (
                  <Input
                    field={field}
                    error={errors.lastPhysicalExam}
                    label="¿Cuándo se sometió al último examen físico?*"
                    size="small"
                    type="date"
                    fullWidth
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} lg={6}>
              <FormControl>
                <FormLabel
                  id="medical-treatment-radio-buttons-group"
                  sx={{ typography: 'caption' }}
                >
                  Actualmente ¿se encuentra bajo algún tratamiento médico?
                </FormLabel>
                <RadioGroup
                  row
                  aria-labelledby="medical-treatment-radio-buttons-group"
                  name="medicalTreatmentRadio"
                  value={optionalValues.medicalTreatmentRadio}
                  onChange={handleChange}
                >
                  <FormControlLabel
                    value="yes"
                    control={<Radio />}
                    label={<Typography variant="body2">Sí</Typography>}
                  />
                  <FormControlLabel
                    value="no"
                    control={<Radio />}
                    label={<Typography variant="body2">No</Typography>}
                  />
                </RadioGroup>
              </FormControl>
              {optionalValues.medicalTreatmentRadio === 'yes' && (
                <Controller
                  name="medicalTreatment"
                  control={control}
                  defaultValue=""
                  render={({ field }) => (
                    <TextField
                      {...field}
                      label="Por favor especifique el medicamento y la enfermedad"
                      size="small"
                      rows={2}
                      multiline
                      fullWidth
                    />
                  )}
                />
              )}
            </Grid>
            <Grid item xs={12} lg={6}>
              <FormControl>
                <FormLabel
                  id="allergic-medicines-radio-buttons-group"
                  sx={{ typography: 'caption' }}
                >
                  Es usted alérgico a algún medicamento
                </FormLabel>
                <RadioGroup
                  row
                  aria-labelledby="allergic-medicines-radio-buttons-group"
                  name="allergicMedicinesRadio"
                  value={optionalValues.allergicMedicinesRadio}
                  onChange={handleChange}
                >
                  <FormControlLabel
                    value="yes"
                    control={<Radio />}
                    label={<Typography variant="body2">Sí</Typography>}
                  />
                  <FormControlLabel
                    value="no"
                    control={<Radio />}
                    label={<Typography variant="body2">No</Typography>}
                  />
                </RadioGroup>
              </FormControl>
              {optionalValues.allergicMedicinesRadio === 'yes' && (
                <Controller
                  name="allergicMedicines"
                  control={control}
                  defaultValue=""
                  render={({ field }) => (
                    <TextField
                      {...field}
                      label="Por favor especifique el medicamento"
                      size="small"
                      rows={2}
                      multiline
                      fullWidth
                    />
                  )}
                />
              )}
            </Grid>
            <Grid item xs={12} lg={6}>
              <FormControl>
                <FormLabel
                  id="allergic-materials-radio-buttons-group"
                  sx={{ typography: 'caption' }}
                >
                  Es usted alérgico al látex o cualquier otra sustancia o
                  material
                </FormLabel>
                <RadioGroup
                  row
                  aria-labelledby="allergic-materials-radio-buttons-group"
                  name="allergicMaterialsRadio"
                  value={optionalValues.allergicMaterialsRadio}
                  onChange={handleChange}
                >
                  <FormControlLabel
                    value="yes"
                    control={<Radio />}
                    label={<Typography variant="body2">Sí</Typography>}
                  />
                  <FormControlLabel
                    value="no"
                    control={<Radio />}
                    label={<Typography variant="body2">No</Typography>}
                  />
                </RadioGroup>
              </FormControl>
              {optionalValues.allergicMaterialsRadio === 'yes' && (
                <Controller
                  name="allergicMaterials"
                  control={control}
                  defaultValue=""
                  render={({ field }) => (
                    <TextField
                      {...field}
                      label="Por favor especifique"
                      size="small"
                      rows={2}
                      multiline
                      fullWidth
                    />
                  )}
                />
              )}
            </Grid>
            <Grid item xs={12}>
              <Controller
                name="previouslyAnesthetized"
                control={control}
                defaultValue={false}
                render={({ field }) => (
                  <FormControl>
                    <FormLabel
                      id="previously-anesthetized-radio-buttons-group"
                      sx={{ typography: 'caption' }}
                    >
                      Lo han anestesiado anteriormente
                    </FormLabel>
                    <RadioGroup
                      {...field}
                      row
                      aria-labelledby="previously-anesthetized-radio-buttons-group"
                    >
                      <FormControlLabel
                        value="yes"
                        control={<Radio />}
                        label={<Typography variant="body2">Sí</Typography>}
                      />
                      <FormControlLabel
                        value="no"
                        control={<Radio />}
                        label={<Typography variant="body2">No</Typography>}
                      />
                    </RadioGroup>
                  </FormControl>
                )}
              />
            </Grid>
            <Grid item xs={12} lg={6}>
              <FormControl>
                <FormLabel
                  id="anesthesia-reaction-radio-buttons-group"
                  sx={{ typography: 'caption' }}
                >
                  Ha tenido alguna reacción inusual
                </FormLabel>
                <RadioGroup
                  row
                  aria-labelledby="anesthesia-reaction-radio-buttons-group"
                  name="anesthesiaUnusualReactionRadio"
                  value={optionalValues.anesthesiaUnusualReactionRadio}
                  onChange={handleChange}
                >
                  <FormControlLabel
                    value="yes"
                    control={<Radio />}
                    label={<Typography variant="body2">Sí</Typography>}
                  />
                  <FormControlLabel
                    value="no"
                    control={<Radio />}
                    label={<Typography variant="body2">No</Typography>}
                  />
                </RadioGroup>
              </FormControl>
              {optionalValues.anesthesiaUnusualReactionRadio === 'yes' && (
                <Controller
                  name="anesthesiaUnusualReaction"
                  control={control}
                  defaultValue=""
                  render={({ field }) => (
                    <TextField
                      {...field}
                      label="Explíquela por favor"
                      size="small"
                      rows={2}
                      multiline
                      fullWidth
                    />
                  )}
                />
              )}
            </Grid>
            <Grid item xs={12}>
              <Controller
                name="diseasesSuffered"
                control={control}
                defaultValue={[]}
                render={({ field }) => (
                  <MuiSelect
                    field={field}
                    options={diseases}
                    id="diseases-suffered"
                    label="Señale por favor cualquier enfermedad pasada o presente que haya padecido"
                    size="small"
                    fullWidth
                    multiple
                  />
                )}
              />
            </Grid>
            <Grid item xs={12}>
              <Controller
                name="pregnancy"
                control={control}
                defaultValue={false}
                render={({ field }) => (
                  <FormControl>
                    <FormLabel
                      id="pregnancy-radio-buttons-group"
                      sx={{ typography: 'caption' }}
                    >
                      ¿Está embarazada?
                    </FormLabel>
                    <RadioGroup
                      {...field}
                      row
                      aria-labelledby="pregnancy-radio-buttons-group"
                    >
                      <FormControlLabel
                        value="yes"
                        control={<Radio />}
                        label={<Typography variant="body2">Sí</Typography>}
                      />
                      <FormControlLabel
                        value="no"
                        control={<Radio />}
                        label={<Typography variant="body2">No</Typography>}
                      />
                    </RadioGroup>
                  </FormControl>
                )}
              />
            </Grid>
            <Grid item xs={12} lg={6}>
              <Controller
                name="monthsPregnancy"
                control={control}
                defaultValue=""
                render={({ field }) => (
                  <TextField
                    {...field}
                    label="¿cuántos meses?"
                    size="small"
                    type="number"
                    fullWidth
                  />
                )}
              />
            </Grid>
            <Grid item xs={12}>
              <Controller
                name="breastfeeding"
                control={control}
                defaultValue={false}
                render={({ field }) => (
                  <FormControl>
                    <FormLabel
                      id="breastfeeding-radio-buttons-group"
                      sx={{ typography: 'caption' }}
                    >
                      ¿Está amamantando?
                    </FormLabel>
                    <RadioGroup
                      {...field}
                      row
                      aria-labelledby="breastfeeding-radio-buttons-group"
                    >
                      <FormControlLabel
                        value="yes"
                        control={<Radio />}
                        label={<Typography variant="body2">Sí</Typography>}
                      />
                      <FormControlLabel
                        value="no"
                        control={<Radio />}
                        label={<Typography variant="body2">No</Typography>}
                      />
                    </RadioGroup>
                  </FormControl>
                )}
              />
            </Grid>
          </Grid>
          <Stack direction="row" spacing={1} sx={{ mt: 4 }}>
            <Box sx={{ position: 'relative' }}>
              <Button
                type="submit"
                variant="contained"
                color="primary"
                size="small"
                startIcon={<SaveIcon />}
                disabled={isLoading}
              >
                Guardar
              </Button>
              {isLoading && (
                <CircularProgress
                  size={24}
                  sx={{
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    marginTop: '-12px',
                    marginLeft: '-12px',
                  }}
                />
              )}
            </Box>
            <Button
              size="small"
              startIcon={<ClearIcon />}
              onClick={() => onCancel()}
            >
              Cancelar
            </Button>
          </Stack>
        </Box>
      </Paper>
    </>
  )
}

export default AddMedicalHistory
