import { useState, useEffect } from 'react'
import { Routes, Route, Navigate, useLocation } from 'react-router-dom'
import { createTheme, ThemeProvider } from '@mui/material/styles'
import { teal, green } from '@mui/material/colors'
import CssBaseline from '@mui/material/CssBaseline'
import Box from '@mui/material/Box'
import Toolbar from '@mui/material/Toolbar'
import ResponsiveAppBar from '../../components/public/layout/ResponsiveAppBar'
import ResponsiveDrawer from '../../components/public/layout/ResponsiveDrawer'
import HomePage from './HomePage'
import ServicesPage from './ServicesPage'
import ContactPage from './ContactPage'
import FabButton from '../../components/public/common/FabButton'
import Footer from '../../components/public/layout/Footer'

const landingTheme = createTheme({
  palette: {
    // mode: 'dark',
    primary: {
      main: teal[500],
    },
    secondary: {
      main: green[500],
    },
    neutral: {
      main: '#fff',
      contrastText: '#fff',
    },
  },
})

const drawerWidth = 240

const ScrollToTop = ({ children }) => {
  const location = useLocation()
  useEffect(() => {
    document.documentElement.scrollTo({ top: 0, left: 0, behavior: 'smooth' })
  }, [location.pathname])

  return children
}

function Landing() {
  const [mobileOpen, setMobileOpen] = useState(false)
  return (
    <>
      <ThemeProvider theme={landingTheme}>
        <CssBaseline />
        <Box sx={{ display: 'flex' }}>
          <ResponsiveAppBar open={mobileOpen} onOpenChange={setMobileOpen} />
          <ResponsiveDrawer
            drawerWidth={drawerWidth}
            open={mobileOpen}
            onOpenChange={setMobileOpen}
          />
          <Box
            component="main"
            sx={{
              // backgroundColor: (theme) => theme.palette.grey[200],
              // height: '100vh',
              flexGrow: 1,
              overflow: 'auto',
            }}
          >
            <Toolbar />
            <FabButton />
            <ScrollToTop>
              <Routes>
                <Route index element={<HomePage />} />
                <Route path="services" element={<ServicesPage />} />
                <Route path="contact" element={<ContactPage />} />
                <Route path="*" element={<Navigate to="/" replace />} />
              </Routes>
            </ScrollToTop>
            <Footer />
          </Box>
        </Box>
      </ThemeProvider>
    </>
  )
}

export default Landing
