import { useEffect } from 'react'
import { useDispatch } from 'react-redux'
// import moment from 'moment'
// import Paper from '@mui/material/Paper'
// import Box from '@mui/material/Box'
// import Typography from '@mui/material/Typography'
import Grid from '@mui/material/Grid'
import Paper from '@mui/material/Paper'
import Chart from '../../components/admin/dashboard/Chart'
import TodayPayments from '../../components/admin/dashboard/TodayPayments'
import RecentPayments from '../../components/admin/dashboard/RecentPayments'
import { getAllPayments } from '../../features/payments/paymentsSlice'

const HomePage = () => {
  // const { user } = useSelector((state) => state.auth)
  // const date = moment().format('dddd, LL')
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(getAllPayments())
  }, [dispatch])

  return (
    <>
      {/* <Paper> */}
      {/* <Box sx={{ p: 2 }}>
        <Typography variant="h5" sx={{ fontWeight: 'bold' }}>
          Bienvenido {user.name}!
        </Typography>
        <Typography variant="overline">{date}</Typography>
      </Box> */}
      {/* </Paper> */}
      <Grid container spacing={3}>
        <Grid item xs={12} sm={8} md={8} lg={9}>
          <Paper
            sx={{
              p: 2,
              display: 'flex',
              flexDirection: 'column',
              height: 280,
            }}
          >
            <Chart />
          </Paper>
        </Grid>
        <Grid item xs={12} sm={4} md={4} lg={3}>
          <Paper
            sx={{
              p: 2,
              display: 'flex',
              flexDirection: 'column',
              height: 280,
            }}
          >
            <TodayPayments />
          </Paper>
        </Grid>
        <Grid item xs={12}>
          <Paper sx={{ p: 2, display: 'flex', flexDirection: 'column' }}>
            <RecentPayments />
          </Paper>
        </Grid>
      </Grid>
    </>
  )
}

export default HomePage
