import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import medicalHistoryService from './MedicalHistoryService'

const initialState = {
  medicalHistory: null,
  isError: false,
  isSuccess: false,
  isLoading: false,
  message: '',
}

// Create new medical history
export const createMedicalHistory = createAsyncThunk(
  'medicalHistory/create',
  async (data, thunkAPI) => {
    try {
      const token = thunkAPI.getState().auth.user.token
      return await medicalHistoryService.createMedicalHistory(data, token)
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString()

      return thunkAPI.rejectWithValue(message)
    }
  }
)

// Update medical history
export const updateMedicalHistory = createAsyncThunk(
  'medicalHistory/update',
  async (data, thunkAPI) => {
    try {
      const token = thunkAPI.getState().auth.user.token
      return await medicalHistoryService.updateMedicalHistory(data, token)
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString()

      return thunkAPI.rejectWithValue(message)
    }
  }
)

/* // Get patient
export const getPatient = createAsyncThunk(
  'paciente/get',
  async (patientId, thunkAPI) => {
    try {
      const token = thunkAPI.getState().auth.user.token
      return await pacienteService.getPatient(patientId, token)
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString()

      return thunkAPI.rejectWithValue(message)
    }
  }
)

// Delete patient
export const deletePatient = createAsyncThunk(
  'paciente/delete',
  async (patientId, thunkAPI) => {
    try {
      const token = thunkAPI.getState().auth.user.token
      return await pacienteService.deletePatient(patientId, token)
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString()

      return thunkAPI.rejectWithValue(message)
    }
  }
) */

const medicalHistorySlice = createSlice({
  name: 'medicalHistory',
  initialState,
  reducers: {
    reset: (state) => initialState,
  },
  extraReducers: (builder) => {
    builder
      .addCase(createMedicalHistory.pending, (state) => {
        state.isLoading = true
      })
      .addCase(createMedicalHistory.fulfilled, (state, action) => {
        state.isLoading = false
        state.isSuccess = true
      })
      .addCase(createMedicalHistory.rejected, (state, action) => {
        state.isLoading = false
        state.isError = true
        state.message = action.payload
      })
      .addCase(updateMedicalHistory.pending, (state) => {
        state.isLoading = true
      })
      .addCase(updateMedicalHistory.fulfilled, (state, action) => {
        state.isLoading = false
        state.isSuccess = true
      })
      .addCase(updateMedicalHistory.rejected, (state, action) => {
        state.isLoading = false
        state.isError = true
        state.message = action.payload
      })
  },
})

export const { reset } = medicalHistorySlice.actions
export default medicalHistorySlice.reducer
