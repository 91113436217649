import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import patientService from './PatientService'

const initialState = {
  patients: [],
  patient: null,
  isError: false,
  isSuccess: false,
  isLoading: false,
  message: '',
}

// Create new patient
export const createPatient = createAsyncThunk(
  'patient/create',
  async (patientData, thunkAPI) => {
    try {
      const token = thunkAPI.getState().auth.user.token
      return await patientService.createPatient(patientData, token)
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString()

      return thunkAPI.rejectWithValue(message)
    }
  }
)

// Get user patients
export const getPatients = createAsyncThunk(
  'patient/getAll',
  async (_, thunkAPI) => {
    try {
      const token = thunkAPI.getState().auth.user.token
      return await patientService.getPatients(token)
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString()

      return thunkAPI.rejectWithValue(message)
    }
  }
)

// Get patient
export const getPatient = createAsyncThunk(
  'patient/get',
  async (patientId, thunkAPI) => {
    try {
      const token = thunkAPI.getState().auth.user.token
      return await patientService.getPatient(patientId, token)
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString()

      return thunkAPI.rejectWithValue(message)
    }
  }
)

// Update patient
export const updatePatient = createAsyncThunk(
  'patient/update',
  async (patientData, thunkAPI) => {
    try {
      const token = thunkAPI.getState().auth.user.token
      return await patientService.updatePatient(patientData, token)
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString()

      return thunkAPI.rejectWithValue(message)
    }
  }
)

// Delete patient
export const deletePatient = createAsyncThunk(
  'patient/delete',
  async (patientId, thunkAPI) => {
    try {
      const token = thunkAPI.getState().auth.user.token
      return await patientService.deletePatient(patientId, token)
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString()

      return thunkAPI.rejectWithValue(message)
    }
  }
)

const patientSlice = createSlice({
  name: 'patient',
  initialState,
  reducers: {
    reset: (state) => initialState,
  },
  extraReducers: (builder) => {
    builder
      .addCase(createPatient.pending, (state) => {
        state.isLoading = true
      })
      .addCase(createPatient.fulfilled, (state, action) => {
        state.isLoading = false
        state.isSuccess = true
      })
      .addCase(createPatient.rejected, (state, action) => {
        state.isLoading = false
        state.isError = true
        state.message = action.payload
      })
      .addCase(getPatients.pending, (state) => {
        state.isLoading = true
      })
      .addCase(getPatients.fulfilled, (state, action) => {
        state.isLoading = false
        // state.isSuccess = true
        state.patients = action.payload
      })
      .addCase(getPatients.rejected, (state, action) => {
        state.isLoading = false
        state.isError = true
        state.message = action.payload
      })
      .addCase(getPatient.pending, (state) => {
        state.isLoading = true
      })
      .addCase(getPatient.fulfilled, (state, action) => {
        state.isLoading = false
        // state.isSuccess = true
        state.patient = action.payload
      })
      .addCase(getPatient.rejected, (state, action) => {
        state.isLoading = false
        state.isError = true
        state.message = action.payload
      })
      .addCase(updatePatient.pending, (state) => {
        state.isLoading = true
      })
      .addCase(updatePatient.fulfilled, (state, action) => {
        state.isSuccess = true
        state.isLoading = false
        state.patient = action.payload
      })
      .addCase(updatePatient.rejected, (state, action) => {
        state.isError = true
        state.isLoading = false
        state.message = action.payload
      })
      .addCase(deletePatient.pending, (state) => {
        state.isLoading = true
      })
      .addCase(deletePatient.fulfilled, (state, action) => {
        state.isSuccess = true
        state.isLoading = false
        state.message = action.payload
      })
      .addCase(deletePatient.rejected, (state, action) => {
        state.isError = true
        state.isLoading = false
        state.message = action.payload
      })
  },
})

export const { reset } = patientSlice.actions
export default patientSlice.reducer
