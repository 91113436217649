import { useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useNavigate, Link as RouterLink } from 'react-router-dom'
import { toast } from 'react-toastify'
import moment from 'moment'
import Grid from '@mui/material/Grid'
import Stack from '@mui/material/Stack'
import Box from '@mui/material/Box'
import FormLabel from '@mui/material/FormLabel'
import OutlinedInput from '@mui/material/OutlinedInput'
import Typography from '@mui/material/Typography'
import Button from '@mui/material/Button'
import InputAdornment from '@mui/material/InputAdornment'
import FormControl from '@mui/material/FormControl'
import FormHelperText from '@mui/material/FormHelperText'
import SaveIcon from '@mui/icons-material/Save'
import ClearIcon from '@mui/icons-material/Clear'
import CircularProgress from '@mui/material/CircularProgress'
import Paper from '@mui/material/Paper'
import IconButton from '@mui/material/IconButton'
import HomeIcon from '@mui/icons-material/Home'
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight'
import Link from '@mui/material/Link'

import { useForm, Controller } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import expenseSchema from '../../../validators/expenseSchema'
import { createExpense, reset } from '../../../features/expenses/ExpenseSlice'

const AddExpense = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const date = moment().format('DD/MMM/YYYY')

  const { isLoading, isError, isSuccess, message } = useSelector(
    (state) => state.expenses
  )

  const {
    control,
    formState: { errors },
    handleSubmit,
  } = useForm({
    mode: 'onChange',
    resolver: yupResolver(expenseSchema),
  })

  useEffect(() => {
    if (isError) {
      toast.error(message)
      dispatch(reset())
    }

    if (isSuccess) {
      dispatch(reset())
      toast.success('Nuevo gasto agregado')
      navigate(`/dashboard/expenses`)
    }

    dispatch(reset())
  }, [dispatch, isError, isSuccess, message, navigate])

  const onSubmit = (data, e) => {
    e.preventDefault()
    // console.log(data)
    dispatch(createExpense(data))
  }

  return (
    <>
      <Paper sx={{ p: 2, mb: 2 }}>
        <Stack direction="row" justifyContent="space-between" spacing={1}>
          <Typography sx={{ fontWeight: 'bold' }} variant="h5">
            Nuevo Gasto
          </Typography>
          <Stack direction="row" alignItems="center" spacing={1}>
            <IconButton
              aria-label="home"
              size="small"
              color="primary"
              onClick={() => navigate(`/dashboard`)}
            >
              <HomeIcon fontSize="small" />
            </IconButton>
            <KeyboardArrowRightIcon fontSize="small" />
            <Link
              component={RouterLink}
              to="/dashboard/expenses"
              color="inherit"
              variant="subtitle2"
              underline="none"
            >
              Gastos
            </Link>
          </Stack>
        </Stack>
      </Paper>
      <Paper sx={{ p: 2, mb: 2 }}>
        <Stack direction="row" justifyContent="flex-end" spacing={1}>
          {/* <Typography sx={{ fontWeight: 'bold' }} variant="h5">
          Nuevo Paciente
        </Typography> */}
          <Typography variant="body2" color="textSecondary">
            Fecha: <strong>{date}</strong>
          </Typography>
        </Stack>
        <Box
          component="form"
          autoComplete="off"
          noValidate
          sx={{ mb: 2 }}
          onSubmit={handleSubmit(onSubmit)}
        >
          <Grid container spacing={2} sx={{ mb: 4 }}>
            <Grid item xs={12} md={6}>
              <Controller
                name="amount"
                control={control}
                defaultValue={''}
                render={({ field }) => (
                  <FormControl fullWidth>
                    <FormLabel
                      id="amount"
                      error={!!errors.amount}
                      sx={{ typography: 'subtitle2', pb: 1 }}
                    >
                      Cantidad
                    </FormLabel>
                    <OutlinedInput
                      {...field}
                      placeholder="0"
                      type="number"
                      error={!!errors.amount}
                      startAdornment={
                        <InputAdornment position="start">$</InputAdornment>
                      }
                    />
                    <FormHelperText id="amount" error={!!errors.amount}>
                      {errors.amount && errors.amount.message}
                    </FormHelperText>
                  </FormControl>
                )}
              />
            </Grid>
            <Grid item xs={12} md={12}>
              <Controller
                name="notes"
                control={control}
                defaultValue=""
                render={({ field }) => (
                  <FormControl {...field} fullWidth>
                    <FormLabel
                      id="notes"
                      sx={{ typography: 'subtitle2', pb: 1 }}
                    >
                      Notas
                    </FormLabel>
                    <OutlinedInput
                      multiline
                      rows={2}
                      placeholder="Referencia y/o descripción..."
                    />
                  </FormControl>
                )}
              />
            </Grid>
          </Grid>
          <Stack direction="row" spacing={1} sx={{ mt: 4 }}>
            <Box sx={{ position: 'relative' }}>
              <Button
                type="submit"
                variant="contained"
                color="primary"
                size="small"
                startIcon={<SaveIcon />}
                disabled={isLoading}
              >
                Guardar
              </Button>
              {isLoading && (
                <CircularProgress
                  size={24}
                  sx={{
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    marginTop: '-12px',
                    marginLeft: '-12px',
                  }}
                />
              )}
            </Box>
            <Button
              size="small"
              startIcon={<ClearIcon />}
              onClick={() => navigate(`/dashboard/expenses`)}
            >
              Cancelar
            </Button>
          </Stack>
        </Box>
      </Paper>
    </>
  )
}

export default AddExpense
