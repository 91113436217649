import { Routes, Route, Navigate } from 'react-router-dom'

import Expenses from '../../components/admin/expenses/Expenses'
import AddExpense from '../../components/admin/expenses/AddExpense'
import ExpenseView from '../../components/admin/expenses/ExpenseView'
import ExpenseEdit from '../../components/admin/expenses/ExpenseEdit'

const ExpensesPage = () => {
  return (
    <>
      <Routes>
        <Route index element={<Expenses />} />
        <Route path="new" element={<AddExpense />} />
        <Route path=":id/*" element={<ExpenseView />} />
        <Route path=":id/edit" element={<ExpenseEdit />} />
        <Route path="*" element={<Navigate to="/" replace />} />
      </Routes>
    </>
  )
}

export default ExpensesPage
