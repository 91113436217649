import React from 'react'
import { useNavigate } from 'react-router-dom'
import Paper from '@mui/material/Paper'
import Button from '@mui/material/Button'
import AddIcon from '@mui/icons-material/Add'
import IconButton from '@mui/material/IconButton'
import HomeIcon from '@mui/icons-material/Home'
import Stack from '@mui/material/Stack'
import Typography from '@mui/material/Typography'

import PatientsList from './PatientsList'

const Patients = () => {
  const navigate = useNavigate()

  const handleNewPatient = () => {
    navigate(`/dashboard/patients/new`)
  }

  return (
    <>
      <Paper sx={{ p: 2, mb: 2 }}>
        <Stack direction="row" justifyContent="space-between" spacing={1}>
          <Typography sx={{ fontWeight: 'bold' }} variant="h5">
            Pacientes
          </Typography>
          <Stack direction="row" alignItems="center" spacing={1}>
            <IconButton
              aria-label="home"
              size="small"
              color="primary"
              onClick={() => navigate(`/dashboard`)}
            >
              <HomeIcon fontSize="small" />
            </IconButton>
          </Stack>
        </Stack>
      </Paper>
      <Paper sx={{ p: 2 }}>
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          spacing={1}
          sx={{ pb: 2 }}
        >
          <Typography sx={{ fontWeight: 'bold' }} variant="h6">
            Listado de Pacientes
          </Typography>
          <Button
            variant="contained"
            color="primary"
            size="small"
            sx={{
              mt: 1,
              mb: 3,
            }}
            startIcon={<AddIcon />}
            onClick={() => handleNewPatient()}
          >
            Nuevo
          </Button>
        </Stack>

        <PatientsList />
      </Paper>
    </>
  )
}

export default Patients
