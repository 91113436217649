import { configureStore } from '@reduxjs/toolkit'
import authReducer from '../features/auth/authSlice'
import patientReducer from '../features/patients/PatientSlice'
import medicalHistoryReducer from '../features/medicalHistory/MedicalHistorySlice'
import appointmentReducer from '../features/appointments/AppointmentsSlice'
import paymentReducer from '../features/payments/paymentsSlice'
import expenseReducer from '../features/expenses/ExpenseSlice'

export const store = configureStore({
  reducer: {
    auth: authReducer,
    patients: patientReducer,
    medicalHistory: medicalHistoryReducer,
    appointments: appointmentReducer,
    payments: paymentReducer,
    expenses: expenseReducer,
  },
})
