import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import moment from 'moment'
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  Label,
  Tooltip,
  CartesianGrid,
  ResponsiveContainer,
} from 'recharts'
import { useTheme } from '@mui/material/styles'

import Title from '../common/Title'

export default function Chart() {
  const theme = useTheme()

  const { payments } = useSelector((state) => state.payments)
  const [weekSummary, setWeekSummary] = useState([])

  useEffect(() => {
    if (payments) {
      let week = []
      for (let i = 1; i < 7; i++) {
        let total = 0
        const today = moment().day(i).format('YYYY-MM-DD')
        payments.forEach((p) => {
          const paymentDate = moment(p.createdAt).format('YYYY-MM-DD')
          const isSame = moment(today).isSame(paymentDate)

          if (isSame) total += p.amount
        })
        const day = {
          date: today,
          day: moment().day(i).format('dddd'),
          total,
        }
        week.push(day)
      }

      setWeekSummary(week)
    }
  }, [payments])

  return (
    <>
      <Title>Resumen Semanal</Title>
      <ResponsiveContainer>
        <LineChart
          data={weekSummary}
          margin={{
            top: 16,
            right: 16,
            bottom: 0,
            left: 24,
          }}
        >
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis
            dataKey="day"
            stroke={theme.palette.text.secondary}
            style={theme.typography.body2}
          />
          <YAxis
            stroke={theme.palette.text.secondary}
            style={theme.typography.body2}
          >
            <Label
              angle={270}
              position="left"
              style={{
                textAnchor: 'middle',
                fill: theme.palette.text.primary,
                ...theme.typography.body1,
              }}
            >
              Pagos ($)
            </Label>
          </YAxis>
          <Tooltip />
          <Line
            isAnimationActive={false}
            type="monotone"
            dataKey="total"
            stroke={theme.palette.primary.main}
          />
        </LineChart>
      </ResponsiveContainer>
    </>
  )
}
