import { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { Link as RouterLink, useNavigate } from 'react-router-dom'

import Avatar from '@mui/material/Avatar'
import Button from '@mui/material/Button'
import CssBaseline from '@mui/material/CssBaseline'
import TextField from '@mui/material/TextField'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import Stack from '@mui/material/Stack'
import Container from '@mui/material/Container'
import InputAdornment from '@mui/material/InputAdornment'
import IconButton from '@mui/material/IconButton'
import Visibility from '@mui/icons-material/Visibility'
import VisibilityOff from '@mui/icons-material/VisibilityOff'
import CircularProgress from '@mui/material/CircularProgress'
import Alert from '@mui/material/Alert'
import Grid from '@mui/material/Grid'
import Link from '@mui/material/Link'
import { useForm, Controller } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import loginSchema from '../../validators/loginSchema'
import { login, reset } from '../../features/auth/authSlice'

function Login() {
  const dispatch = useDispatch()
  const navigate = useNavigate()

  const {
    control,
    formState: { errors },
    handleSubmit,
  } = useForm({
    mode: 'onChange',
    resolver: yupResolver(loginSchema),
    defaultValues: {
      email: '',
      password: '',
    },
  })

  const { user, isLoading, isSuccess, isError, message } = useSelector(
    (state) => state.auth
  )
  const [showPassword, setShowPassword] = useState(false)

  useEffect(() => {
    // Redirect when logged in
    if (isSuccess && user) {
      navigate('/dashboard')
    }

    return () => {
      dispatch(reset())
    }
  }, [user, isSuccess, navigate, dispatch])

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword)
  }

  const handleMouseDownPassword = (event) => {
    event.preventDefault()
  }

  const onSubmit = (data, e) => {
    e.preventDefault()
    // console.log(data)
    dispatch(login(data))
  }

  return (
    <>
      <Container component="main" maxWidth="xs">
        <CssBaseline />
        <Box
          sx={{
            marginTop: 8,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <Avatar alt="Logo" src="/logo.jpg" sx={{ width: 100, height: 100 }} />
          <Typography component="h1" variant="h5" sx={{ mt: 2 }}>
            Dr. Diego Martín Pantoja
          </Typography>
          <Box
            component="form"
            noValidate
            sx={{ mt: 4, width: '100%' }}
            onSubmit={handleSubmit(onSubmit)}
          >
            <Stack spacing={2}>
              {isError && <Alert severity="error">{message}</Alert>}
              <Controller
                name="email"
                control={control}
                defaultValue=""
                render={({ field }) => (
                  <TextField
                    {...field}
                    id="email"
                    label="Correo electrónico *"
                    variant="outlined"
                    type="email"
                    fullWidth
                    autoFocus
                    error={!!errors.email}
                    helperText={errors.email && errors.email.message}
                  />
                )}
              />
              <Controller
                name="password"
                control={control}
                defaultValue=""
                render={({ field }) => (
                  <TextField
                    {...field}
                    id="password"
                    label="Password *"
                    variant="outlined"
                    fullWidth
                    type={showPassword ? 'text' : 'password'}
                    error={!!errors.password}
                    helperText={errors.password && errors.password.message}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton
                            aria-label="toggle password visibility"
                            onClick={handleClickShowPassword}
                            onMouseDown={handleMouseDownPassword}
                            edge="end"
                            size="small"
                          >
                            {showPassword ? (
                              <Visibility
                                color={!!errors.password ? 'error' : undefined}
                              />
                            ) : (
                              <VisibilityOff
                                color={!!errors.password ? 'error' : undefined}
                              />
                            )}
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                  />
                )}
              />
              <Box sx={{ mt: 4, position: 'relative' }}>
                <Button
                  type="submit"
                  variant="contained"
                  disabled={isLoading}
                  fullWidth
                >
                  Iniciar sesión
                </Button>
                {isLoading && (
                  <CircularProgress
                    size={24}
                    sx={{
                      position: 'absolute',
                      top: '50%',
                      left: '50%',
                      marginTop: '-12px',
                      marginLeft: '-12px',
                    }}
                  />
                )}
              </Box>
            </Stack>
          </Box>
          <Grid container sx={{ my: 4 }}>
            <Grid item xs>
              <Link component={RouterLink} to="/" variant="body2">
                {'Regresar al sitio web.'}
              </Link>
            </Grid>
            {/* <Grid item>
              <Link href="#" variant="body2">
                {"Don't have an account? Sign Up"}
              </Link>
            </Grid> */}
          </Grid>
        </Box>
      </Container>
    </>
  )
}

export default Login
