import React, { useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useParams, useNavigate, Link as RouterLink } from 'react-router-dom'
// import moment from 'moment'

import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography'
import IconButton from '@mui/material/IconButton'
import Paper from '@mui/material/Paper'
import Stack from '@mui/material/Stack'
import HomeIcon from '@mui/icons-material/Home'
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight'
import Link from '@mui/material/Link'
import LinearProgress from '@mui/material/LinearProgress'

import {
  getPatient,
  // deletePatient,
} from '../../../features/patients/PatientSlice'
import TabsComponent from './tabs/TabsComponent'
// import ConfirmationDialog from '../common/ConfirmationDialog'

const PatientView = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const { id } = useParams()

  const { patient } = useSelector((state) => state.patients)

  const [activePatient, setActivePatient] = useState(null)
  // const [confirmOpen, setConfirmOpen] = useState(false)

  useEffect(() => {
    dispatch(getPatient(id))
  }, [dispatch, id])

  useEffect(() => {
    if (patient) {
      setActivePatient(patient)
    }
  }, [patient])

  return (
    <>
      {activePatient ? (
        <div>
          <Paper sx={{ p: 2, mb: 2 }}>
            <Stack direction="row" justifyContent="space-between" spacing={1}>
              <Grid
                container
                direction="row"
                alignItems="center"
                justifyContent="space-between"
              >
                <Grid item xs={12} sm={8}>
                  <Stack direction="column" justifyContent="space-between">
                    <Typography sx={{ fontWeight: 'bold' }} variant="h5">
                      {activePatient.name}
                    </Typography>
                    <Typography color="textSecondary" variant="caption">
                      #{activePatient._id}
                    </Typography>
                    {/* <Typography color="textSecondary" variant="caption">
                      {`Fecha Alta: ${moment(activePatient.createdAt).format(
                        'DD/MMM/YYYY'
                      )}`}
                    </Typography> */}
                  </Stack>
                </Grid>
                <Grid item xs="auto">
                  <Stack direction="row" alignItems="center" spacing={1}>
                    <IconButton
                      aria-label="home"
                      size="small"
                      color="primary"
                      onClick={() => navigate(`/dashboard`)}
                    >
                      <HomeIcon fontSize="small" />
                    </IconButton>
                    <KeyboardArrowRightIcon fontSize="small" />
                    <Link
                      component={RouterLink}
                      to="/dashboard/patients"
                      color="inherit"
                      variant="subtitle2"
                      underline="none"
                    >
                      Pacientes
                    </Link>
                  </Stack>
                </Grid>
              </Grid>
            </Stack>
          </Paper>
          <Paper sx={{ mb: 2 }}>
            <TabsComponent />
          </Paper>
          {/* <ConfirmationDialog
            keepMounted
            title="Confirmación"
            children={
              <>
                <Typography variant="body1">
                  ¿Está seguro que desea eliminar el paciente?
                </Typography>
                <Typography variant="body1" sx={{ fontWeight: 'bold', mb: 2 }}>
                  {patient.name}
                </Typography>
                <Typography variant="caption">
                  *Todos los registros relacionados con el paciente se
                  eliminarán.
                </Typography>
              </>
            }
            open={confirmOpen}
            setOpen={setConfirmOpen}
            onConfirm={() => {
              dispatch(deletePatient(id))
            }}
          /> */}
        </div>
      ) : (
        <Stack direction="column" spacing={2}>
          <Paper sx={{ minHeight: 84 }}>
            <LinearProgress />
          </Paper>
          <Paper sx={{ minHeight: 500 }}>
            <LinearProgress />
          </Paper>
        </Stack>
      )}
    </>
  )
}

export default PatientView
