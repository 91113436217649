import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import expenseService from './ExpenseService'

const initialState = {
  expenses: [],
  expense: null,
  isError: false,
  isSuccess: false,
  isLoading: false,
  message: '',
}

// Create new expense
export const createExpense = createAsyncThunk(
  'expense/create',
  async (expenseData, thunkAPI) => {
    try {
      const token = thunkAPI.getState().auth.user.token
      return await expenseService.createExpense(expenseData, token)
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString()

      return thunkAPI.rejectWithValue(message)
    }
  }
)

// Get user expenses
export const getExpenses = createAsyncThunk(
  'expense/getAll',
  async (_, thunkAPI) => {
    try {
      const token = thunkAPI.getState().auth.user.token
      return await expenseService.getExpenses(token)
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString()

      return thunkAPI.rejectWithValue(message)
    }
  }
)

// Get expense
export const getExpense = createAsyncThunk(
  'expense/get',
  async (expenseId, thunkAPI) => {
    try {
      const token = thunkAPI.getState().auth.user.token
      return await expenseService.getExpense(expenseId, token)
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString()

      return thunkAPI.rejectWithValue(message)
    }
  }
)

// Update expense
export const updateExpense = createAsyncThunk(
  'patieexpensent/update',
  async (expenseData, thunkAPI) => {
    try {
      const token = thunkAPI.getState().auth.user.token
      return await expenseService.updateExpense(expenseData, token)
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString()

      return thunkAPI.rejectWithValue(message)
    }
  }
)

// Delete expense
export const deleteExpense = createAsyncThunk(
  'expense/delete',
  async (expenseId, thunkAPI) => {
    try {
      const token = thunkAPI.getState().auth.user.token
      return await expenseService.deleteExpense(expenseId, token)
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString()

      return thunkAPI.rejectWithValue(message)
    }
  }
)

const expenseSlice = createSlice({
  name: 'expense',
  initialState,
  reducers: {
    reset: (state) => initialState,
    resetError: (state) => {
      state.isError = false
      state.message = ''
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(createExpense.pending, (state) => {
        state.isLoading = true
      })
      .addCase(createExpense.fulfilled, (state, action) => {
        state.isLoading = false
        state.isSuccess = true
      })
      .addCase(createExpense.rejected, (state, action) => {
        state.isLoading = false
        state.isError = true
        state.message = action.payload
      })
      .addCase(getExpenses.pending, (state) => {
        state.isLoading = true
      })
      .addCase(getExpenses.fulfilled, (state, action) => {
        state.isLoading = false
        // state.isSuccess = true
        state.expenses = action.payload
      })
      .addCase(getExpenses.rejected, (state, action) => {
        state.isLoading = false
        state.isError = true
        state.message = action.payload
      })
      .addCase(getExpense.pending, (state) => {
        state.isLoading = true
      })
      .addCase(getExpense.fulfilled, (state, action) => {
        state.isLoading = false
        // state.isSuccess = true
        state.expense = action.payload
      })
      .addCase(getExpense.rejected, (state, action) => {
        state.isLoading = false
        state.isError = true
        state.message = action.payload
      })
      .addCase(updateExpense.pending, (state) => {
        state.isLoading = true
      })
      .addCase(updateExpense.fulfilled, (state, action) => {
        state.isSuccess = true
        state.isLoading = false
        state.expense = action.payload
      })
      .addCase(updateExpense.rejected, (state, action) => {
        state.isError = true
        state.isLoading = false
        state.message = action.payload
      })
      .addCase(deleteExpense.pending, (state) => {
        state.isLoading = true
      })
      .addCase(deleteExpense.fulfilled, (state, action) => {
        state.isSuccess = true
        state.isLoading = false
        state.message = action.payload
      })
      .addCase(deleteExpense.rejected, (state, action) => {
        state.isError = true
        state.isLoading = false
        state.message = action.payload
      })
  },
})

export const { reset, resetError } = expenseSlice.actions
export default expenseSlice.reducer
