import { useSelector } from 'react-redux'
import { Link as RouterLink } from 'react-router-dom'
import ListItemButton from '@mui/material/ListItemButton'
import ListItemText from '@mui/material/ListItemText'
import Button from '@mui/material/Button'

const MainListItems = () => {
  const { user } = useSelector((state) => state.auth)
  return (
    <>
      <Button component={RouterLink} to="/" color="neutral">
        Inicio
      </Button>
      <Button component={RouterLink} to="/services" color="neutral">
        Servicios
      </Button>
      <Button component={RouterLink} to="/contact" color="neutral">
        Contacto
      </Button>
      {user && (
        <Button component={RouterLink} to="/dashboard" color="neutral">
          Dashboard
        </Button>
      )}
    </>
  )
}

const MobileListItems = () => {
  const { user } = useSelector((state) => state.auth)
  return (
    <>
      <ListItemButton component={RouterLink} to="/">
        <ListItemText primary="Inicio" />
      </ListItemButton>
      <ListItemButton component={RouterLink} to="/services">
        <ListItemText primary="Servicios" />
      </ListItemButton>
      <ListItemButton component={RouterLink} to="/contact">
        <ListItemText primary="Contacto" />
      </ListItemButton>
      {user && (
        <ListItemButton component={RouterLink} to="/dashboard">
          <ListItemText primary="Dashboard" />
        </ListItemButton>
      )}
    </>
  )
}

export { MainListItems, MobileListItems }
