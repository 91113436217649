import axios from 'axios'

const API_URL = '/api/v1/payments/'

// Create new payment
const createPayment = async (data, token) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  }

  const response = await axios.post(`${API_URL}`, data, config)

  return response.data
}

// Get all user payments
const getAllPayments = async (token) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  }

  const response = await axios.get(`${API_URL}`, config)

  return response.data
}

// Get payment
const getPayment = async (id, token) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  }

  const response = await axios.get(`${API_URL}${id}`, config)

  return response.data
}

// Update payment
const updatePayment = async (paymentData, token) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  }

  const { id, data } = paymentData
  const response = await axios.put(`${API_URL}${id}`, data, config)

  return response.data
}

// Delete payment
const deletePayment = async (id, token) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  }

  const response = await axios.delete(`${API_URL}${id}`, config)

  return response.data
}

const paymentsService = {
  createPayment,
  getAllPayments,
  getPayment,
  updatePayment,
  deletePayment,
}

export default paymentsService
