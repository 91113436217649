import Box from '@mui/material/Box'
import Divider from '@mui/material/Divider'
import Drawer from '@mui/material/Drawer'
import List from '@mui/material/List'
import Toolbar from './Toolbar'
import { MobileListItems } from './listItems'

// import Box from '@mui/material/Box'
import Grid from '@mui/material/Grid'
import Link from '@mui/material/Link'
import Container from '@mui/material/Container'
import Typography from '@mui/material/Typography'
import Stack from '@mui/material/Stack'
import Avatar from '@mui/material/Avatar'
import { red, blue, green, blueGrey } from '@mui/material/colors'
import FacebookIcon from '@mui/icons-material/Facebook'
import WhatsAppIcon from '@mui/icons-material/WhatsApp'
import LocalPhoneIcon from '@mui/icons-material/LocalPhone'
import PlaceIcon from '@mui/icons-material/Place'

function ResponsiveDrawer({ drawerWidth, open, onOpenChange, ...props }) {
  const { window } = props

  const drawer = (
    <Box onClick={() => onOpenChange(!open)}>
      <Toolbar sx={{ justifyContent: 'center' }}>
        <Box
          component="img"
          src="/static/img/brand/main-logo-green-letters.png"
          alt="Logo Alt"
          sx={{
            height: 50,
          }}
        />
      </Toolbar>
      <Divider />
      <List>
        <MobileListItems />
      </List>
      <List
        style={{
          position: 'fixed',
          bottom: 0,
          textAlign: 'center',
        }}
      >
        <Container sx={{ my: 2, display: 'flex', justifyContent: 'flex-end' }}>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={12} md={4}>
              {/* <Typography variant="h6" marked="left" gutterBottom>
                Agenda tu Cita
              </Typography> */}
              <Box component="ul" sx={{ m: 0, listStyle: 'none', p: 0 }}>
                <Box component="li" sx={{ py: 0.5 }}>
                  <Link color="inherit" href="https://wa.me/6694310008">
                    <Stack direction="row" spacing={1}>
                      <Avatar
                        sx={{ width: 30, height: 30, bgcolor: green.A700 }}
                        variant="rounded"
                      >
                        <WhatsAppIcon />
                      </Avatar>
                      <Typography variant="subtitle1" marked="left">
                        (669) 431-00-08
                      </Typography>
                    </Stack>
                  </Link>
                </Box>
                <Box component="li" sx={{ py: 0.5 }}>
                  <Link color="inherit" href="tel:6691771462">
                    <Stack direction="row" spacing={1}>
                      <Avatar
                        sx={{ width: 30, height: 30, bgcolor: blueGrey[700] }}
                        variant="rounded"
                      >
                        <LocalPhoneIcon />
                      </Avatar>
                      <Typography variant="subtitle1" marked="left">
                        (669) 177-14-62
                      </Typography>
                    </Stack>
                  </Link>
                </Box>
                <Box component="li" sx={{ py: 0.5 }}>
                  <Link
                    color="inherit"
                    href="https://www.facebook.com/dr.diego.endo/"
                  >
                    <Stack direction="row" spacing={1}>
                      <Avatar
                        sx={{ width: 30, height: 30, bgcolor: blue[800] }}
                        variant="rounded"
                      >
                        <FacebookIcon />
                      </Avatar>
                      <Typography variant="subtitle1" marked="left">
                        @dr.diego.endo
                      </Typography>
                    </Stack>
                  </Link>
                </Box>
                <Box component="li" sx={{ py: 0.5 }}>
                  <Stack direction="row" spacing={1}>
                    <Avatar
                      sx={{ width: 30, height: 30, bgcolor: red[500] }}
                      variant="rounded"
                    >
                      <PlaceIcon />
                    </Avatar>
                    <Typography variant="body2" marked="left" align="left">
                      Av. Reforma #2007 Int. 509 Plaza Laguna, 5to piso.
                    </Typography>
                  </Stack>
                </Box>
              </Box>
            </Grid>
          </Grid>
        </Container>
      </List>
    </Box>
  )

  const container =
    window !== undefined ? () => window().document.body : undefined

  return (
    <Box component="nav">
      {/* The implementation can be swapped with js to avoid SEO duplication of links. */}
      <Drawer
        container={container}
        variant="temporary"
        anchor="right"
        open={open}
        onClose={() => onOpenChange(!open)}
        ModalProps={{
          keepMounted: true, // Better open performance on mobile.
        }}
        sx={{
          display: { xs: 'block', sm: 'none' },
          '& .MuiDrawer-paper': {
            boxSizing: 'border-box',
            width: drawerWidth,
          },
        }}
      >
        {drawer}
      </Drawer>
    </Box>
  )
}

export default ResponsiveDrawer
