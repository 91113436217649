import { useState, useEffect } from 'react'
import { useSelector } from 'react-redux'
import moment from 'moment'

import Paper from '@mui/material/Paper'
import Button from '@mui/material/Button'
import Stack from '@mui/material/Stack'
import DialogTitle from '@mui/material/DialogTitle'
import DialogContent from '@mui/material/DialogContent'
import DialogActions from '@mui/material/DialogActions'
import Dialog from '@mui/material/Dialog'
import Typography from '@mui/material/Typography'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell, { tableCellClasses } from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import Chip from '@mui/material/Chip'
import ArrowForwardIosSharpIcon from '@mui/icons-material/ArrowForwardIosSharp'
import MuiAccordion from '@mui/material/Accordion'
import MuiAccordionSummary from '@mui/material/AccordionSummary'
import MuiAccordionDetails from '@mui/material/AccordionDetails'
import { styled } from '@mui/material/styles'

const Accordion = styled((props) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
  border: `1px solid ${theme.palette.divider}`,
  '&:not(:last-child)': {
    borderBottom: 0,
  },
  '&:before': {
    display: 'none',
  },
}))

const AccordionSummary = styled((props) => (
  <MuiAccordionSummary
    expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: '0.9rem' }} />}
    {...props}
  />
))(({ theme }) => ({
  backgroundColor:
    theme.palette.mode === 'dark'
      ? 'rgba(255, 255, 255, .05)'
      : 'rgba(0, 0, 0, .03)',
  flexDirection: 'row-reverse',
  '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
    transform: 'rotate(90deg)',
  },
  '& .MuiAccordionSummary-content': {
    marginLeft: theme.spacing(1),
  },
}))

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  padding: theme.spacing(2),
  borderTop: '1px solid rgba(0, 0, 0, .125)',
}))

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: '#00796b',
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}))

const QuickView = (props) => {
  const { children, open, setOpen, onConfirm, dentalOrgan, ...other } = props
  const { patient } = useSelector((state) => state.patients)

  const [appointmentsList, setAppointmentsList] = useState([])

  useEffect(() => {
    // Get appointments by dental organ
    const list = patient.appointments.filter((appointment) => {
      return appointment.dentalOrgan.find((d) => d === dentalOrgan)
    })
    setAppointmentsList(list)
  }, [patient, dentalOrgan])

  return (
    <Dialog
      maxWidth="sm"
      sx={{
        '& .MuiDialog-paper': {
          maxHeight: 600,
        },
      }}
      open={open}
      onClose={() => setOpen(false)}
      {...other}
    >
      <DialogTitle>
        <Stack direction="row" spacing={1} alignItems="center">
          <Typography variant="h5" sx={{ fontWeight: 'bold' }}>
            Vista Rápida
          </Typography>
          <Chip label={dentalOrgan} size="large" />
        </Stack>
      </DialogTitle>
      <DialogContent dividers>
        {appointmentsList.map((appointment, i) => (
          <Accordion key={i} defaultExpanded={true}>
            <AccordionSummary
              aria-controls="rootCanal-content"
              id="rootCanal-header"
            >
              <Typography>
                Registro - {moment(appointment.createdAt).format('DD MMM YYYY dddd hh:mm a')}
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <TableContainer
                component={Paper}
                sx={{ backgroundColor: '#f5f5f5' }}
              >
                <Table
                  sx={{ minWidth: 350 }}
                  size="small"
                  aria-label="root canal therapy"
                >
                  <TableHead>
                    <TableRow>
                      <StyledTableCell>Conducto</StyledTableCell>
                      <StyledTableCell align="right">MM</StyledTableCell>
                      <StyledTableCell align="center">
                        Referencia
                      </StyledTableCell>
                      <StyledTableCell align="right">1er inst</StyledTableCell>
                      <StyledTableCell align="center">
                        Lima maestra
                      </StyledTableCell>
                      <StyledTableCell align="right">
                        Cono principal
                      </StyledTableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {appointment.rootCanalTherapy.map((row, i) => (
                      <TableRow
                        key={i}
                        sx={{
                          '&:last-child td, &:last-child th': {
                            border: 0,
                          },
                        }}
                      >
                        <TableCell component="th" scope="row">
                          {row.conduct ? row.conduct : `-`}
                        </TableCell>
                        <TableCell align="right">
                          {row.mm ? row.mm : `-`}
                        </TableCell>
                        <TableCell align="center">
                          {row.reference ? row.reference : `-`}
                        </TableCell>
                        <TableCell align="right">
                          {row.firstInst ? row.firstInst : `-`}
                        </TableCell>
                        <TableCell align="center">
                          {row.masterLime ? row.masterLime : `-`}
                        </TableCell>
                        <TableCell align="right">
                          {row.principalCone ? row.principalCone : `-`}
                        </TableCell>
                      </TableRow>
                    ))}
                    {appointment.rootCanalTherapy.length <= 0 && (
                      <TableRow
                        sx={{
                          '&:last-child td, &:last-child th': {
                            border: 0,
                          },
                        }}
                      >
                        <TableCell component="th" scope="row">
                          -
                        </TableCell>
                        <TableCell align="right">-</TableCell>
                        <TableCell align="center">-</TableCell>
                        <TableCell align="right">-</TableCell>
                        <TableCell align="center">-</TableCell>
                        <TableCell align="right">-</TableCell>
                      </TableRow>
                    )}
                  </TableBody>
                </Table>
              </TableContainer>
            </AccordionDetails>
          </Accordion>
        ))}
      </DialogContent>
      <DialogActions sx={{ justifyContent: 'space-between' }}>
        <Button onClick={() => setOpen(false)}>Cerrar</Button>
        {/* <Button
          variant="contained"
          color="primary"
          onClick={() => {
            setOpen(false)
          }}
        >
          Ok
        </Button> */}
      </DialogActions>
    </Dialog>
  )
}

export default QuickView
