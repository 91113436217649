import HeroHeader from '../../components/public/common/HeroHeader'
import Location from '../../components/public/contact/Location'
import Socials from '../../components/public/contact/Socials'

const headerData = {
  title: `Contacto`,
  imgUrl: `/static/img/headers/contact.webp`,
}

function ContactPage() {
  return (
    <>
      <HeroHeader title={headerData.title} imgUrl={headerData.imgUrl} />
      <Socials />
      <Location />
    </>
  )
}

export default ContactPage
