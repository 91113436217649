import axios from 'axios'

const API_URL = '/api/v1/patients/'

// Create new patient
const createPatient = async (patientData, token) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  }

  const response = await axios.post(`${API_URL}`, patientData, config)

  return response.data
}

// Get user patients
const getPatients = async (token) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  }

  const response = await axios.get(`${API_URL}`, config)

  return response.data
}

// Get patient
const getPatient = async (patientId, token) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  }

  const response = await axios.get(`${API_URL}${patientId}`, config)

  return response.data
}

// Update patient
const updatePatient = async (patientData, token) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  }

  const { id, data } = patientData
  const response = await axios.put(`${API_URL}${id}`, data, config)

  return response.data
}

// Delete patient
const deletePatient = async (patientId, token) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  }

  const response = await axios.delete(`${API_URL}${patientId}`, config)

  return response.data
}

const patientService = {
  createPatient,
  getPatients,
  getPatient,
  updatePatient,
  deletePatient,
}

export default patientService
