import Grid from '@mui/material/Grid'
import Paper from '@mui/material/Paper'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import Avatar from '@mui/material/Avatar'
// import AccountBalanceWalletIcon from '@mui/icons-material/AccountBalanceWallet'
import TrendingDownIcon from '@mui/icons-material/TrendingDown';
import { styled } from '@mui/material/styles'
import { red } from '@mui/material/colors'

const CustomPaperCard = styled(Paper)(({ theme }) => ({
  height: 210,

  transition: 'box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;',
  // boxShadow: 'none',
  backgroundImage: 'none',
  // borderRadius: '8px',
  border: 'none rgba(224, 224, 224, 0.596)',
  // backgroundColor: 'rgb(0, 137, 123)',
  backgroundColor: red[500],
  color: 'rgb(255, 255, 255)',
  overflow: 'hidden',
  position: 'relative',
  '&::before': {
    content: '""',
    position: 'absolute',
    width: '180px',
    height: '180px',
    // background: 'rgb(0, 105, 92)',
    background: red[800],
    borderRadius: '50%',
    top: '-125px',
    right: '-15px',
    opacity: '0.5',
  },
  '&::after': {
    content: '""',
    position: 'absolute',
    width: '180px',
    height: '180px',
    // background: 'rgb(0, 105, 92)',
    background: red[800],
    borderRadius: '50%',
    top: '-85px',
    right: '-95px',
  },
}))

const ExpensesCard = ({ total }) => {
  return (
    <CustomPaperCard>
      <Box sx={{ p: 2 }}>
        <Grid container>
          <Grid item xs={12} sx={{ my: 2 }}>
            <Avatar sx={{ bgcolor: red[800] }} variant="rounded">
              <TrendingDownIcon />
            </Avatar>
          </Grid>
          <Grid item xs={12} sx={{ pt: 1 }}>
            <Typography component="p" variant="h4">
              ${Number(total).toLocaleString()}
            </Typography>
          </Grid>
          <Grid item xs={12} sx={{ pt: 0 }}>
            <Typography variant="h6" sx={{ color: red[200] }}>
              Gastos
            </Typography>
          </Grid>
        </Grid>
      </Box>
    </CustomPaperCard>
  )
}

export default ExpensesCard
