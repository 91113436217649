import Button from '@mui/material/Button'
import Stack from '@mui/material/Stack'
import DialogTitle from '@mui/material/DialogTitle'
import DialogContent from '@mui/material/DialogContent'
import DialogActions from '@mui/material/DialogActions'
import Dialog from '@mui/material/Dialog'
import Typography from '@mui/material/Typography'
import ErrorTwoToneIcon from '@mui/icons-material/ErrorTwoTone'
import { red } from '@mui/material/colors'

const ConfirmationDialog = (props) => {
  const { title, children, open, setOpen, onConfirm, ...other } = props

  return (
    <Dialog
      sx={{
        '& .MuiDialog-paper': {
          width: '80%',
          maxHeight: 435,
          border: 4,
          borderColor: red[500],
        },
      }}
      maxWidth="xs"
      open={open}
      onClose={() => setOpen(false)}
      {...other}
    >
      <DialogTitle>
        <Stack direction="row" spacing={1} alignItems="center">
          <ErrorTwoToneIcon sx={{ color: red[500] }} />
          <Typography variant="h5" sx={{ fontWeight: 'bold' }}>
            {title}
          </Typography>
        </Stack>
      </DialogTitle>
      <DialogContent dividers>{children}</DialogContent>
      <DialogActions sx={{ justifyContent: 'space-between' }}>
        <Button onClick={() => setOpen(false)}>No, cancelar</Button>
        <Button
          variant="contained"
          color="error"
          onClick={() => {
            setOpen(false)
            onConfirm()
          }}
        >
          Sí, estoy seguro
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default ConfirmationDialog
