import React from 'react'
import { useSelector } from 'react-redux'
import { useLocation, useNavigate } from 'react-router-dom'
import Box from '@mui/material/Box'
import List from '@mui/material/List'
import ListItem from '@mui/material/ListItem'
import ListItemText from '@mui/material/ListItemText'
import Stack from '@mui/material/Stack'
import Chip from '@mui/material/Chip'
import Divider from '@mui/material/Divider'
import Grid from '@mui/material/Grid'
import ListItemButton from '@mui/material/ListItemButton'
import Paper from '@mui/material/Paper'
import Typography from '@mui/material/Typography'
import moment from 'moment'
import NoItemsImg from '../../../common/NoItemsImg'

function PaymentsList() {
  const location = useLocation()
  const navigate = useNavigate()

  const { patient } = useSelector((state) => state.patients)

  const handleViewPayment = (id) => {
    navigate(`${location.pathname}/${id}`)
  }

  if (patient.payments.length <= 0)
    return (
      <Paper variant="outlined" sx={{ my: 2, p: 2, minHeight: 300 }}>
        <NoItemsImg />
      </Paper>
    )

  return (
    <>
      {patient.payments && (
        <Box sx={{ width: '100%', bgcolor: 'background.paper' }}>
          {patient.payments.length > 0 && (
            <Typography variant="h6" gutterBottom component="div">
              Pagos ({patient.payments ? patient.payments.length : 0})
            </Typography>
          )}
          <Paper variant="outlined" sx={{ my: 2 }}>
            <Box sx={{ width: '100%', bgcolor: 'background.paper' }}>
              <nav aria-label="payments list">
                <List sx={{ p: 0 }}>
                  {patient.payments
                    .slice(0)
                    .sort((t1, t2) =>
                      t1.createdAt < t2.createdAt
                        ? 1
                        : t1.createdAt > t2.createdAt
                        ? -1
                        : 0
                    )
                    .map((payment, i) => (
                      <div key={payment._id}>
                        <ListItem disablePadding>
                          <ListItemButton
                            onClick={() => handleViewPayment(payment._id)}
                          >
                            <ListItemText
                              primary={
                                <Grid
                                  container
                                  justifyContent="space-between"
                                  alignItems="center"
                                  spacing={0}
                                >
                                  <Grid item xs={12} sm={9}>
                                    <Stack
                                      direction="row"
                                      alignItems="center"
                                      spacing={1}
                                      sx={{ mb: 1 }}
                                    >
                                      <Typography>Cantidad:</Typography>
                                      <Chip
                                        label={`$${Number(
                                          payment.amount
                                        ).toLocaleString()}`}
                                        color="primary"
                                        variant="outlined"
                                        sx={{ fontWeight: 'bold' }}
                                      />
                                    </Stack>
                                  </Grid>
                                  <Grid item xs={12} sm={3}>
                                    <Stack
                                      direction={{ xs: 'row', sm: 'column' }}
                                      justifyContent={{
                                        xs: 'flex-start',
                                        sm: 'flex-end',
                                      }}
                                      alignItems="center"
                                      spacing={0}
                                      sx={{ mt: 1 }}
                                    >
                                      <Typography
                                        variant="caption"
                                        color="textSecondary"
                                      >
                                        {moment(payment.createdAt).format(
                                          'dddd hh:mm a'
                                        )}
                                      </Typography>
                                      <Typography
                                        variant="caption"
                                        color="textSecondary"
                                      >
                                        {moment(payment.createdAt).format(
                                          'DD MMM YYYY'
                                        )}
                                      </Typography>
                                    </Stack>
                                  </Grid>
                                </Grid>
                              }
                            />
                          </ListItemButton>
                        </ListItem>
                        {patient.payments.length - i !== 1 && <Divider />}
                      </div>
                    ))}
                </List>
              </nav>
            </Box>
          </Paper>
          {/* <List>
            {patient.payments
              .slice(0)
              .sort((t1, t2) =>
                t1.createdAt < t2.createdAt
                  ? 1
                  : t1.createdAt > t2.createdAt
                  ? -1
                  : 0
              )
              .map((appointment) => (
                <Paper
                  key={appointment._id}
                  variant="outlined"
                  sx={{ py: 1, mb: 2 }}
                >
                  <ListItem
                    secondaryAction={
                      <IconButton
                        edge="end"
                        aria-label="ver"
                        onClick={() => handleViewAppointment(appointment._id)}
                      >
                        <FindInPageIcon color="primary" />
                      </IconButton>
                    }
                  >
                    <ListItemText
                      primary={`Monto: $${Number(
                        appointment.amount
                      ).toLocaleString()}`}
                      secondary={moment(appointment.createdAt).calendar()}
                    />
                  </ListItem>
                </Paper>
              ))}
          </List> */}
        </Box>
      )}
    </>
  )
}

export default PaymentsList
