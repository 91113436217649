import { useState, useEffect } from 'react'
import { useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'

export const useAuthStatus = () => {
  const [loggedIn, setLoggedIn] = useState(false)
  const [checkingStatus, setCheckingStatus] = useState(true)

  const { user } = useSelector((state) => state.auth)
  const { message } = useSelector((state) => state.patients)

  const navigate = useNavigate()

  useEffect(() => {
    if (user) {
      setLoggedIn(true)
    } else {
      setLoggedIn(false)
    }

    if (message === 'Not authorized') {
      localStorage.removeItem('user')
      navigate('/login')
    }

    setCheckingStatus(false)
  }, [user, message, navigate])

  return { loggedIn, checkingStatus }
}
