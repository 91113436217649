import { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'

import Grid from '@mui/material/Grid'
import Paper from '@mui/material/Paper'
import Stack from '@mui/material/Stack'
import Typography from '@mui/material/Typography'
import Chip from '@mui/material/Chip'
import Title from '../../../common/Title'
import GeneralOdontogram from './GeneralOdontogram'
import QuickView from '../../../common/QuickView'
import LastRegisters from './LastRegisters'
import PersonalInfo from './PersonalInfo'
import { updateDentalOrgan } from '../../../../../features/appointments/AppointmentsSlice'

function Overview() {
  const dispatch = useDispatch()
  const { patient } = useSelector((state) => state.patients)

  const [treatedDentalOrgans, setTreatedDentalOrgans] = useState([])
  const [balanceDue, setBalanceDue] = useState(0)
  const [totalAppointments, setTotalAppointments] = useState(0)
  const [totalPayments, setTotalPayments] = useState(0)
  const [quickView, setQuickView] = useState(null)
  const [confirmOpen, setConfirmOpen] = useState(false)
  const [lastRegisters, setLastRegisters] = useState([])

  useEffect(() => {
    // Get treated dental organs
    let mergeDentalOrgans = []
    patient.appointments.map((a, i) => mergeDentalOrgans.push(...a.dentalOrgan))
    let uniqueDentalOrgans = [...new Set(mergeDentalOrgans)]

    setTreatedDentalOrgans(uniqueDentalOrgans)

    // Calculate the patient balance due
    let totalCost = 0
    let totalPay = 0

    patient.appointments.map((a, i) => (totalCost += a.total))
    patient.payments.map((p, i) => (totalPay += p.amount))

    setTotalAppointments(totalCost)
    setTotalPayments(totalPay)
    setBalanceDue(totalCost - totalPay)

    // Set the last registers
    const lastRegisters = patient.appointments
      .slice(-5)
      .sort((t1, t2) =>
        t1.updatedAt < t2.updatedAt ? 1 : t1.updatedAt > t2.updatedAt ? -1 : 0
      )
    setLastRegisters(lastRegisters)

    // Clear dental organ
    dispatch(updateDentalOrgan([]))
  }, [patient, dispatch])

  const handleOpenQuickView = (d) => {
    setQuickView(d)
    setConfirmOpen(true)
  }

  if (patient.appointments.length <= 0)
    return (
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <PersonalInfo />
        </Grid>
        <Grid item xs={12}>
          <Paper
            variant="outlined"
            sx={{ p: 2, display: 'flex', flexDirection: 'column' }}
          >
            <Grid
              container
              spacing={2}
              justifyContent="flex-start"
              alignItems="center"
            >
              <Grid item xs={12}>
                <Stack direction="column" spacing={1}>
                  <Title>Odontograma</Title>
                  <GeneralOdontogram
                    treatedDentalOrgans={treatedDentalOrgans}
                  />
                </Stack>
              </Grid>
            </Grid>
          </Paper>
        </Grid>
      </Grid>
    )

  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <PersonalInfo />
        </Grid>
        <Grid item xs={12}>
          <Paper
            variant="outlined"
            sx={{ p: 2, display: 'flex', flexDirection: 'column' }}
          >
            <Grid
              container
              spacing={2}
              justifyContent="flex-start"
              alignItems="center"
            >
              <Grid item xs={12}>
                <Stack direction="column" spacing={1}>
                  <Title>Odontograma</Title>
                  <GeneralOdontogram
                    treatedDentalOrgans={treatedDentalOrgans}
                  />
                </Stack>
              </Grid>
            </Grid>
          </Paper>
        </Grid>
        <Grid item xs={12}>
          <Paper
            variant="outlined"
            sx={{ p: 2, display: 'flex', flexDirection: 'column' }}
          >
            <Grid
              container
              spacing={2}
              justifyContent="flex-start"
              alignItems="center"
            >
              <Grid item xs={12}>
                <Stack direction="column" spacing={1}>
                  <Title>Dientes tratados</Title>
                  <Stack direction="row" spacing={1} sx={{ py: 1 }}>
                    {treatedDentalOrgans
                      // .sort((a, b) => a - b)
                      .map((dentalOrgan, i) => (
                        <Chip
                          key={i}
                          label={dentalOrgan}
                          color="primary"
                          onClick={() => handleOpenQuickView(dentalOrgan)}
                        />
                      ))}
                  </Stack>
                </Stack>
              </Grid>
            </Grid>
          </Paper>
          <QuickView
            keepMounted
            open={confirmOpen}
            setOpen={setConfirmOpen}
            dentalOrgan={quickView}
          />
        </Grid>
        <Grid item xs={12} sm={4}>
          <Paper
            variant="outlined"
            sx={{ p: 2, display: 'flex', flexDirection: 'column' }}
          >
            <Grid
              container
              spacing={2}
              justifyContent="flex-start"
              alignItems="center"
            >
              <Grid item xs={12}>
                <Stack direction="column" spacing={1}>
                  <Typography
                    component="h2"
                    variant="h6"
                    color="primary"
                    gutterBottom
                  >
                    Total
                  </Typography>
                  <Typography variant="h6" sx={{ py: 1 }}>
                    {`$${totalAppointments.toLocaleString()}`}
                  </Typography>
                </Stack>
              </Grid>
            </Grid>
          </Paper>
        </Grid>
        <Grid item xs={12} sm={4}>
          <Paper
            variant="outlined"
            sx={{ p: 2, display: 'flex', flexDirection: 'column' }}
          >
            <Grid
              container
              spacing={2}
              justifyContent="flex-start"
              alignItems="center"
            >
              <Grid item xs={12}>
                <Stack direction="column" spacing={1}>
                  <Typography
                    component="h2"
                    variant="h6"
                    color="primary"
                    gutterBottom
                  >
                    Pagos
                  </Typography>
                  <Typography variant="h6" sx={{ py: 1 }}>
                    {`$${totalPayments.toLocaleString()}`}
                  </Typography>
                </Stack>
              </Grid>
            </Grid>
          </Paper>
        </Grid>
        <Grid item xs={12} sm={4}>
          <Paper
            variant="outlined"
            sx={{ p: 2, display: 'flex', flexDirection: 'column' }}
          >
            <Grid
              container
              spacing={2}
              justifyContent="flex-start"
              alignItems="center"
            >
              <Grid item xs={12}>
                <Stack direction="column" spacing={1}>
                  <Typography
                    component="h2"
                    variant="h6"
                    color="primary"
                    gutterBottom
                  >
                    Balance
                  </Typography>
                  <Typography variant="h6" sx={{ py: 1 }}>
                    {`$${balanceDue.toLocaleString()}`}
                  </Typography>
                </Stack>
              </Grid>
            </Grid>
          </Paper>
        </Grid>
        <Grid item xs={12}>
          <LastRegisters lastRegisters={lastRegisters} />
        </Grid>
      </Grid>
    </>
  )
}

export default Overview
