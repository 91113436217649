import Box from '@mui/material/Box'
import Divider from '@mui/material/Divider'
import Drawer from '@mui/material/Drawer'
import List from '@mui/material/List'
import ListSubheader from '@mui/material/ListSubheader'
import Toolbar from '@mui/material/Toolbar'
import Avatar from '@mui/material/Avatar'
import { MainListItems, SecondaryListItems } from './listItems'

function ResponsiveDrawer({ drawerWidth, open, onOpenChange }) {
  const drawer = (
    <Box onClick={() => onOpenChange(!open)}>
      <Toolbar sx={{ justifyContent: 'center' }}>
        <Avatar alt="Logo" src="/logo.jpg" sx={{ width: 50, height: 50 }} />
      </Toolbar>
      <Divider />
      <List>
        <MainListItems />
      </List>
      <List
        subheader={
          <ListSubheader
            component="div"
            id="reports-header"
            sx={{ lineHeight: '28px' }}
          >
            Reportes
          </ListSubheader>
        }
      >
        <SecondaryListItems />
      </List>
    </Box>
  )

  const container = document.body

  return (
    <Box
      component="nav"
      sx={{ width: { md: drawerWidth }, flexShrink: { md: 0 } }}
      aria-label="menu"
    >
      {/* The implementation can be swapped with js to avoid SEO duplication of links. */}
      <Drawer
        container={container}
        variant="temporary"
        open={open}
        onClose={() => onOpenChange(!open)}
        ModalProps={{
          keepMounted: true, // Better open performance on mobile.
        }}
        sx={{
          display: { xs: 'block', md: 'none' },
          '& .MuiDrawer-paper': {
            boxSizing: 'border-box',
            width: drawerWidth,
          },
        }}
      >
        {drawer}
      </Drawer>
      <Drawer
        variant="permanent"
        sx={{
          display: { xs: 'none', md: 'block' },
          '& .MuiDrawer-paper': {
            boxSizing: 'border-box',
            width: drawerWidth,
          },
        }}
        open
      >
        {drawer}
      </Drawer>
    </Box>
  )
}

export default ResponsiveDrawer
