import { Routes, Route, Navigate } from 'react-router-dom'

import Appointments from '../../components/admin/patients/tabs/appointments/Appointments'
import AddAppointment from '../../components/admin/patients/tabs/appointments/AddAppointment'
import AppointmentView from '../../components/admin/patients/tabs/appointments/AppointmentView'
import AppointmentEdit from '../../components/admin/patients/tabs/appointments/AppointmentEdit'

const AppointmentsPage = () => {
  return (
    <Routes>
      <Route index element={<Appointments />} />
      <Route path="new" element={<AddAppointment />} />
      <Route path=":appointmentId" element={<AppointmentView />} />
      <Route path=":appointmentId/edit" element={<AppointmentEdit />} />
      <Route path="*" element={<Navigate to="/" replace />} />
    </Routes>
  )
}

export default AppointmentsPage
