import { useState } from 'react'
import { useSelector } from 'react-redux'
import Button from '@mui/material/Button'
import AddIcon from '@mui/icons-material/Add'
import AddMedicalHistory from './AddMedicalHistory'
import MedicalHistoryView from './MedicalHistoryView'

function MedicalHistory() {
  const { patient } = useSelector((state) => state.patients)
  const [showForm, setShowForm] = useState(false)

  return (
    <>
      {patient.medicalHistory ? (
        <MedicalHistoryView />
      ) : (
        <Button
          variant="contained"
          color="primary"
          size="small"
          disabled={showForm}
          sx={{
            mt: 1,
            mb: 3,
          }}
          startIcon={<AddIcon />}
          onClick={() => setShowForm(true)}
        >
          Agregar Historia Clínica
        </Button>
      )}
      {showForm && (
        <AddMedicalHistory
          onCancel={() => {
            setShowForm(false)
          }}
        />
      )}
    </>
  )
}

export default MedicalHistory
