import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import paymentsService from './paymentsService'

const initialState = {
  payments: [],
  payment: null,
  isError: false,
  isSuccess: false,
  isLoading: false,
  message: '',
}

// Create new payment
export const createPayment = createAsyncThunk(
  'payments/create',
  async (data, thunkAPI) => {
    try {
      const token = thunkAPI.getState().auth.user.token
      return await paymentsService.createPayment(data, token)
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString()

      return thunkAPI.rejectWithValue(message)
    }
  }
)

// Get all user payments
export const getAllPayments = createAsyncThunk(
  'payments/getAll',
  async (_, thunkAPI) => {
    try {
      const token = thunkAPI.getState().auth.user.token
      return await paymentsService.getAllPayments(token)
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString()

      return thunkAPI.rejectWithValue(message)
    }
  }
)

// Get payment
export const getPayment = createAsyncThunk(
  'payments/get',
  async (paymentId, thunkAPI) => {
    try {
      const token = thunkAPI.getState().auth.user.token
      return await paymentsService.getPayment(paymentId, token)
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString()

      return thunkAPI.rejectWithValue(message)
    }
  }
)

// Update payment
export const updatePayment = createAsyncThunk(
  'payments/update',
  async (data, thunkAPI) => {
    try {
      const token = thunkAPI.getState().auth.user.token
      return await paymentsService.updatePayment(data, token)
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString()

      return thunkAPI.rejectWithValue(message)
    }
  }
)

// Delete payment
export const deletePayment = createAsyncThunk(
  'payments/delete',
  async (paymentId, thunkAPI) => {
    try {
      const token = thunkAPI.getState().auth.user.token
      return await paymentsService.deletePayment(paymentId, token)
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString()

      return thunkAPI.rejectWithValue(message)
    }
  }
)

const paymentsSlice = createSlice({
  name: 'payments',
  initialState,
  reducers: {
    reset: (state) => initialState,
    resetError: (state) => {
      state.isError = false
      state.message = ''
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(createPayment.pending, (state) => {
        state.isLoading = true
      })
      .addCase(createPayment.fulfilled, (state, action) => {
        state.isLoading = false
        state.isSuccess = true
      })
      .addCase(createPayment.rejected, (state, action) => {
        state.isLoading = false
        state.isError = true
        state.message = action.payload
      })
      .addCase(getAllPayments.pending, (state) => {
        state.isLoading = true
      })
      .addCase(getAllPayments.fulfilled, (state, action) => {
        state.isLoading = false
        state.payments = action.payload
      })
      .addCase(getAllPayments.rejected, (state, action) => {
        state.isLoading = false
        state.isError = true
        state.message = action.payload
      })
      .addCase(getPayment.pending, (state) => {
        state.isLoading = true
      })
      .addCase(getPayment.fulfilled, (state, action) => {
        state.isLoading = false
        // state.isSuccess = true
        state.payment = action.payload
      })
      .addCase(getPayment.rejected, (state, action) => {
        state.isLoading = false
        state.isError = true
        state.message = action.payload
      })
      .addCase(updatePayment.pending, (state) => {
        state.isLoading = true
      })
      .addCase(updatePayment.fulfilled, (state, action) => {
        state.isLoading = false
        state.isSuccess = true
      })
      .addCase(updatePayment.rejected, (state, action) => {
        state.isLoading = false
        state.isError = true
        state.message = action.payload
      })
      .addCase(deletePayment.pending, (state) => {
        state.isLoading = true
      })
      .addCase(deletePayment.fulfilled, (state, action) => {
        state.isSuccess = true
        state.isLoading = false
        state.message = action.payload
      })
      .addCase(deletePayment.rejected, (state, action) => {
        state.isError = true
        state.isLoading = false
        state.message = action.payload
      })
  },
})

export const { reset, resetError } = paymentsSlice.actions
export default paymentsSlice.reducer
