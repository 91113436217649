import * as yup from 'yup'

const patientSchema = yup.object().shape({
  name: yup.string().required('Nombre es obligatorio'),
  age: yup
    .number()
    .typeError('Por favor introduce una edad válida')
    .positive()
    .integer()
    .required('Edad es obligatorio'),
  gender: yup.string().required('Sexo es obligatorio'),
  maritalStatus: yup.string().required('Estado Civil es obligatorio'),
  occupation: yup.string().required('Ocupación es obligatorio'),
  address: yup.string().required('Dirección es obligatorio'),
  phone: yup.string().required('Teléfono es obligatorio'),
})

export default patientSchema
