import * as yup from 'yup'

const paymentSchema = yup.object().shape({
  amount: yup
    .number()
    .typeError('Por favor introduce una cantidad válida')
    .positive('Por favor introduce un monto mayor a cero')
    .required('Por favor introduce un monto mayor a cero'),
})

export default paymentSchema
