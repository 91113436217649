import { useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useParams, useNavigate, Link as RouterLink } from 'react-router-dom'
import { toast } from 'react-toastify'
import moment from 'moment'

import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography'
import IconButton from '@mui/material/IconButton'
import Paper from '@mui/material/Paper'
import Stack from '@mui/material/Stack'
import HomeIcon from '@mui/icons-material/Home'
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight'
import Link from '@mui/material/Link'
import LinearProgress from '@mui/material/LinearProgress'
import Divider from '@mui/material/Divider'
import EventTwoToneIcon from '@mui/icons-material/EventTwoTone'
import LocalAtmTwoToneIcon from '@mui/icons-material/LocalAtmTwoTone'
import EditIcon from '@mui/icons-material/Edit'
import Button from '@mui/material/Button'
import ArrowBackTwoToneIcon from '@mui/icons-material/ArrowBackTwoTone'
import DeleteIcon from '@mui/icons-material/Delete'
import MoreVertIcon from '@mui/icons-material/MoreVert'
import MenuItem from '@mui/material/MenuItem'
import Menu from '@mui/material/Menu'
import ListItemIcon from '@mui/material/ListItemIcon'

import {
  getExpense,
  deleteExpense,
  reset,
  resetError,
} from '../../../features/expenses/ExpenseSlice'
import ConfirmationDialog from '../common/ConfirmationDialog'

const ExpenseView = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const { id } = useParams()

  const { expense, isError, isSuccess, message } = useSelector(
    (state) => state.expenses
  )

  const [activeExpense, setActiveExpense] = useState(null)
  const [anchorElPayment, setAnchorElPayment] = useState(null)
  const [confirmOpen, setConfirmOpen] = useState(false)

  useEffect(() => {
    dispatch(getExpense(id))
  }, [dispatch, id])

  useEffect(() => {
    if (expense) {
      setActiveExpense(expense)
    }
  }, [expense])

  useEffect(() => {
    if (isError) {
      toast.error(message)
      dispatch(resetError())
    }

    if (isSuccess) {
      toast.error('Gasto eliminado!')
      dispatch(reset())
      navigate(`/dashboard/expenses`)
    }
  }, [isError, isSuccess, message, dispatch, navigate, id])

  const handleOpenExpenseMenu = (event) => {
    setAnchorElPayment(event.currentTarget)
  }

  const handleCloseExpenseMenu = () => {
    setAnchorElPayment(null)
  }

  const handleDelete = () => {
    setAnchorElPayment(null)
    setConfirmOpen(true)
  }

  return (
    <>
      {activeExpense ? (
        <div>
          <Paper sx={{ p: 2, mb: 2 }}>
            <Stack direction="row" justifyContent="space-between" spacing={1}>
              <Grid
                container
                direction="row"
                alignItems="center"
                justifyContent="space-between"
              >
                <Grid item xs={12} sm={8}>
                  <Stack direction="column" justifyContent="space-between">
                    <Typography sx={{ fontWeight: 'bold' }} variant="h5">
                      Detalles Gasto
                    </Typography>
                    <Typography color="textSecondary" variant="caption">
                      #{activeExpense._id}
                    </Typography>
                  </Stack>
                </Grid>
                <Grid item xs="auto">
                  <Stack direction="row" alignItems="center" spacing={1}>
                    <IconButton
                      aria-label="home"
                      size="small"
                      color="primary"
                      onClick={() => navigate(`/dashboard`)}
                    >
                      <HomeIcon fontSize="small" />
                    </IconButton>
                    <KeyboardArrowRightIcon fontSize="small" />
                    <Link
                      component={RouterLink}
                      to="/dashboard/expenses"
                      color="inherit"
                      variant="subtitle2"
                      underline="none"
                    >
                      Gastos
                    </Link>
                  </Stack>
                </Grid>
              </Grid>
            </Stack>
          </Paper>
          <Paper sx={{ p: 2 }}>
            <Grid container spacing={0}>
              <Grid item xs={12}>
                <Stack
                  direction="row"
                  justifyContent="space-between"
                  alignItems="center"
                >
                  <Button
                    variant="contained"
                    color="primary"
                    size="small"
                    disabled={false}
                    sx={{
                      mt: 1,
                      mb: 3,
                    }}
                    startIcon={<ArrowBackTwoToneIcon />}
                    onClick={() => navigate(`/dashboard/expenses`)}
                  >
                    Volver al listado
                  </Button>
                  <Stack direction="row" alignItems="center" spacing={1}>
                    <Button
                      variant="contained"
                      color="secondary"
                      size="small"
                      disabled={false}
                      startIcon={<EditIcon />}
                      onClick={() => navigate(`/dashboard/expenses/${id}/edit`)}
                    >
                      Editar
                    </Button>
                    <IconButton onClick={handleOpenExpenseMenu}>
                      <MoreVertIcon />
                    </IconButton>
                    <Menu
                      sx={{ mt: '45px' }}
                      id="menu-expense"
                      anchorEl={anchorElPayment}
                      anchorOrigin={{
                        vertical: 'top',
                        horizontal: 'right',
                      }}
                      keepMounted
                      transformOrigin={{
                        vertical: 'top',
                        horizontal: 'right',
                      }}
                      open={Boolean(anchorElPayment)}
                      onClose={handleCloseExpenseMenu}
                    >
                      <MenuItem onClick={handleDelete}>
                        <ListItemIcon>
                          <DeleteIcon fontSize="small" />
                        </ListItemIcon>
                        Eliminar
                      </MenuItem>
                    </Menu>
                  </Stack>
                </Stack>
              </Grid>
              <Grid item xs={12}>
                <Paper variant="outlined">
                  <Stack
                    direction="row"
                    justifyContent="space-between"
                    alignItems="center"
                    sx={{ p: 2 }}
                  >
                    <Typography variant="subtitle2">Datos del gasto</Typography>
                  </Stack>
                  <Divider />
                  <Grid
                    container
                    justifyContent="space-evenly"
                    alignItems="center"
                    spacing={2}
                    sx={{ p: 2 }}
                  >
                    <Grid item xs={6} md={3}>
                      <Stack
                        direction="row"
                        spacing={1}
                        sx={{ alignItems: 'center' }}
                      >
                        <EventTwoToneIcon />
                        <Typography variant="subtitle2">Fecha</Typography>
                      </Stack>
                    </Grid>
                    <Grid item xs={6} md={3}>
                      <Typography variant="body2">
                        {moment(activeExpense.createdAt).format('DD/MMM/YYYY')}
                      </Typography>
                    </Grid>

                    <Grid item xs={6} md={3}>
                      <Stack
                        direction="row"
                        spacing={1}
                        sx={{ alignItems: 'center' }}
                      >
                        <LocalAtmTwoToneIcon />
                        <Typography variant="subtitle2">Cantidad</Typography>
                      </Stack>
                    </Grid>
                    <Grid item xs={6} md={3}>
                      <Typography variant="body2">
                        {`$${Number(activeExpense.amount).toLocaleString()}`}
                      </Typography>
                    </Grid>
                    {activeExpense.notes && (
                      <Grid
                        container
                        justifyContent="space-evenly"
                        alignItems="center"
                        spacing={2}
                        sx={{ p: 2 }}
                      >
                        <Grid item xs={12}>
                          <Typography variant="subtitle2">Notas</Typography>
                        </Grid>
                        <Grid item xs={12}>
                          <Typography variant="body2">
                            {activeExpense.notes}
                          </Typography>
                        </Grid>
                      </Grid>
                    )}
                  </Grid>
                </Paper>
                <ConfirmationDialog
                  keepMounted
                  title="Eliminar Gasto"
                  children={
                    <>
                      <Typography variant="body1">
                        ¿Está seguro que desea eliminar el gasto?
                      </Typography>
                      <Typography variant="caption">
                        *Esta acción es irreversible.
                      </Typography>
                    </>
                  }
                  open={confirmOpen}
                  setOpen={setConfirmOpen}
                  onConfirm={() => {
                    dispatch(deleteExpense(id))
                  }}
                />
              </Grid>
            </Grid>
          </Paper>
        </div>
      ) : (
        <Stack direction="column" spacing={2}>
          <Paper sx={{ minHeight: 84 }}>
            <LinearProgress />
          </Paper>
          <Paper sx={{ minHeight: 500 }}>
            <LinearProgress />
          </Paper>
        </Stack>
      )}
    </>
  )
}

export default ExpenseView
