import React from 'react'
import { Routes, Route, Navigate } from 'react-router-dom'

import PaymentsReport from '../../../components/admin/reports/payments/PaymentsReport'

const PaymentsReportPage = () => {
  return (
    <>
      <Routes>
        <Route index element={<PaymentsReport />} />
        <Route path="*" element={<Navigate to="/" replace />} />
      </Routes>
    </>
  )
}

export default PaymentsReportPage
