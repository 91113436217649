import { useState } from 'react'
import moment from 'moment'

import Box from '@mui/material/Box'
import Paper from '@mui/material/Paper'
import Stack from '@mui/material/Stack'
import Typography from '@mui/material/Typography'
import Chip from '@mui/material/Chip'
import Table from '@mui/material/Table'
import TableContainer from '@mui/material/TableContainer'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import Title from '../../../common/Title'
import Collapse from '@mui/material/Collapse'
import IconButton from '@mui/material/IconButton'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp'

function Row({ row }) {
  const [open, setOpen] = useState(false)

  return (
    <>
      <TableRow>
        <TableCell sx={{ borderBottom: 'unset' }}>
          <IconButton
            aria-label="expand row"
            size="small"
            onClick={() => setOpen(!open)}
          >
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
        <TableCell component="th" scope="row" sx={{ borderBottom: 'unset' }}>
          {`${moment(row.updatedAt).format('DD/MMM/YYYY')} a las ${moment(
            row.updatedAt
          ).format('hh:mm a')}`}
        </TableCell>
        <TableCell sx={{ borderBottom: 'unset' }}>
          <Stack direction="row" alignItems="center" spacing={1}>
            {row.dentalOrgan.map((t) => (
              <Chip key={t} label={t} size="small" />
            ))}
          </Stack>
        </TableCell>
        <TableCell align="right" sx={{ borderBottom: 'unset' }}>{`$${Number(
          row.total
        ).toLocaleString()}`}</TableCell>
      </TableRow>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box sx={{ margin: 1 }}>
              <Typography variant="h6" gutterBottom component="div">
                Historial
              </Typography>
              <Table size="small" aria-label="purchases">
                <TableHead>
                  <TableRow>
                    <TableCell />
                  </TableRow>
                </TableHead>
                <TableBody>
                  {row.comments.map((comment, i) => (
                    <TableRow key={i}>
                      <TableCell>
                        {`Cita ${i + 1}: ${comment ? comment : '-'}`}
                      </TableCell>
                    </TableRow>
                  ))}
                  {row.comments.length <= 0 && (
                    <TableRow>
                      <TableCell>{`Cita 1: -`}</TableCell>
                    </TableRow>
                  )}
                </TableBody>
              </Table>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </>
  )
}

function LastRegisters({ lastRegisters }) {
  return (
    <>
      <Paper
        variant="outlined"
        sx={{ p: 2, display: 'flex', flexDirection: 'column' }}
      >
        <Title>Últimos Registros</Title>
        <TableContainer component={Box}>
          <Table aria-label="collapsible table">
            <TableHead>
              <TableRow>
                <TableCell />
                <TableCell>Fecha</TableCell>
                <TableCell>Órgano Dental</TableCell>
                <TableCell align="right">Costo Total</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {lastRegisters.map((row) => (
                <Row key={row._id} row={row} />
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Paper>
    </>
  )
}

export default LastRegisters
