import CarouselHome from '../../components/public/home/CarouselHome'
import About from '../../components/public/home/About'
// import Gallery from '../../components/public/home/Gallery'



function HomePage() {
  return (
    <>
      <CarouselHome />
      <About />
      {/* <Gallery /> */}
    </>
  )
}

export default HomePage
