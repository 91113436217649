import { useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import moment from 'moment'

import Paper from '@mui/material/Paper'
import Button from '@mui/material/Button'
import AddIcon from '@mui/icons-material/Add'
import Stack from '@mui/material/Stack'
import Typography from '@mui/material/Typography'
import InputLabel from '@mui/material/InputLabel'
import MenuItem from '@mui/material/MenuItem'
import FormControl from '@mui/material/FormControl'
import Select from '@mui/material/Select'
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { MobileDatePicker } from '@mui/x-date-pickers/MobileDatePicker'

import Input from '../common/Input'
import ExpensesList from './ExpensesList'

const Expenses = () => {
  const navigate = useNavigate()

  const [filter, setFilter] = useState('today')
  const [dateRange, setDateRange] = useState([])
  const [dateRangeTitle, setDateRangeTitle] = useState('')

  useEffect(() => {
    if (filter !== 'today' && filter !== 'yesterday') {
      setDateRangeTitle(
        `${moment(dateRange[0]).format('DD MMMM, YYYY')} ~ ${moment(
          dateRange[1]
        ).format('DD MMMM, YYYY')}`
      )
    } else {
      setDateRangeTitle(`${moment(dateRange[0]).format('DD MMMM, YYYY')}`)
    }
  }, [dateRange, filter])

  useEffect(() => {
    switch (filter) {
      case 'yesterday':
        setDateRange([
          moment().subtract(1, 'days').format('YYYY-MM-DD'),
          moment().subtract(1, 'days').format('YYYY-MM-DD'),
        ])
        break

      case 'lastWeek':
        setDateRange([
          moment().subtract(1, 'weeks').format('YYYY-MM-DD'),
          moment().format('YYYY-MM-DD'),
        ])
        break

      case 'lastMonth':
        setDateRange([
          moment().subtract(1, 'months').format('YYYY-MM-DD'),
          moment().format('YYYY-MM-DD'),
        ])
        break

      default:
        setDateRange([
          moment().format('YYYY-MM-DD'),
          moment().format('YYYY-MM-DD'),
        ])
        break
    }
  }, [filter])

  const handleChange = (event) => {
    setFilter(event.target.value)
  }

  const handleChangeStartDate = (newDate) => {
    const newDateRanges = dateRange.map((d, i) => {
      if (i === 0) {
        return newDate.format('YYYY-MM-DD')
      } else {
        return d
      }
    })
    setDateRange(newDateRanges)
  }

  const handleChangeEndDate = (newDate) => {
    const newDateRanges = dateRange.map((d, i) => {
      if (i === 1) {
        return moment(newDate).format('YYYY-MM-DD')
      } else {
        return d
      }
    })
    setDateRange(newDateRanges)
  }

  const handleNewExpense = () => {
    navigate(`/dashboard/expenses/new`)
  }

  return (
    <>
      <Paper sx={{ p: 2, mb: 2 }}>
        <Stack direction="row" justifyContent="space-between" spacing={1}>
          <Typography sx={{ fontWeight: 'bold' }} variant="h5">
            Gastos
          </Typography>
          <Button
            variant="contained"
            color="primary"
            size="small"
            sx={{
              mt: 1,
              mb: 3,
            }}
            startIcon={<AddIcon />}
            onClick={() => handleNewExpense()}
          >
            Nuevo
          </Button>
        </Stack>
      </Paper>
      <Paper sx={{ p: 2 }}>
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          spacing={1}
          sx={{ pb: 2 }}
        >
          <Typography sx={{ fontWeight: 'bold' }} variant="h6">
            {dateRangeTitle}
          </Typography>
          <FormControl sx={{ m: 1, minWidth: 200 }} size="small">
            <InputLabel id="select-filter-label">Filtrar</InputLabel>
            <Select
              labelId="select-filter-label"
              id="filter-select"
              value={filter}
              label="Filtrar"
              onChange={handleChange}
            >
              <MenuItem value="today">Hoy</MenuItem>
              <MenuItem value="yesterday">Ayer</MenuItem>
              <MenuItem value="lastWeek">Última Semana</MenuItem>
              <MenuItem value="lastMonth">Último Mes</MenuItem>
              <MenuItem value="custom">Personalizado</MenuItem>
            </Select>
          </FormControl>
        </Stack>
        {filter === 'custom' && (
          <LocalizationProvider dateAdapter={AdapterMoment}>
            <Stack direction="row" spacing={1} sx={{ mb: 2 }}>
              <MobileDatePicker
                label="Fecha Inicial"
                minDate={moment('2022-01-01')}
                showToolbar={false}
                disableFuture
                disableOpenPicker
                value={dateRange[0]}
                onChange={(newValue) => {
                  handleChangeStartDate(newValue)
                }}
                renderInput={(params) => (
                  <Input size="small" fullWidth {...params} />
                )}
              />
              <MobileDatePicker
                label="Fecha Final"
                value={dateRange[1]}
                onChange={(newValue) => {
                  handleChangeEndDate(newValue)
                }}
                renderInput={(params) => (
                  <Input size="small" fullWidth {...params} />
                )}
              />
            </Stack>
          </LocalizationProvider>
        )}
        <ExpensesList dateRange={dateRange} />
      </Paper>
    </>
  )
}

export default Expenses
