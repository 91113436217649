import { Link as RouterLink } from 'react-router-dom'
import AppBar from '@mui/material/AppBar'
import Box from '@mui/material/Box'
import IconButton from '@mui/material/IconButton'
import MenuIcon from '@mui/icons-material/Menu'
import Typography from '@mui/material/Typography'
import Link from '@mui/material/Link'
import { MainListItems } from './listItems'
import Toolbar from './Toolbar'

function ResponsiveAppBar({ open, onOpenChange }) {
  return (
    <AppBar component="nav">
      <Toolbar>
        <Link
          component={RouterLink}
          to="/"
          sx={{
            display: 'flex',
          }}
        >
          <Box
            component="img"
            src="/static/img/brand/main-logo-white-letters.png"
            alt="logo"
            sx={{
              height: { xs: 50, sm: 60 },
            }}
          />
        </Link>
        <Typography
          variant="h6"
          component="div"
          sx={{ flexGrow: 1 }}
        ></Typography>
        <IconButton
          color="inherit"
          aria-label="open drawer"
          edge="end"
          onClick={() => onOpenChange(!open)}
          sx={{ ml: 2, display: { sm: 'none' } }}
        >
          <MenuIcon />
        </IconButton>
        <Box sx={{ display: { xs: 'none', sm: 'block' } }}>
          <MainListItems />
        </Box>
      </Toolbar>
    </AppBar>
  )
}

export default ResponsiveAppBar
