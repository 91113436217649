import React, { useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useParams } from 'react-router-dom'
import { toast } from 'react-toastify'
import Paper from '@mui/material/Paper'
import Divider from '@mui/material/Divider'
import Grid from '@mui/material/Grid'
import Stack from '@mui/material/Stack'
import Box from '@mui/material/Box'
import TextField from '@mui/material/TextField'
import Typography from '@mui/material/Typography'
import Button from '@mui/material/Button'
import InputLabel from '@mui/material/InputLabel'
import MenuItem from '@mui/material/MenuItem'
import Select from '@mui/material/Select'
import FormHelperText from '@mui/material/FormHelperText'
import Radio from '@mui/material/Radio'
import RadioGroup from '@mui/material/RadioGroup'
import FormControlLabel from '@mui/material/FormControlLabel'
import FormControl from '@mui/material/FormControl'
import FormLabel from '@mui/material/FormLabel'
import SaveIcon from '@mui/icons-material/Save'
import ClearIcon from '@mui/icons-material/Clear'
import CircularProgress from '@mui/material/CircularProgress'
import Chip from '@mui/material/Chip'
import IconButton from '@mui/material/IconButton'
import Tooltip from '@mui/material/Tooltip'
import EditIcon from '@mui/icons-material/Edit'
import moment from 'moment'
import { useForm, Controller } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import medicalHistorySchema from '../../../../../validators/medicalHistorySchema'
import { getPatient } from '../../../../../features/patients/PatientSlice'
import {
  updateMedicalHistory,
  reset as resetState,
} from '../../../../../features/medicalHistory/MedicalHistorySlice'

const ITEM_HEIGHT = 48
const ITEM_PADDING_TOP = 8
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
}

const diseases = [
  'Alcoholismo',
  'Anemia',
  'Alergias',
  'Asma',
  'Cáncer',
  'Cardiopatía',
  'Colitis',
  'Diabetes',
  'Drogadicción',
  'Epilepsia',
  'Enfermedades Infecciosas',
  'Enfermedades venéreas',
  'Enfermedades Mentales',
  'Fiebre Reumática',
  'Gastritis',
  'Glaucoma',
  'Hepatitis',
  'Herpes simple',
  'Hígado',
  'Hipertensión arterial (presión alta)',
  'Hipotensión arterial (presión baja)',
  'Lesiones de cabeza/cuello',
  'Migraña',
  'Riñón',
  'Respiratorias',
  'Sinusitis',
  'Úlceras',
]

function MedicalHistoryView() {
  const dispatch = useDispatch()
  const { id } = useParams()

  const { isLoading, isError, isSuccess, message } = useSelector(
    (state) => state.medicalHistory
  )
  const { patient } = useSelector((state) => state.patients)

  const {
    control,
    reset,
    formState: { errors },
    handleSubmit,
  } = useForm({
    mode: 'onChange',
    resolver: yupResolver(medicalHistorySchema),
  })

  useEffect(() => {
    if (isError) {
      toast.error(message)
    }

    if (isSuccess) {
      toast.success('Historia Clinica actualizada correctamente!')
      dispatch(resetState())
      dispatch(getPatient(id))
    }

    if (patient) {
      const { medicalHistory } = patient
      reset({
        healthCondition: medicalHistory.healthCondition,
        lastPhysicalExam: medicalHistory.lastPhysicalExam
          ? moment(medicalHistory.lastPhysicalExam).format('YYYY-MM-DD')
          : '',
        medicalTreatment: medicalHistory.medicalTreatment,
        allergicMedicines: medicalHistory.allergicMedicines,
        allergicMaterials: medicalHistory.allergicMaterials,
        previouslyAnesthetized: medicalHistory.previouslyAnesthetized
          ? 'yes'
          : 'no',
        anesthesiaUnusualReaction: medicalHistory.anesthesiaUnusualReaction,
        diseasesSuffered: medicalHistory.diseasesSuffered,
        pregnancy: medicalHistory.pregnancy ? 'yes' : 'no',
        monthsPregnancy: medicalHistory.monthsPregnancy
          ? medicalHistory.monthsPregnancy
          : '',
        breastfeeding: medicalHistory.breastfeeding ? 'yes' : 'no',
      })
      setOptionalValues({
        medicalTreatmentRadio: patient.medicalHistory.medicalTreatment
          ? 'yes'
          : 'no',
        allergicMedicinesRadio: patient.medicalHistory.allergicMedicines
          ? 'yes'
          : 'no',
        allergicMaterialsRadio: patient.medicalHistory.allergicMaterials
          ? 'yes'
          : 'no',
        anesthesiaUnusualReactionRadio: patient.medicalHistory
          .anesthesiaUnusualReaction
          ? 'yes'
          : 'no',
      })
    }

    dispatch(resetState())
  }, [patient, isError, isSuccess, message, dispatch, reset, id])

  const [isEdit, setIsEdit] = useState(false)
  const [optionalValues, setOptionalValues] = useState({
    medicalTreatmentRadio: '',
    allergicMedicinesRadio: '',
    allergicMaterialsRadio: '',
    anesthesiaUnusualReactionRadio: '',
  })

  const handleChange = (e) => {
    setOptionalValues({ ...optionalValues, [e.target.name]: e.target.value })
  }

  const onSubmit = (data, e) => {
    e.preventDefault()
    const formData = {
      ...data,
      previouslyAnesthetized: data.previouslyAnesthetized === 'yes',
      pregnancy: data.pregnancy === 'yes',
    }
    const formattedData = {
      id: patient.medicalHistory._id,
      data: formData,
    }
    // console.log(formattedData)
    dispatch(updateMedicalHistory(formattedData))
  }

  if (isLoading) {
    return (
      <Box sx={{ display: 'flex', justifyContent: 'center' }}>
        <CircularProgress />
      </Box>
    )
  }

  return (
    <Paper variant="outlined">
      <Stack
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        sx={{ p: 2 }}
      >
        <Typography variant="subtitle2">
          Datos personales sobre su estado de salud
        </Typography>
        <Tooltip title="Editar">
          <IconButton
            aria-label="editar"
            size="small"
            onClick={() => setIsEdit(true)}
          >
            <EditIcon />
          </IconButton>
        </Tooltip>
      </Stack>
      <Divider />
      <Box
        component="form"
        autoComplete="off"
        noValidate
        sx={{ my: 1, p: 2 }}
        onSubmit={handleSubmit(onSubmit)}
      >
        <Grid container spacing={2} sx={{ mb: 4 }}>
          <Grid item xs={12} lg={6}>
            <Controller
              name="healthCondition"
              control={control}
              defaultValue=""
              render={({ field }) => (
                <FormControl
                  fullWidth
                  size="small"
                  disabled={!isEdit}
                  error={!!errors.healthCondition}
                >
                  <InputLabel id="health-condition">
                    ¿Cómo catalogaría su salud?*
                  </InputLabel>
                  <Select
                    {...field}
                    labelId="health-condition"
                    label="¿Cómo catalogaría su salud?*"
                  >
                    <MenuItem value="excellent">Excelente</MenuItem>
                    <MenuItem value="good">Buena</MenuItem>
                    <MenuItem value="acceptable">Aceptable</MenuItem>
                    <MenuItem value="bad">Mala</MenuItem>
                  </Select>
                  {errors.healthCondition && (
                    <FormHelperText>
                      {errors.healthCondition.message}
                    </FormHelperText>
                  )}
                </FormControl>
              )}
            />
          </Grid>
          <Grid item xs={12} lg={6}>
            <Controller
              name="lastPhysicalExam"
              control={control}
              defaultValue=""
              render={({ field }) => (
                <TextField
                  {...field}
                  label="¿Cuándo se sometió al último examen físico?*"
                  size="small"
                  type="date"
                  disabled={!isEdit}
                  fullWidth
                  error={!!errors.lastPhysicalExam}
                  helperText={
                    errors.lastPhysicalExam && errors.lastPhysicalExam.message
                  }
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              )}
            />
          </Grid>
          <Grid item xs={12} lg={6}>
            <FormControl>
              <FormLabel
                id="medical-treatment-radio-buttons-group"
                sx={{ typography: 'caption' }}
              >
                Actualmente ¿se encuentra bajo algún tratamiento médico?
              </FormLabel>
              <RadioGroup
                row
                aria-labelledby="medical-treatment-radio-buttons-group"
                name="medicalTreatmentRadio"
                value={optionalValues.medicalTreatmentRadio}
                onChange={handleChange}
              >
                <FormControlLabel
                  value="yes"
                  disabled={!isEdit}
                  control={<Radio />}
                  label={<Typography variant="body2">Sí</Typography>}
                />
                <FormControlLabel
                  value="no"
                  disabled={!isEdit}
                  control={<Radio />}
                  label={<Typography variant="body2">No</Typography>}
                />
              </RadioGroup>
            </FormControl>
            {optionalValues.medicalTreatmentRadio === 'yes' && (
              <Controller
                name="medicalTreatment"
                control={control}
                defaultValue=""
                render={({ field }) => (
                  <TextField
                    {...field}
                    label="Por favor especifique el medicamento y la enfermedad"
                    size="small"
                    disabled={!isEdit}
                    rows={2}
                    multiline
                    fullWidth
                  />
                )}
              />
            )}
          </Grid>
          <Grid item xs={12} lg={6}>
            <FormControl>
              <FormLabel
                id="allergic-medicines-radio-buttons-group"
                sx={{ typography: 'caption' }}
              >
                Es usted alérgico a algún medicamento
              </FormLabel>
              <RadioGroup
                row
                aria-labelledby="allergic-medicines-radio-buttons-group"
                name="allergicMedicinesRadio"
                value={optionalValues.allergicMedicinesRadio}
                onChange={handleChange}
              >
                <FormControlLabel
                  value="yes"
                  disabled={!isEdit}
                  control={<Radio />}
                  label={<Typography variant="body2">Sí</Typography>}
                />
                <FormControlLabel
                  value="no"
                  disabled={!isEdit}
                  control={<Radio />}
                  label={<Typography variant="body2">No</Typography>}
                />
              </RadioGroup>
            </FormControl>
            {optionalValues.allergicMedicinesRadio === 'yes' && (
              <Controller
                name="allergicMedicines"
                control={control}
                defaultValue=""
                render={({ field }) => (
                  <TextField
                    {...field}
                    label="Por favor especifique el medicamento"
                    size="small"
                    disabled={!isEdit}
                    rows={2}
                    multiline
                    fullWidth
                  />
                )}
              />
            )}
          </Grid>
          <Grid item xs={12} lg={6}>
            <FormControl>
              <FormLabel
                id="allergic-materials-radio-buttons-group"
                sx={{ typography: 'caption' }}
              >
                Es usted alérgico al látex o cualquier otra sustancia o material
              </FormLabel>
              <RadioGroup
                row
                aria-labelledby="allergic-materials-radio-buttons-group"
                name="allergicMaterialsRadio"
                value={optionalValues.allergicMaterialsRadio}
                onChange={handleChange}
              >
                <FormControlLabel
                  value="yes"
                  disabled={!isEdit}
                  control={<Radio />}
                  label={<Typography variant="body2">Sí</Typography>}
                />
                <FormControlLabel
                  value="no"
                  disabled={!isEdit}
                  control={<Radio />}
                  label={<Typography variant="body2">No</Typography>}
                />
              </RadioGroup>
            </FormControl>
            {optionalValues.allergicMaterialsRadio === 'yes' && (
              <Controller
                name="allergicMaterials"
                control={control}
                defaultValue=""
                render={({ field }) => (
                  <TextField
                    {...field}
                    label="Por favor especifique"
                    size="small"
                    disabled={!isEdit}
                    rows={2}
                    multiline
                    fullWidth
                  />
                )}
              />
            )}
          </Grid>
          <Grid item xs={12}>
            <Controller
              name="previouslyAnesthetized"
              control={control}
              defaultValue={false}
              render={({ field }) => (
                <FormControl>
                  <FormLabel
                    id="previously-anesthetized-radio-buttons-group"
                    sx={{ typography: 'caption' }}
                  >
                    Lo han anestesiado anteriormente
                  </FormLabel>
                  <RadioGroup
                    {...field}
                    row
                    aria-labelledby="previously-anesthetized-radio-buttons-group"
                  >
                    <FormControlLabel
                      value="yes"
                      disabled={!isEdit}
                      control={<Radio />}
                      label={<Typography variant="body2">Sí</Typography>}
                    />
                    <FormControlLabel
                      value="no"
                      disabled={!isEdit}
                      control={<Radio />}
                      label={<Typography variant="body2">No</Typography>}
                    />
                  </RadioGroup>
                </FormControl>
              )}
            />
          </Grid>
          <Grid item xs={12} lg={6}>
            <FormControl>
              <FormLabel
                id="anesthesia-reaction-radio-buttons-group"
                sx={{ typography: 'caption' }}
              >
                Ha tenido alguna reacción inusual
              </FormLabel>
              <RadioGroup
                row
                aria-labelledby="anesthesia-reaction-radio-buttons-group"
                name="anesthesiaUnusualReactionRadio"
                value={optionalValues.anesthesiaUnusualReactionRadio}
                onChange={handleChange}
              >
                <FormControlLabel
                  value="yes"
                  disabled={!isEdit}
                  control={<Radio />}
                  label={<Typography variant="body2">Sí</Typography>}
                />
                <FormControlLabel
                  value="no"
                  disabled={!isEdit}
                  control={<Radio />}
                  label={<Typography variant="body2">No</Typography>}
                />
              </RadioGroup>
            </FormControl>
            {optionalValues.anesthesiaUnusualReactionRadio === 'yes' && (
              <Controller
                name="anesthesiaUnusualReaction"
                control={control}
                defaultValue=""
                render={({ field }) => (
                  <TextField
                    {...field}
                    label="Explíquela por favor"
                    size="small"
                    disabled={!isEdit}
                    rows={2}
                    multiline
                    fullWidth
                  />
                )}
              />
            )}
          </Grid>
          <Grid item xs={12}>
            <Controller
              name="diseasesSuffered"
              control={control}
              defaultValue={[]}
              render={({ field }) => (
                <FormControl fullWidth size="small">
                  <InputLabel id="diseases-suffered">
                    Señale por favor cualquier enfermedad pasada o presente que
                    haya padecido
                  </InputLabel>
                  <Select
                    {...field}
                    multiple
                    disabled={!isEdit}
                    labelId="diseases-suffered"
                    label="Señale por favor cualquier enfermedad pasada o presente que haya padecido"
                    renderValue={(selected) => (
                      <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                        {selected.map((value) => (
                          <Chip key={value} label={value} />
                        ))}
                      </Box>
                    )}
                    MenuProps={MenuProps}
                  >
                    {diseases.map((disease) => (
                      <MenuItem key={disease} value={disease}>
                        {disease}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              )}
            />
          </Grid>
          {patient.gender === 'Femenino' && (
            <>
              <Grid item xs={12}>
                <Controller
                  name="pregnancy"
                  control={control}
                  defaultValue={false}
                  render={({ field }) => (
                    <FormControl>
                      <FormLabel
                        id="pregnancy-radio-buttons-group"
                        sx={{ typography: 'caption' }}
                      >
                        ¿Está embarazada?
                      </FormLabel>
                      <RadioGroup
                        {...field}
                        row
                        aria-labelledby="pregnancy-radio-buttons-group"
                      >
                        <FormControlLabel
                          value="yes"
                          disabled={!isEdit}
                          control={<Radio />}
                          label={<Typography variant="body2">Sí</Typography>}
                        />
                        <FormControlLabel
                          value="no"
                          disabled={!isEdit}
                          control={<Radio />}
                          label={<Typography variant="body2">No</Typography>}
                        />
                      </RadioGroup>
                    </FormControl>
                  )}
                />
              </Grid>
              <Grid item xs={12} lg={6}>
                <Controller
                  name="monthsPregnancy"
                  control={control}
                  defaultValue=""
                  render={({ field }) => (
                    <TextField
                      {...field}
                      disabled={!isEdit}
                      label="¿cuántos meses?"
                      size="small"
                      type="number"
                      fullWidth
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12}>
                <Controller
                  name="breastfeeding"
                  control={control}
                  defaultValue={false}
                  render={({ field }) => (
                    <FormControl>
                      <FormLabel
                        id="breastfeeding-radio-buttons-group"
                        sx={{ typography: 'caption' }}
                      >
                        ¿Está amamantando?
                      </FormLabel>
                      <RadioGroup
                        {...field}
                        row
                        aria-labelledby="breastfeeding-radio-buttons-group"
                      >
                        <FormControlLabel
                          value="yes"
                          disabled={!isEdit}
                          control={<Radio />}
                          label={<Typography variant="body2">Sí</Typography>}
                        />
                        <FormControlLabel
                          value="no"
                          disabled={!isEdit}
                          control={<Radio />}
                          label={<Typography variant="body2">No</Typography>}
                        />
                      </RadioGroup>
                    </FormControl>
                  )}
                />
              </Grid>
            </>
          )}
        </Grid>
        {isEdit && (
          <Stack direction="row" spacing={1} sx={{ mt: 4 }}>
            <Box sx={{ position: 'relative' }}>
              <Button
                type="submit"
                variant="contained"
                color="primary"
                size="small"
                startIcon={<SaveIcon />}
                disabled={isLoading}
              >
                Guardar cambios
              </Button>
              {isLoading && (
                <CircularProgress
                  size={24}
                  sx={{
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    marginTop: '-12px',
                    marginLeft: '-12px',
                  }}
                />
              )}
            </Box>
            <Button
              size="small"
              startIcon={<ClearIcon />}
              onClick={() => setIsEdit(!isEdit)}
            >
              Cancelar
            </Button>
          </Stack>
        )}
      </Box>
    </Paper>
  )
}

export default MedicalHistoryView
