import { Link } from 'react-router-dom'
import ListItemButton from '@mui/material/ListItemButton'
import ListItemIcon from '@mui/material/ListItemIcon'
import ListItemText from '@mui/material/ListItemText'
import DashboardIcon from '@mui/icons-material/Dashboard'
import PeopleIcon from '@mui/icons-material/People'
import AccountBalanceWalletIcon from '@mui/icons-material/AccountBalanceWallet'
import AccountBalanceIcon from '@mui/icons-material/AccountBalance'
import PaymentsIcon from '@mui/icons-material/Payments'
import Divider from '@mui/material/Divider'

const MainListItems = () => {
  return (
    <>
      <ListItemButton component={Link} to="/dashboard">
        <ListItemIcon>
          <DashboardIcon />
        </ListItemIcon>
        <ListItemText primary="Dashboard" />
      </ListItemButton>
      <ListItemButton component={Link} to="/dashboard/patients">
        <ListItemIcon>
          <PeopleIcon />
        </ListItemIcon>
        <ListItemText primary="Pacientes" />
      </ListItemButton>
      <ListItemButton component={Link} to="/dashboard/balance">
        <ListItemIcon>
          <AccountBalanceIcon />
        </ListItemIcon>
        <ListItemText primary="Balance" />
      </ListItemButton>
      <ListItemButton component={Link} to="/dashboard/expenses">
        <ListItemIcon>
          <AccountBalanceWalletIcon />
        </ListItemIcon>
        <ListItemText primary="Gastos" />
      </ListItemButton>
      <Divider sx={{ mt: 1 }} />
    </>
  )
}

const SecondaryListItems = () => {
  return (
    <>
      <ListItemButton component={Link} to="/dashboard/reports/payments">
        <ListItemIcon>
          <PaymentsIcon />
        </ListItemIcon>
        <ListItemText primary="Ingresos" />
      </ListItemButton>
    </>
  )
}

export { MainListItems, SecondaryListItems }
