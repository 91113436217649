import Box from '@mui/material/Box'
import Container from '@mui/material/Container'
import Typography from '@mui/material/Typography'
import Grid from '@mui/material/Grid'

import Link from '@mui/material/Link'
import Stack from '@mui/material/Stack'
import Avatar from '@mui/material/Avatar'
import { red, blue, green, blueGrey } from '@mui/material/colors'
import PlaceIcon from '@mui/icons-material/Place'
import FacebookIcon from '@mui/icons-material/Facebook'
import WhatsAppIcon from '@mui/icons-material/WhatsApp'
import LocalPhoneIcon from '@mui/icons-material/LocalPhone'

function Socials() {
  return (
    <Container component="section" sx={{ my: 12 }}>
      <Typography
        component="h1"
        variant="h4"
        color="inherit"
        align="center"
        sx={{ fontWeight: 'bold' }}
      >
        {'Agenda tu Cita'}
      </Typography>
      <Box sx={{ mt: 8, display: 'flex', flexWrap: 'wrap' }}>
        <Grid
          container
          direction="row"
          justifyContent="center"
          alignItems="center"
          // sx={{ m: 2 }}
        >
          <Grid item xs={12} md={6}>
            <Box component="ul" sx={{ m: 0, listStyle: 'none', p: 0 }}>
              <Box component="li" sx={{ py: 1 }}>
                <Stack direction="row" spacing={2} alignItems="center">
                  <Avatar
                    sx={{ width: 56, height: 56, bgcolor: red[500] }}
                    variant="rounded"
                  >
                    <PlaceIcon fontSize="large" />
                  </Avatar>
                  <Stack direction="column" spacing={0}>
                    <Typography variant="h6" sx={{ fontWeight: 'bold' }}>
                      Dirección
                    </Typography>
                    <Typography variant="body1">
                      Av. Reforma #2007 Int. 509 Plaza Laguna, 5to piso.
                    </Typography>
                  </Stack>
                </Stack>
              </Box>
              <Box component="li" sx={{ py: 1 }}>
                <Stack direction="row" spacing={2} alignItems="center">
                  <Link color="inherit" href="https://wa.me/6694310008">
                    <Avatar
                      sx={{ width: 56, height: 56, bgcolor: green.A700 }}
                      variant="rounded"
                    >
                      <WhatsAppIcon fontSize="large" />
                    </Avatar>
                  </Link>
                  <Stack direction="column" spacing={0}>
                    <Typography variant="h6" sx={{ fontWeight: 'bold' }}>
                      WhatsApp
                    </Typography>
                    <Link color="inherit" href="https://wa.me/6694310008">
                      <Typography variant="body1">(669) 431-00-08</Typography>
                    </Link>
                  </Stack>
                </Stack>
              </Box>
              <Box component="li" sx={{ py: 1 }}>
                <Stack direction="row" spacing={2} alignItems="center">
                  <Link color="inherit" href="tel:6691771462">
                    <Avatar
                      sx={{ width: 56, height: 56, bgcolor: blueGrey[700] }}
                      variant="rounded"
                    >
                      <LocalPhoneIcon fontSize="large" />
                    </Avatar>
                  </Link>
                  <Stack direction="column" spacing={0}>
                    <Typography variant="h6" sx={{ fontWeight: 'bold' }}>
                      Teléfono
                    </Typography>
                    <Link color="inherit" href="tel:6691771462">
                      <Typography variant="body1">(669) 177-14-62</Typography>
                    </Link>
                  </Stack>
                </Stack>
              </Box>
              <Box component="li" sx={{ py: 1 }}>
                <Stack direction="row" spacing={2} alignItems="center">
                  <Link
                    color="inherit"
                    href="https://www.facebook.com/dr.diego.endo/"
                  >
                    <Avatar
                      sx={{ width: 56, height: 56, bgcolor: blue[800] }}
                      variant="rounded"
                    >
                      <FacebookIcon fontSize="large" />
                    </Avatar>
                  </Link>
                  <Stack direction="column" spacing={0}>
                    <Typography variant="h6" sx={{ fontWeight: 'bold' }}>
                      Facebook
                    </Typography>
                    <Link
                      color="inherit"
                      href="https://www.facebook.com/dr.diego.endo/"
                    >
                      <Typography variant="body1">@dr.diego.endo</Typography>
                    </Link>
                  </Stack>
                </Stack>
              </Box>
            </Box>
          </Grid>
          <Grid
            item
            xs={12}
            md={6}
            sx={{
              position: 'relative',
              height: 500,
              backgroundSize: 'contain',
              backgroundRepeat: 'no-repeat',
              backgroundPosition: 'center',
              backgroundImage: `url(/static/img/contact/plaza-laguna.webp)`,
            }}
          ></Grid>
        </Grid>
      </Box>
    </Container>
  )
}

export default Socials
