import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
// import Link from '@mui/material/Link'
import Typography from '@mui/material/Typography'
import Title from '../common/Title'
import moment from 'moment'

// function preventDefault(event) {
//   event.preventDefault()
// }

export default function TodayPayments() {
  const { payments } = useSelector((state) => state.payments)
  const [totalPayments, setTotalPayments] = useState(0)

  useEffect(() => {
    if (payments) {
      let sum = 0
      payments.forEach((p) => {
        const today = moment().format('YYYY-MM-DD')
        const paymentDate = moment(p.createdAt).format('YYYY-MM-DD')
        const isSame = moment(today).isSame(paymentDate)

        if (isSame) sum += p.amount
      })
      setTotalPayments(sum)
    }
  }, [payments])

  return (
    <>
      <Title>Pagos</Title>
      <Typography component="p" variant="h4">
        ${Number(totalPayments).toLocaleString()}
      </Typography>
      <Typography color="text.secondary" sx={{ flex: 1 }}>
        hoy {moment().format('DD ')} de {moment().format('MMMM, YYYY')}
      </Typography>
      {/* <div>
        <Link color="primary" href="#" onClick={preventDefault}>
          Ver balance
        </Link>
      </div> */}
    </>
  )
}
