import { Navigate } from 'react-router-dom'
import { useAuthStatus } from '../hooks/useAuthStatus'
import Backdrop from '@mui/material/Backdrop'
import CircularProgress from '@mui/material/CircularProgress'

const PrivateRoute = ({ children }) => {
  const { loggedIn, checkingStatus } = useAuthStatus()

  if (checkingStatus) {
    return (
      <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open
      >
        <CircularProgress color="inherit" />
      </Backdrop>
    )
  }

  return loggedIn ? children : <Navigate to="/login" replace />
}

export default PrivateRoute
