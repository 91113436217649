import React, { useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useNavigate, useParams } from 'react-router-dom'
import { toast } from 'react-toastify'
import moment from 'moment'

import Paper from '@mui/material/Paper'
import Divider from '@mui/material/Divider'
import Grid from '@mui/material/Grid'
import Stack from '@mui/material/Stack'
import Box from '@mui/material/Box'
import TextField from '@mui/material/TextField'
import Typography from '@mui/material/Typography'
import Button from '@mui/material/Button'
import FormControlLabel from '@mui/material/FormControlLabel'
import InputAdornment from '@mui/material/InputAdornment'
import Checkbox from '@mui/material/Checkbox'
import SaveIcon from '@mui/icons-material/Save'
import ClearIcon from '@mui/icons-material/Clear'
import CircularProgress from '@mui/material/CircularProgress'
import FormControl from '@mui/material/FormControl'
import FormLabel from '@mui/material/FormLabel'
import OutlinedInput from '@mui/material/OutlinedInput'
import Tooltip from '@mui/material/Tooltip'
import IconButton from '@mui/material/IconButton'
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline'
import HighlightOffIcon from '@mui/icons-material/HighlightOff'
import MoreVertIcon from '@mui/icons-material/MoreVert'
import Check from '@mui/icons-material/Check'
import MenuItem from '@mui/material/MenuItem'
import Menu from '@mui/material/Menu'
import ListItemIcon from '@mui/material/ListItemIcon'
import Chip from '@mui/material/Chip'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell, { tableCellClasses } from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import { styled } from '@mui/material/styles'
import Odontogram from '../../../common/Odontogram'

import { useForm, Controller } from 'react-hook-form'
// import { yupResolver } from '@hookform/resolvers/yup'
// import appointmentSchema from '../../../../../validators/appointmentSchema'
import {
  getAppointment,
  updateAppointment,
  updateDentalOrgan,
  reset as resetState,
} from '../../../../../features/appointments/AppointmentsSlice'
import { getPatient } from '../../../../../features/patients/PatientSlice'

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: '#00796b',
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 12,
  },
}))

const AppointmentEdit = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const { id, appointmentId } = useParams()
  const date = moment().format('DD/MMM/YYYY')

  const { appointment, isLoading, isError, isSuccess, message } = useSelector(
    (state) => state.appointments
  )

  const {
    control,
    reset,
    // formState: { errors },
    handleSubmit,
  } = useForm({
    mode: 'onChange',
    // resolver: yupResolver(appointmentSchema),
  })

  const [selectedDentalOrgans, setSelectedDentalOrgans] = useState([])
  const [confirmOpen, setConfirmOpen] = useState(false)
  const [anchorElCost, setAnchorElCost] = useState(null)
  const [clinicalExam, setClinicalExam] = useState({
    coldTest: false,
    heatTest: false,
    airTest: false,
    percussionTest: false,
    spontaneousTest: false,
    changeColorTest: false,
  })
  const [rootCanalTherapy, setRootCanalTherapy] = useState([])
  const [comments, setComments] = useState([''])
  const [costTx, setCostTx] = useState('')
  const [costPost, setCostPost] = useState('')
  const [costResin, setCostResin] = useState('')
  const [total, setTotal] = useState(0)
  const [showCostPost, setShowCostPost] = useState(true)
  const [showCostResin, setShowCostResin] = useState(false)

  useEffect(() => {
    dispatch(getAppointment(appointmentId))
  }, [dispatch, appointmentId])

  useEffect(() => {
    if (appointment) {
      reset({
        dentalOrgan: appointment.dentalOrgan,
        radiographicInterpretation: appointment.radiographicInterpretation,
        dxPulpar: appointment.dxPulpar,
        dxPeriapical: appointment.dxPeriapical,
        diagnosis: appointment.diagnosis,
        treatment: appointment.treatment,
        observations: appointment.observations,
      })
      setSelectedDentalOrgans(appointment.dentalOrgan)
      dispatch(updateDentalOrgan(appointment.dentalOrgan))
      setClinicalExam(appointment.clinicalExam)
      setRootCanalTherapy(appointment.rootCanalTherapy)
      setComments(appointment.comments)
      setCostTx(Number(appointment.costTx))
      setCostPost(Number(appointment.costPost))
      setCostResin(appointment.costResin ? Number(appointment.costResin) : 0)
      setTotal(Number(appointment.total))

      if (Number(appointment.costResin) > 0) {
        setShowCostPost(false)
        setShowCostResin(true)
      }
    }
  }, [appointment, reset, dispatch])

  useEffect(() => {
    if (isError) {
      toast.error(message)
      dispatch(resetState())
    }

    if (isSuccess) {
      dispatch(resetState())
      dispatch(getPatient(id))
      toast.success('Información actualizada correctamente')
      navigate(`/dashboard/patients/${id}/appointments`)
    }
  }, [dispatch, isError, isSuccess, message, navigate, id, reset, appointment])

  useEffect(() => {
    if (appointment) {
      const sumTotal = Number(costTx) + Number(costPost) + Number(costResin)
      setTotal(sumTotal)
    }
  }, [appointment, costTx, costPost, costResin, showCostPost, showCostResin])

  const handleOpenOdontogram = () => {
    setConfirmOpen(true)
  }
  const addRootCanalTheraphy = () => {
    setRootCanalTherapy([
      ...rootCanalTherapy,
      {
        conduct: '',
        mm: '',
        reference: '',
        firstInst: '',
        masterLime: '',
        principalCone: '',
      },
    ])
  }
  const removeRootCanalTheraphy = (index) => {
    const rows = [...rootCanalTherapy]
    rows.splice(index, 1)
    setRootCanalTherapy(rows)
  }
  const handleChangeRootCanal = (index, evnt) => {
    const { name, value } = evnt.target
    // const list = [...rootCanalTherapy]
    // list[index][name] = value
    // setRootCanalTherapy(newList)

    setRootCanalTherapy((current) =>
      current.map((obj, i) => {
        if (index === i) {
          return { ...obj, [name]: value }
        }

        return obj
      })
    )
  }
  const handleChangeCheckbox = (e) => {
    setClinicalExam({ ...clinicalExam, [e.target.name]: e.target.checked })
  }
  const addComment = () => {
    setComments([...comments, ''])
  }
  const removeComment = (index) => {
    const rows = [...comments]
    rows.splice(index, 1)
    setComments(rows)
  }
  const handleChangeComment = (index, evnt) => {
    const { value } = evnt.target
    const list = [...comments]
    list[index] = value
    setComments(list)
  }
  const handleCostTxChange = (e) => {
    e.preventDefault()
    setCostTx(e.target.value)
  }
  const handleCostPostChange = (e) => {
    e.preventDefault()
    setCostPost(e.target.value)
  }
  const handleCostResinChange = (e) => {
    e.preventDefault()
    setCostResin(e.target.value)
  }
  const handleTotalChange = (e) => {
    e.preventDefault()
    setTotal(e.target.value)
  }
  const handleFocus = (e, setVal) => {
    e.preventDefault()
    const amount = Number(e.target.value)
    if (amount <= 0) setVal('')
  }
  const handleShowCostPost = () => {
    setAnchorElCost(null)
    setShowCostPost(true)
    setShowCostResin(false)
    setCostPost('')
    setCostResin('')
  }
  const handleShowCostResin = () => {
    setAnchorElCost(null)
    setShowCostResin(true)
    setShowCostPost(false)
    setCostResin('')
    setCostPost('')
  }
  const handleOpenCostMenu = (event) => {
    setAnchorElCost(event.currentTarget)
  }
  const handleCloseCostMenu = () => {
    setAnchorElCost(null)
  }

  const onSubmit = (data, e) => {
    e.preventDefault()
    const formData = {
      ...data,
      dentalOrgan: selectedDentalOrgans,
      clinicalExam,
      rootCanalTherapy,
      comments,
      costTx: Number(costTx),
      costPost: Number(costPost),
      costResin: Number(costResin),
      total: Number(total),
      patient: id,
    }
    const formattedData = {
      id: appointmentId,
      data: formData,
    }
    // console.log(formattedData)
    dispatch(updateAppointment(formattedData))
  }

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Paper variant="outlined">
          <Stack
            direction="row"
            justifyContent="space-between"
            alignItems="center"
            spacing={1}
            sx={{ p: 2 }}
          >
            <Typography sx={{ fontWeight: 'bold' }} variant="h6">
              Editar
            </Typography>
            <Typography variant="body2" color="textSecondary">
              Fecha: <strong>{date}</strong>
            </Typography>
          </Stack>
          <Divider />
          <Box
            component="form"
            autoComplete="off"
            noValidate
            sx={{ p: 2 }}
            onSubmit={handleSubmit(onSubmit)}
          >
            <Grid container spacing={2}>
              <Grid item xs={12} md={6}>
                {/* <Controller
                  name="dentalOrgan"
                  control={control}
                  defaultValue=""
                  render={({ field }) => (
                    <FormControl fullWidth error={!!errors.dentalOrgan}>
                      <FormLabel
                        id="dental-organ"
                        sx={{ typography: 'subtitle2', pb: 1 }}
                      >
                        Órgano Dental *
                      </FormLabel>
                      <OutlinedInput
                        {...field}
                        placeholder="11-18, 21-28, 31-38, 41-48"
                      />
                      <FormHelperText id="dental-organ">
                        {errors.dentalOrgan && errors.dentalOrgan.message}
                      </FormHelperText>
                    </FormControl>
                  )}
                /> */}
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <Stack
                      direction="row"
                      spacing={1}
                      justifyContent="flex-start"
                      alignItems="center"
                    >
                      <Typography
                        variant="subtitle2"
                        color={
                          selectedDentalOrgans.length <= 0
                            ? 'error'
                            : 'textSecondary'
                        }
                      >
                        Órgano Dental *
                      </Typography>
                      {/* <Tooltip title="Abrir Odontograma">
                        <IconButton
                          color={
                            selectedDentalOrgans.length <= 0
                              ? 'error'
                              : 'primary'
                          }
                          aria-label="abrir odontograma"
                          onClick={handleOpenOdontogram}
                        >
                          <ContentPasteSearchTwoToneIcon />
                        </IconButton>
                      </Tooltip> */}
                      <Button
                        variant="outlined"
                        color={
                          selectedDentalOrgans.length <= 0 ? 'error' : 'primary'
                        }
                        size="small"
                        onClick={handleOpenOdontogram}
                      >
                        Abrir Odontograma
                      </Button>
                    </Stack>
                    <Typography variant="caption" color="error">
                      {selectedDentalOrgans.length <= 0 &&
                        'Por favor selecciona el órgano dental'}
                    </Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <Stack
                      direction="row"
                      spacing={1}
                      justifyContent="flex-start"
                      alignItems="center"
                    >
                      {selectedDentalOrgans.map((t) => (
                        <Chip key={t} label={t} color="primary"></Chip>
                      ))}
                    </Stack>
                  </Grid>
                </Grid>
                <Odontogram
                  keepMounted
                  open={confirmOpen}
                  setOpen={setConfirmOpen}
                  onConfirm={(dentalOrgans) => {
                    setSelectedDentalOrgans(dentalOrgans)
                  }}
                />
              </Grid>
            </Grid>
            <Divider sx={{ my: 4 }} />
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Typography variant="subtitle2" color="textSecondary">
                  Examen Clínico
                </Typography>
              </Grid>
              <Grid item xs={12} sm={4} md={2}>
                <FormControlLabel
                  control={
                    <Checkbox
                      name="coldTest"
                      checked={!!clinicalExam.coldTest}
                      value={clinicalExam.coldTest}
                      onChange={handleChangeCheckbox}
                      size="small"
                    />
                  }
                  label={
                    <Typography variant="body2" color="textSecondary">
                      Frío
                    </Typography>
                  }
                  labelPlacement="start"
                />
              </Grid>
              <Grid item xs={12} sm={4} md={2}>
                <FormControlLabel
                  control={
                    <Checkbox
                      name="heatTest"
                      checked={!!clinicalExam.heatTest}
                      value={clinicalExam.heatTest}
                      onChange={handleChangeCheckbox}
                      size="small"
                    />
                  }
                  label={
                    <Typography variant="body2" color="textSecondary">
                      Calor
                    </Typography>
                  }
                  labelPlacement="start"
                />
              </Grid>
              <Grid item xs={12} sm={4} md={2}>
                <FormControlLabel
                  control={
                    <Checkbox
                      name="airTest"
                      checked={!!clinicalExam.airTest}
                      value={clinicalExam.airTest}
                      onChange={handleChangeCheckbox}
                      size="small"
                    />
                  }
                  label={
                    <Typography variant="body2" color="textSecondary">
                      Aire
                    </Typography>
                  }
                  labelPlacement="start"
                />
              </Grid>
              <Grid item xs={12} sm={4} md={2}>
                <FormControlLabel
                  control={
                    <Checkbox
                      name="percussionTest"
                      checked={!!clinicalExam.percussionTest}
                      value={clinicalExam.percussionTest}
                      onChange={handleChangeCheckbox}
                      size="small"
                    />
                  }
                  label={
                    <Typography variant="body2" color="textSecondary">
                      Percusión
                    </Typography>
                  }
                  labelPlacement="start"
                />
              </Grid>
              <Grid item xs={12} sm={4} md={2}>
                <FormControlLabel
                  control={
                    <Checkbox
                      name="spontaneousTest"
                      checked={!!clinicalExam.spontaneousTest}
                      value={clinicalExam.spontaneousTest}
                      onChange={handleChangeCheckbox}
                      size="small"
                    />
                  }
                  label={
                    <Typography variant="body2" color="textSecondary">
                      Espontáneo
                    </Typography>
                  }
                  labelPlacement="start"
                />
              </Grid>
              <Grid item xs={12} sm={4} md={2}>
                <FormControlLabel
                  control={
                    <Checkbox
                      name="changeColorTest"
                      checked={!!clinicalExam.changeColorTest}
                      value={clinicalExam.changeColorTest}
                      onChange={handleChangeCheckbox}
                      size="small"
                    />
                  }
                  label={
                    <Typography variant="body2" color="textSecondary">
                      Cambio de color
                    </Typography>
                  }
                  labelPlacement="start"
                />
              </Grid>
            </Grid>
            <Divider sx={{ my: 4 }} />
            <Grid container spacing={2}>
              <Grid item xs={12} md={12}>
                <Controller
                  name="radiographicInterpretation"
                  control={control}
                  defaultValue=""
                  render={({ field }) => (
                    <FormControl fullWidth>
                      <FormLabel
                        id="radiographic"
                        sx={{ typography: 'subtitle2', pb: 1 }}
                      >
                        Interpretación Radiográfica
                      </FormLabel>
                      <OutlinedInput {...field} multiline rows={2} />
                    </FormControl>
                  )}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <Controller
                  name="dxPulpar"
                  control={control}
                  defaultValue=""
                  render={({ field }) => (
                    <FormControl fullWidth>
                      <FormLabel
                        id="dx-pulpar"
                        sx={{ typography: 'subtitle2', pb: 1 }}
                      >
                        Dx Pulpar
                      </FormLabel>
                      <OutlinedInput {...field} placeholder="" />
                    </FormControl>
                  )}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <Controller
                  name="dxPeriapical"
                  control={control}
                  defaultValue=""
                  render={({ field }) => (
                    <FormControl fullWidth>
                      <FormLabel
                        id="dx-periapical"
                        sx={{ typography: 'subtitle2', pb: 1 }}
                      >
                        Dx Periapical
                      </FormLabel>
                      <OutlinedInput {...field} placeholder="" />
                    </FormControl>
                  )}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <Controller
                  name="diagnosis"
                  control={control}
                  defaultValue=""
                  render={({ field }) => (
                    <FormControl fullWidth>
                      <FormLabel
                        id="diagnosis"
                        sx={{ typography: 'subtitle2', pb: 1 }}
                      >
                        Pronóstico
                      </FormLabel>
                      <OutlinedInput {...field} placeholder="" />
                    </FormControl>
                  )}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <Controller
                  name="treatment"
                  control={control}
                  defaultValue=""
                  render={({ field }) => (
                    <FormControl fullWidth>
                      <FormLabel
                        id="treatment"
                        sx={{ typography: 'subtitle2', pb: 1 }}
                      >
                        Tratamiento
                      </FormLabel>
                      <OutlinedInput {...field} placeholder="" />
                    </FormControl>
                  )}
                />
              </Grid>
              <Grid item xs={12} md={12}>
                <Controller
                  name="observations"
                  control={control}
                  defaultValue=""
                  render={({ field }) => (
                    <FormControl fullWidth>
                      <FormLabel
                        id="observations"
                        sx={{ typography: 'subtitle2', pb: 1 }}
                      >
                        Observaciones
                      </FormLabel>
                      <OutlinedInput {...field} multiline rows={2} />
                    </FormControl>
                  )}
                />
              </Grid>
            </Grid>
            <Divider sx={{ my: 4 }} />
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Stack
                  direction="row"
                  spacing={1}
                  justifyContent="flex-start"
                  alignItems="center"
                >
                  <Typography variant="subtitle2" color="textSecondary">
                    Conductoterapia
                  </Typography>
                  <Tooltip title="Agregar">
                    <IconButton
                      color="primary"
                      aria-label="agregar conductoterapia"
                      onClick={addRootCanalTheraphy}
                    >
                      <AddCircleOutlineIcon />
                    </IconButton>
                  </Tooltip>
                </Stack>
              </Grid>
            </Grid>
            <TableContainer
              component={Paper}
              sx={{ backgroundColor: '#f5f5f5', mb: 4 }}
            >
              <Table sx={{ minWidth: 600 }} size="small">
                <TableHead>
                  <TableRow>
                    <StyledTableCell>Conducto</StyledTableCell>
                    <StyledTableCell align="center">MM</StyledTableCell>
                    <StyledTableCell align="center">Referencia</StyledTableCell>
                    <StyledTableCell align="center">1er inst</StyledTableCell>
                    <StyledTableCell align="center">
                      Lima maestra
                    </StyledTableCell>
                    <StyledTableCell align="center">
                      Cono principal
                    </StyledTableCell>
                    <StyledTableCell></StyledTableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {rootCanalTherapy.map((data, index) => {
                    const {
                      conduct,
                      mm,
                      reference,
                      firstInst,
                      masterLime,
                      principalCone,
                    } = data
                    return (
                      <TableRow
                        key={index}
                        sx={{
                          '&:last-child td, &:last-child th': {
                            border: 0,
                          },
                        }}
                      >
                        <TableCell sx={{ px: 1 }}>
                          <TextField
                            onChange={(evnt) =>
                              handleChangeRootCanal(index, evnt)
                            }
                            value={conduct}
                            name="conduct"
                            size="small"
                          />
                        </TableCell>
                        <TableCell sx={{ px: 1 }}>
                          <TextField
                            onChange={(evnt) =>
                              handleChangeRootCanal(index, evnt)
                            }
                            value={mm}
                            name="mm"
                            size="small"
                          />
                        </TableCell>
                        <TableCell sx={{ px: 1 }}>
                          <TextField
                            onChange={(evnt) =>
                              handleChangeRootCanal(index, evnt)
                            }
                            value={reference}
                            name="reference"
                            size="small"
                          />
                        </TableCell>
                        <TableCell sx={{ px: 1 }}>
                          <TextField
                            onChange={(evnt) =>
                              handleChangeRootCanal(index, evnt)
                            }
                            value={firstInst}
                            name="firstInst"
                            size="small"
                          />
                        </TableCell>
                        <TableCell sx={{ px: 1 }}>
                          <TextField
                            onChange={(evnt) =>
                              handleChangeRootCanal(index, evnt)
                            }
                            value={masterLime}
                            name="masterLime"
                            size="small"
                          />
                        </TableCell>
                        <TableCell sx={{ px: 1 }}>
                          <TextField
                            onChange={(evnt) =>
                              handleChangeRootCanal(index, evnt)
                            }
                            value={principalCone}
                            name="principalCone"
                            size="small"
                          />
                        </TableCell>
                        <TableCell sx={{ px: 0 }}>
                          <Tooltip title="Eliminar">
                            <IconButton
                              color="error"
                              aria-label="eliminar conductoterapia"
                              onClick={removeRootCanalTheraphy}
                            >
                              <HighlightOffIcon />
                            </IconButton>
                          </Tooltip>
                        </TableCell>
                      </TableRow>
                    )
                  })}
                </TableBody>
              </Table>
            </TableContainer>
            <Divider sx={{ my: 4 }} />
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Stack
                  direction="row"
                  spacing={1}
                  justifyContent="flex-start"
                  alignItems="center"
                >
                  <Typography variant="subtitle2" color="textSecondary">
                    Citas
                  </Typography>
                  <Tooltip title="Agregar">
                    <IconButton
                      color="primary"
                      aria-label="agregar cita"
                      onClick={addComment}
                    >
                      <AddCircleOutlineIcon />
                    </IconButton>
                  </Tooltip>
                </Stack>
              </Grid>
            </Grid>
            {comments.map((comment, index) => {
              // const { comment } = data
              return (
                <Grid
                  container
                  key={index}
                  spacing={2}
                  justifyContent="flex-start"
                  alignItems="center"
                  sx={{ my: 1 }}
                >
                  <Grid item xs={10}>
                    <TextField
                      onChange={(evnt) => handleChangeComment(index, evnt)}
                      value={comment}
                      name="comment"
                      label={`Cita ${index + 1}`}
                      variant="outlined"
                      fullWidth
                    />
                  </Grid>
                  <Grid item xs="auto">
                    <Tooltip title="Eliminar">
                      <IconButton
                        color="error"
                        aria-label="eliminar cita"
                        onClick={removeComment}
                      >
                        <HighlightOffIcon />
                      </IconButton>
                    </Tooltip>
                  </Grid>
                </Grid>
              )
            })}
            <Divider sx={{ my: 4 }} />
            <Grid container spacing={2} sx={{ mb: 4 }}>
              <Grid item xs={12}>
                <Stack direction="column" spacing={1}>
                  <Stack
                    direction="row"
                    justifyContent="space-between"
                    alignItems="center"
                  >
                    <Typography variant="subtitle2" color="textSecondary">
                      Costos
                    </Typography>
                    <Stack
                      direction="row"
                      justifyContent="flex-end"
                      alignItems="center"
                    >
                      <IconButton onClick={handleOpenCostMenu}>
                        <MoreVertIcon />
                      </IconButton>
                      <Menu
                        sx={{ mt: '45px' }}
                        id="menu-cost"
                        anchorEl={anchorElCost}
                        anchorOrigin={{
                          vertical: 'top',
                          horizontal: 'right',
                        }}
                        keepMounted
                        transformOrigin={{
                          vertical: 'top',
                          horizontal: 'right',
                        }}
                        open={Boolean(anchorElCost)}
                        onClose={handleCloseCostMenu}
                      >
                        <MenuItem onClick={handleShowCostPost}>
                          {showCostPost && (
                            <ListItemIcon>
                              <Check />
                            </ListItemIcon>
                          )}
                          Costo Poste
                        </MenuItem>
                        <MenuItem onClick={handleShowCostResin}>
                          {showCostResin && (
                            <ListItemIcon>
                              <Check />
                            </ListItemIcon>
                          )}
                          Costo Resina
                        </MenuItem>
                      </Menu>
                    </Stack>
                  </Stack>
                </Stack>
              </Grid>
              <Grid item xs={12} md={4}>
                <TextField
                  label="Costo Tx"
                  placeholder="0"
                  type="number"
                  variant="outlined"
                  fullWidth
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">$</InputAdornment>
                    ),
                  }}
                  value={costTx}
                  onChange={handleCostTxChange}
                  onFocus={(e) => {
                    handleFocus(e, setCostTx)
                  }}
                />
              </Grid>
              {showCostPost && (
                <Grid item xs={12} md={4}>
                  <TextField
                    label="Costo Poste"
                    placeholder="0"
                    type="number"
                    variant="outlined"
                    fullWidth
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">$</InputAdornment>
                      ),
                    }}
                    value={costPost}
                    onChange={handleCostPostChange}
                    onFocus={(e) => {
                      handleFocus(e, setCostPost)
                    }}
                  />
                </Grid>
              )}
              {showCostResin && (
                <Grid item xs={12} md={4}>
                  <TextField
                    label="Costo Resina"
                    placeholder="0"
                    type="number"
                    variant="outlined"
                    fullWidth
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">$</InputAdornment>
                      ),
                    }}
                    value={costResin}
                    onChange={handleCostResinChange}
                    onFocus={(e) => {
                      handleFocus(e, setCostResin)
                    }}
                  />
                </Grid>
              )}
              <Grid item xs={12} md={4}>
                <TextField
                  id="costo-total"
                  name="total"
                  label="Costo Total"
                  placeholder="0"
                  variant="outlined"
                  type="number"
                  color="warning"
                  fullWidth
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">$</InputAdornment>
                    ),
                  }}
                  value={total}
                  onChange={handleTotalChange}
                  onFocus={(e) => {
                    handleFocus(e, setTotal)
                  }}
                />
              </Grid>
            </Grid>
            <Stack direction="row" spacing={1} sx={{ py: 2 }}>
              <Box sx={{ position: 'relative' }}>
                <Button
                  type="submit"
                  variant="contained"
                  color="primary"
                  size="small"
                  startIcon={<SaveIcon />}
                  disabled={isLoading}
                >
                  Guardar cambios
                </Button>
                {isLoading && (
                  <CircularProgress
                    size={24}
                    sx={{
                      position: 'absolute',
                      top: '50%',
                      left: '50%',
                      marginTop: '-12px',
                      marginLeft: '-12px',
                    }}
                  />
                )}
              </Box>
              <Button
                size="small"
                startIcon={<ClearIcon />}
                onClick={() =>
                  navigate(`/dashboard/patients/${id}/appointments`)
                }
              >
                Cancelar
              </Button>
            </Stack>
          </Box>
        </Paper>
      </Grid>
    </Grid>
  )
}

export default AppointmentEdit
