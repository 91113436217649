import Container from '@mui/material/Container'
import Paper from '@mui/material/Paper'
import Box from '@mui/material/Box'
import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography'
import Chip from '@mui/material/Chip'
// import { teal } from '@mui/material/colors'

function About() {
  return (
    // <Box sx={{ display: 'flex', backgroundColor: teal[50] }}>
    <Container component="section" sx={{ my: 12 }}>
      <Paper elevation={0}>
        <Grid
          container
          direction="row"
          justifyContent="center"
          alignItems="center"
          // sx={{ m: 2 }}
        >
          <Grid item xs={12} md={6} sx={{ p: 2 }}>
            <Box>
              <Typography
                component="h1"
                variant="h4"
                color="inherit"
                sx={{ fontWeight: 'bold' }}
              >
                {'Dr. Diego Martín Pantoja'}
              </Typography>
              <Chip
                color="primary"
                label="Especialista en Endodoncia"
                sx={{ mt: 1, mb: 4, fontWeight: 'bold' }}
              />
              <Typography
                variant="body1"
                color="inherit"
                align="justify"
                paragraph
              >
                Lorem Ipsum is simply dummy text of the printing and typesetting
                industry. Lorem Ipsum has been the industry's standard dummy
                text ever since the 1500s, when an unknown printer took a galley
                of type and scrambled it to make a type specimen book.
              </Typography>
              <Typography
                variant="body1"
                color="inherit"
                align="justify"
                paragraph
              >
                It has survived not only five centuries, but also the leap into
                electronic typesetting, remaining essentially unchanged. It was
                popularised in the 1960s with the release of Letraset sheets
                containing Lorem Ipsum passages, and more recently with desktop
                publishing software like Aldus PageMaker including versions of
                Lorem Ipsum.
              </Typography>
            </Box>
          </Grid>
          <Grid
            item
            xs={12}
            md={6}
            sx={{
              position: 'relative',
              height: 500,
              backgroundSize: 'contain',
              backgroundRepeat: 'no-repeat',
              backgroundPosition: 'center',
              backgroundImage: `url(/static/img/home/about/about-01.webp)`,
            }}
          >
            {/* <Box
              component="img"
              src="https://scontent-qro1-1.xx.fbcdn.net/v/t39.30808-6/293774457_5279515988836311_6965893798258780588_n.jpg?_nc_cat=108&ccb=1-7&_nc_sid=730e14&_nc_ohc=9tVEy9UXRVkAX8x8wq4&_nc_ht=scontent-qro1-1.xx&oh=00_AfA37bStHJ-apXeiAujUfugO2rMyQfTh5GKS6e1JSSUikg&oe=64C9FD0A"
              alt="About"
              sx={{
                display: 'none',
                // p: 2,
              }}
            /> */}
            {/* <img
              sx={{ p: 2 }}
              style={{ display: 'none' }}
              alt="The house from the offer."
              src="https://scontent-qro1-1.xx.fbcdn.net/v/t39.30808-6/293774457_5279515988836311_6965893798258780588_n.jpg?_nc_cat=108&ccb=1-7&_nc_sid=730e14&_nc_ohc=9tVEy9UXRVkAX8x8wq4&_nc_ht=scontent-qro1-1.xx&oh=00_AfA37bStHJ-apXeiAujUfugO2rMyQfTh5GKS6e1JSSUikg&oe=64C9FD0A"
            /> */}
          </Grid>
        </Grid>
      </Paper>
    </Container>
    // </Box>
  )
}

export default About
