import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import moment from 'moment'

import Grid from '@mui/material/Grid'

import PaymentsCard from './PaymentsCard'
import ExpensesCard from './ExpensesCard'
import BalanceCard from './BalanceCard'
import { getAllPayments } from '../../../features/payments/paymentsSlice'
import { getExpenses } from '../../../features/expenses/ExpenseSlice'

const Balance = ({ dateRange }) => {
  const dispatch = useDispatch()

  const { payments } = useSelector((state) => state.payments)
  const [filteredPayments, setFilteredPayments] = useState([])
  const [totalPayments, setTotalPayments] = useState(0)

  const { expenses } = useSelector((state) => state.expenses)
  const [filteredExpenses, setFilteredExpenses] = useState([])
  const [totalExpenses, setTotalExpenses] = useState(0)

  const [totalBalance, setTotalBalance] = useState(0)

  useEffect(() => {
    dispatch(getAllPayments())
    dispatch(getExpenses())
  }, [dispatch])

  useEffect(() => {
    if (payments) {
      // Set filtered payments
      const filtered = payments
        .filter((item) => {
          const paymentDate = moment(item.createdAt).format('YYYY-MM-DD')
          return (
            moment(paymentDate).isSameOrAfter(dateRange[0]) &&
            moment(paymentDate).isSameOrBefore(dateRange[1])
          )
        })
        .sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt))
      setFilteredPayments(filtered)
    }
    if (expenses) {
      // Set filtered expenses
      const filtered = expenses
        .filter((item) => {
          const expenseDate = moment(item.createdAt).format('YYYY-MM-DD')
          return (
            moment(expenseDate).isSameOrAfter(dateRange[0]) &&
            moment(expenseDate).isSameOrBefore(dateRange[1])
          )
        })
        .sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt))
      setFilteredExpenses(filtered)
    }
    if (!dateRange[0] && !dateRange[1]) {
      setFilteredPayments(payments)
      setFilteredExpenses(expenses)
    }
  }, [payments, expenses, dateRange])

  useEffect(() => {
    if (filteredPayments) {
      const sum = filteredPayments
        .map((item) => item.amount)
        .reduce((a, b) => a + b, 0)
      setTotalPayments(sum)
    }
    if (filteredExpenses) {
      const sum = filteredExpenses
        .map((item) => item.amount)
        .reduce((a, b) => a + b, 0)
      setTotalExpenses(sum)
    }
  }, [filteredPayments, filteredExpenses])

  useEffect(() => {
    // Set the balance total
    setTotalBalance(Number(totalPayments) - Number(totalExpenses))
  }, [totalPayments, totalExpenses])

  return (
    <Grid container spacing={2} sx={{ mt: 1 }}>
      <Grid item xs={12} sm={4} md={4}>
        <PaymentsCard total={totalPayments} />
      </Grid>
      <Grid item xs={12} sm={4} md={4}>
        <ExpensesCard total={totalExpenses} />
      </Grid>
      <Grid item xs={12} sm={4} md={4}>
        <BalanceCard total={totalBalance} />
      </Grid>
    </Grid>
  )
}

export default Balance
