// import { useLocation, useNavigate } from 'react-router-dom'
// import Button from '@mui/material/Button'
// import AddIcon from '@mui/icons-material/Add'
import AppointmentsList from './AppointmentsList'

function Appointments() {
  // const location = useLocation()
  // const navigate = useNavigate()

  // const handleNewAppointment = () => {
  //   navigate(`${location.pathname}/new`)
  // }

  return (
    <>
      {/* <Button
        variant="contained"
        color="primary"
        size="small"
        disabled={false}
        sx={{
          mt: 1,
          mb: 3,
        }}
        startIcon={<AddIcon />}
        onClick={() => handleNewAppointment()}
      >
        Agregar Cita
      </Button> */}
      <AppointmentsList />
    </>
  )
}

export default Appointments
