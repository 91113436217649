import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import appointmentsService from './AppointmentsService'

const initialState = {
  appointment: null,
  dentalOrgan: [],
  isError: false,
  isSuccess: false,
  isLoading: false,
  message: '',
}

// Create new appointment
export const createAppointment = createAsyncThunk(
  'appointments/create',
  async (data, thunkAPI) => {
    try {
      const token = thunkAPI.getState().auth.user.token
      return await appointmentsService.createAppointment(data, token)
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString()

      return thunkAPI.rejectWithValue(message)
    }
  }
)

// Get appointment
export const getAppointment = createAsyncThunk(
  'appointments/get',
  async (appointmentId, thunkAPI) => {
    try {
      const token = thunkAPI.getState().auth.user.token
      return await appointmentsService.getAppointment(appointmentId, token)
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString()

      return thunkAPI.rejectWithValue(message)
    }
  }
)

// Update appointment
export const updateAppointment = createAsyncThunk(
  'appointments/update',
  async (data, thunkAPI) => {
    try {
      const token = thunkAPI.getState().auth.user.token
      return await appointmentsService.updateAppointment(data, token)
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString()

      return thunkAPI.rejectWithValue(message)
    }
  }
)

// Delete appointment
export const deleteAppointment = createAsyncThunk(
  'appointments/delete',
  async (appointmentId, thunkAPI) => {
    try {
      const token = thunkAPI.getState().auth.user.token
      return await appointmentsService.deleteAppointment(appointmentId, token)
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString()

      return thunkAPI.rejectWithValue(message)
    }
  }
)

const appointmentsSlice = createSlice({
  name: 'appointments',
  initialState,
  reducers: {
    reset: (state) => initialState,
    resetError: (state) => {
      state.isError = false
      state.message = ''
    },
    updateDentalOrgan(state, action) {
      state.dentalOrgan = action.payload
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(createAppointment.pending, (state) => {
        state.isLoading = true
      })
      .addCase(createAppointment.fulfilled, (state, action) => {
        state.isLoading = false
        state.isSuccess = true
      })
      .addCase(createAppointment.rejected, (state, action) => {
        state.isLoading = false
        state.isError = true
        state.message = action.payload
      })
      .addCase(getAppointment.pending, (state) => {
        state.isLoading = true
      })
      .addCase(getAppointment.fulfilled, (state, action) => {
        state.isLoading = false
        // state.isSuccess = true
        state.appointment = action.payload
      })
      .addCase(getAppointment.rejected, (state, action) => {
        state.isLoading = false
        state.isError = true
        state.message = action.payload
      })
      .addCase(updateAppointment.pending, (state) => {
        state.isLoading = true
      })
      .addCase(updateAppointment.fulfilled, (state, action) => {
        state.isLoading = false
        state.isSuccess = true
      })
      .addCase(updateAppointment.rejected, (state, action) => {
        state.isLoading = false
        state.isError = true
        state.message = action.payload
      })
      .addCase(deleteAppointment.pending, (state) => {
        state.isLoading = true
      })
      .addCase(deleteAppointment.fulfilled, (state, action) => {
        state.isSuccess = true
        state.isLoading = false
        state.message = action.payload
      })
      .addCase(deleteAppointment.rejected, (state, action) => {
        state.isError = true
        state.isLoading = false
        state.message = action.payload
      })
  },
})

export const { reset, resetError, updateDentalOrgan } =
  appointmentsSlice.actions
export default appointmentsSlice.reducer
