import { useDispatch } from 'react-redux'
import { useLocation, useNavigate } from 'react-router-dom'
import Stack from '@mui/material/Stack'
import Chip from '@mui/material/Chip'
import Grid from '@mui/material/Grid'
import { styled } from '@mui/material/styles'
import { updateDentalOrgan } from '../../../../../features/appointments/AppointmentsSlice'

const teeth1 = [18, 17, 16, 15, 14, 13, 12, 11]
const teeth2 = [21, 22, 23, 24, 25, 26, 27, 28]
const teeth3 = [48, 47, 46, 45, 44, 43, 42, 41]
const teeth4 = [31, 32, 33, 34, 35, 36, 37, 38]

const Tooth = styled(Chip)(({ theme }) => ({
  ...theme.typography.caption,
}))

const GeneralOdontogram = ({ treatedDentalOrgans }) => {
  const dispatch = useDispatch()
  const location = useLocation()
  const navigate = useNavigate()

  const handleClick = (v) => {
    dispatch(updateDentalOrgan([v]))
    navigate(`${location.pathname}/appointments/new`)
  }

  const setColorActive = (v) => {
    if (treatedDentalOrgans.includes(v)) return 'primary'
  }

  return (
    <Grid container>
      <Grid item xs={12} sm={6} sx={{ p: 1 }}>
        <Stack
          direction="row"
          justifyContent={{ xs: 'center', sm: 'flex-end' }}
          alignItems="center"
          spacing={1}
        >
          {teeth1.map((t) => (
            <Tooth
              key={t}
              label={t}
              size="small"
              color={setColorActive(t)}
              onClick={() => handleClick(t)}
            />
          ))}
        </Stack>
      </Grid>
      <Grid item xs={12} sm={6} sx={{ p: 1 }}>
        <Stack
          direction="row"
          justifyContent={{ xs: 'center', sm: 'flex-start' }}
          alignItems="center"
          spacing={1}
        >
          {teeth2.map((t) => (
            <Tooth
              key={t}
              label={t}
              size="small"
              color={setColorActive(t)}
              onClick={() => handleClick(t)}
            />
          ))}
        </Stack>
      </Grid>
      <Grid item xs={12} sm={6} sx={{ p: 1 }}>
        <Stack
          direction="row"
          justifyContent={{ xs: 'center', sm: 'flex-end' }}
          alignItems="center"
          spacing={1}
        >
          {teeth3.map((t) => (
            <Tooth
              key={t}
              label={t}
              size="small"
              color={setColorActive(t)}
              onClick={() => handleClick(t)}
            />
          ))}
        </Stack>
      </Grid>
      <Grid item xs={12} sm={6} sx={{ p: 1 }}>
        <Stack
          direction="row"
          justifyContent={{ xs: 'center', sm: 'flex-start' }}
          alignItems="center"
          spacing={1}
        >
          {teeth4.map((t) => (
            <Tooth
              key={t}
              label={t}
              size="small"
              color={setColorActive(t)}
              onClick={() => handleClick(t)}
            />
          ))}
        </Stack>
      </Grid>
    </Grid>
  )
}

export default GeneralOdontogram
