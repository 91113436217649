import FormControl from '@mui/material/FormControl'
import InputLabel from '@mui/material/InputLabel'
import Select from '@mui/material/Select'
import MenuItem from '@mui/material/MenuItem'
import FormHelperText from '@mui/material/FormHelperText'

const ITEM_HEIGHT = 48
const ITEM_PADDING_TOP = 8
const menuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
}

export const MuiSelect = ({ field, error, options, ...props }) => {
  const { id, label, size, fullWidth, multiple } = props
  return (
    <FormControl size={size} fullWidth={fullWidth} error={!!error}>
      <InputLabel id={id}>{label}</InputLabel>
      <Select
        {...field}
        labelId={id}
        label={label}
        multiple={multiple}
        MenuProps={menuProps}
      >
        {options.map((option, index) => (
          <MenuItem key={index} value={option.value}>
            {option.name}
          </MenuItem>
        ))}
      </Select>
      {error && <FormHelperText>{error.message}</FormHelperText>}
    </FormControl>
  )
}

export default MuiSelect
