import TextField from '@mui/material/TextField'

const Input = ({ field, error, ...props }) => {
  return (
    <TextField
      {...field}
      {...props}
      error={!!error}
      helperText={error && error.message}
      InputLabelProps={{
        shrink: true,
      }}
    />
  )
}

export default Input
