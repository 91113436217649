import { Link as RouterLink } from 'react-router-dom'
import Box from '@mui/material/Box'
import Grid from '@mui/material/Grid'
import Link from '@mui/material/Link'
import Container from '@mui/material/Container'
import Typography from '@mui/material/Typography'
import Stack from '@mui/material/Stack'
import Avatar from '@mui/material/Avatar'
import { blue, green, blueGrey } from '@mui/material/colors'
import FacebookIcon from '@mui/icons-material/Facebook'
import WhatsAppIcon from '@mui/icons-material/WhatsApp'
import LocalPhoneIcon from '@mui/icons-material/LocalPhone'

function Copyright() {
  return (
    <>
      {'© '}
      <Link color="inherit" href="https://www.endodonciaenmazatlan.com/">
        Dr. Diego Martín Pantoja
      </Link>{' '}
      {new Date().getFullYear()}
    </>
  )
}

export default function Footer() {
  return (
    <>
      <Typography
        component="footer"
        sx={{
          display: 'flex',
          backgroundColor: (theme) => theme.palette.grey[200],
        }}
      >
        <Container sx={{ my: 8, display: 'flex' }}>
          <Grid container spacing={5}>
            <Grid item xs={12} sm={12} md={4}>
              <Typography variant="h6" marked="left" gutterBottom>
                Contacto
              </Typography>
              <Box component="ul" sx={{ m: 0, listStyle: 'none', p: 0 }}>
                <Box component="li" sx={{ py: 0.5 }}>
                  <Link color="inherit" href="https://wa.me/6694310008">
                    <Stack direction="row" spacing={1}>
                      <Avatar
                        sx={{ width: 30, height: 30, bgcolor: green.A700 }}
                        variant="rounded"
                      >
                        <WhatsAppIcon />
                      </Avatar>
                      <Typography variant="subtitle1" marked="left">
                        (669) 431-00-08
                      </Typography>
                    </Stack>
                  </Link>
                </Box>
                <Box component="li" sx={{ py: 0.5 }}>
                  <Link color="inherit" href="tel:6691771462">
                    <Stack direction="row" spacing={1}>
                      <Avatar
                        sx={{ width: 30, height: 30, bgcolor: blueGrey[700] }}
                        variant="rounded"
                      >
                        <LocalPhoneIcon />
                      </Avatar>
                      <Typography variant="subtitle1" marked="left">
                        (669) 177-14-62
                      </Typography>
                    </Stack>
                  </Link>
                </Box>
                <Box component="li" sx={{ py: 0.5 }}>
                  <Link
                    color="inherit"
                    href="https://www.facebook.com/dr.diego.endo/"
                  >
                    <Stack direction="row" spacing={1}>
                      <Avatar
                        sx={{ width: 30, height: 30, bgcolor: blue[800] }}
                        variant="rounded"
                      >
                        <FacebookIcon />
                      </Avatar>
                      <Typography variant="subtitle1" marked="left">
                        @dr.diego.endo
                      </Typography>
                    </Stack>
                  </Link>
                </Box>
              </Box>
            </Grid>
            <Grid item xs={12} sm={12} md={4}>
              <Typography variant="h6" marked="left" gutterBottom>
                Ubicación
              </Typography>
              <Box>
                <Typography variant="body2" marked="left" gutterBottom>
                  Av. Reforma No. 2007, Flamingos
                </Typography>
                <Typography variant="body2" marked="left" gutterBottom>
                  Plaza Laguna, Consultorio 509, 5to Piso
                </Typography>
                <Typography variant="body2" marked="left" gutterBottom>
                  82149 Mazatlán, Sin.
                </Typography>
              </Box>
            </Grid>
            <Grid item xs={12} sm={12} md={4}>
              <Typography variant="h6" marked="left" gutterBottom>
                Sitio
              </Typography>
              <Box component="ul" sx={{ m: 0, listStyle: 'none', p: 0 }}>
                <Box component="li" sx={{ py: 0.5 }}>
                  <Link component={RouterLink} to="/" color="inherit">
                    Inicio
                  </Link>
                </Box>
                <Box component="li" sx={{ py: 0.5 }}>
                  <Link component={RouterLink} to="/services" color="inherit">
                    Servicios
                  </Link>
                </Box>
                <Box component="li" sx={{ py: 0.5 }}>
                  <Link component={RouterLink} to="/contact" color="inherit">
                    Contacto
                  </Link>
                </Box>
                <Box component="li" sx={{ py: 0.5 }}>
                  <Link component={RouterLink} to="/dashboard" color="inherit">
                    Dashboard
                  </Link>
                </Box>
              </Box>
            </Grid>
            <Grid item xs={12}>
              <Copyright />
            </Grid>
          </Grid>
        </Container>
      </Typography>
      <Typography
        component="footer"
        sx={{
          display: 'flex',
          backgroundColor: (theme) => theme.palette.grey[300],
        }}
      >
        <Container sx={{ my: 2, display: 'flex' }}>
          <Grid container spacing={1}>
            {/* <Grid xs={12} item>
            <Typography variant="caption">
              {'Desarrollado por '}
              <Link
                href="https://myurl.com/"
                title="My Website"
                target="_blank"
                rel="noopener noreferrer"
              >
                Julio Lopez
              </Link>
            </Typography>
          </Grid> */}
            <Grid xs={12} item>
              <Typography variant="caption">
                {'Icons made by '}
                <Link
                  href="https://www.freepik.com"
                  rel="sponsored"
                  title="Freepik"
                  target="_blank"
                >
                  Freepik
                </Link>
                {' from '}
                <Link
                  href="https://www.flaticon.com"
                  rel="sponsored"
                  title="Flaticon"
                  target="_blank"
                >
                  www.flaticon.com
                </Link>
                {' is licensed by '}
                <Link
                  href="https://creativecommons.org/licenses/by/3.0/"
                  title="Creative Commons BY 3.0"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  CC 3.0 BY
                </Link>
              </Typography>
            </Grid>
          </Grid>
        </Container>
      </Typography>
    </>
  )
}
