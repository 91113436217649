import { Link as RouterLink } from 'react-router-dom'
import Carousel from 'react-material-ui-carousel'
import Paper from '@mui/material/Paper'
import Box from '@mui/material/Box'
import Grid from '@mui/material/Grid'
import Stack from '@mui/material/Stack'
import Typography from '@mui/material/Typography'
import Chip from '@mui/material/Chip'
// import Link from '@mui/material/Link'
// import Avatar from '@mui/material/Avatar'
// import WhatsAppIcon from '@mui/icons-material/WhatsApp'
// import LocalPhoneIcon from '@mui/icons-material/LocalPhone'
import Button from '@mui/material/Button'
// import { green, blueGrey } from '@mui/material/colors'

const CarouselLanding = () => {
  const items = [
    {
      name: 'Dr. Diego Martín Pantoja',
      description: 'Especialista en Endodoncia',
      img: `/static/img/home/carousel/landing-01.webp`,
    },
    {
      name: 'Dr. Diego Martín Pantoja',
      description: 'Especialista en Endodoncia',
      img: `/static/img/home/carousel/landing-02.webp`,
    },
    {
      name: 'Dr. Diego Martín Pantoja',
      description: 'Especialista en Endodoncia',
      img: `/static/img/home/carousel/landing-03.webp`,
    },
  ]

  return (
    <Carousel
      indicators={false}
      navButtonsAlwaysInvisible={true}
      stopAutoPlayOnHover={false}
      swipe={false}
      duration={900}
      interval={6000}
      className="custom-carousel"
    >
      {items.map((item, i) => (
        <CarouselItem key={i} item={item} />
      ))}
    </Carousel>
  )
}

const CarouselItem = (props) => {
  return (
    <Paper
      sx={{
        position: 'relative',
        backgroundColor: 'grey.800',
        color: '#fff',
        height: '100vh',
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center',
        backgroundImage: `url(${props.item.img})`,
      }}
    >
      {
        <img
          style={{ display: 'none' }}
          src={props.item.img}
          alt={props.item.name}
        />
      }
      <Box
        sx={{
          position: 'absolute',
          top: 0,
          bottom: 0,
          right: 0,
          left: 0,
          backgroundColor: 'rgba(0,0,0,.4)',
        }}
      />
      <Grid container>
        <Grid item xs={12}>
          <Box
            sx={{
              position: 'relative',
              p: 6,
              height: '100vh',
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              justifyContent: 'end',
            }}
          >
            <Typography
              component="h1"
              variant="h3"
              color="inherit"
              gutterBottom
              sx={{ fontWeight: 'bold' }}
            >
              {props.item.name}
            </Typography>
            {/* <Typography variant="h6" color="inherit" paragraph>
              {props.item.description}
            </Typography> */}
            <Chip
              label={props.item.description}
              variant="outlined"
              sx={{ color: '#fff', fontWeight: 'bold' }}
            />
            {/* <Stack direction="row" spacing={2} sx={{ pt: 8 }}>
              <Link color="inherit" href="https://wa.me/6691771462">
                <Avatar sx={{ bgcolor: green.A700, width: 56, height: 56 }}>
                  <WhatsAppIcon fontSize="large" />
                </Avatar>
              </Link>
              <Link color="inherit" href="tel:6691771462">
                <Avatar sx={{ bgcolor: blueGrey[700], width: 56, height: 56 }}>
                  <LocalPhoneIcon fontSize="large" />
                </Avatar>
              </Link>
            </Stack>
            <Typography
              variant="button"
              color="inherit"
              paragraph
              sx={{ py: 2 }}
            >
              {'Agenda tu Cita'}
            </Typography> */}
            <Stack direction="row" spacing={2} sx={{ p: 2 }}>
              <Button variant="contained" component={RouterLink} to="/contact" sx={{ my: 4 }}>
                Agenda tu Cita
              </Button>
            </Stack>
          </Box>
        </Grid>
      </Grid>
    </Paper>
  )
}

function CarouselHome() {
  return <CarouselLanding />
}

export default CarouselHome
