import Container from '@mui/material/Container'
import Paper from '@mui/material/Paper'
import Box from '@mui/material/Box'
import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography'

const item = {
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  px: 5,
}

function Services() {
  return (
    <Container component="section" sx={{ my: 12 }}>
      <Paper elevation={0}>
        <Grid
          container
          direction="row"
          justifyContent="center"
          alignItems="center"
          spacing={4}
        >
          <Grid item xs={12} md={4}>
            <Box sx={item}>
              <Box
                component="img"
                src="/static/caries.png"
                alt="graph"
                sx={{ height: 80 }}
              />
              <Typography variant="h4" color="inherit" sx={{ my: 2 }}>
                {'Caries'}
              </Typography>
              <Typography
                variant="body2"
                color="secondary"
                align="center"
                paragraph
                sx={{ color: (theme) => theme.palette.text.secondary }}
              >
                Lorem Ipsum is simply dummy text of the printing and typesetting
                industry.
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={12} md={4}>
            <Box sx={item}>
              <Box
                component="img"
                src="/static/broken-tooth.png"
                alt="graph"
                sx={{ height: 80 }}
              />
              <Typography variant="h4" color="inherit" sx={{ my: 2 }}>
                {'Sensibilidad'}
              </Typography>
              <Typography
                variant="body2"
                color="secondary"
                align="center"
                paragraph
                sx={{ color: (theme) => theme.palette.text.secondary }}
              >
                Lorem Ipsum is simply dummy text of the printing and typesetting
                industry.
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={12} md={4}>
            <Box sx={item}>
              <Box
                component="img"
                src="/static/toothache.png"
                alt="graph"
                sx={{ height: 80 }}
              />
              <Typography variant="h4" color="inherit" sx={{ my: 2 }}>
                {'Dolor'}
              </Typography>
              <Typography
                variant="body2"
                color="secondary"
                align="center"
                paragraph
                sx={{ color: (theme) => theme.palette.text.secondary }}
              >
                Lorem Ipsum is simply dummy text of the printing and typesetting
                industry.
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={12} md={4}>
            <Box sx={item}>
              <Box
                component="img"
                src="/static/tooth-drill.png"
                alt="graph"
                sx={{ height: 80 }}
              />
              <Typography variant="h4" color="inherit" sx={{ my: 2 }}>
                {'Endodoncia'}
              </Typography>
              <Typography
                variant="body2"
                color="secondary"
                align="center"
                paragraph
                sx={{ color: (theme) => theme.palette.text.secondary }}
              >
                Lorem Ipsum is simply dummy text of the printing and typesetting
                industry.
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={12} md={4}>
            <Box sx={item}>
              <Box
                component="img"
                src="/static/clean-tooth.png"
                alt="graph"
                sx={{ height: 80 }}
              />
              <Typography variant="h4" color="inherit" sx={{ my: 2 }}>
                {'Blanqueamiento'}
              </Typography>
              <Typography
                variant="body2"
                color="secondary"
                align="center"
                paragraph
                sx={{ color: (theme) => theme.palette.text.secondary }}
              >
                Lorem Ipsum is simply dummy text of the printing and typesetting
                industry.
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={12} md={4}>
            <Box sx={item}>
              <Box
                component="img"
                src="/static/tooth-extraction.png"
                alt="graph"
                sx={{ height: 80 }}
              />
              <Typography variant="h4" color="inherit" sx={{ my: 2 }}>
                {'Extracción'}
              </Typography>
              <Typography
                variant="body2"
                color="secondary"
                align="center"
                paragraph
                sx={{ color: (theme) => theme.palette.text.secondary }}
              >
                Lorem Ipsum is simply dummy text of the printing and typesetting
                industry.
              </Typography>
            </Box>
          </Grid>
        </Grid>
      </Paper>
    </Container>
  )
}

export default Services
