import React from 'react'
import { useSelector } from 'react-redux'
import { useLocation, useNavigate } from 'react-router-dom'
import Box from '@mui/material/Box'
import List from '@mui/material/List'
import ListItem from '@mui/material/ListItem'
import ListItemText from '@mui/material/ListItemText'
import Paper from '@mui/material/Paper'
import Typography from '@mui/material/Typography'
import Stack from '@mui/material/Stack'
import Chip from '@mui/material/Chip'
import Divider from '@mui/material/Divider'
import Grid from '@mui/material/Grid'
import ListItemButton from '@mui/material/ListItemButton'
import moment from 'moment'
import NoItemsImg from '../../../common/NoItemsImg'

function AppointmentsList() {
  const location = useLocation()
  const navigate = useNavigate()

  const { patient } = useSelector((state) => state.patients)

  const handleViewAppointment = (id) => {
    navigate(`${location.pathname}/${id}`)
  }

  if (patient.appointments.length <= 0)
    return (
      <Paper variant="outlined" sx={{ my: 2, p: 2, minHeight: 300 }}>
        <NoItemsImg />
      </Paper>
    )

  return (
    <>
      {patient.appointments && (
        <Box sx={{ width: '100%', bgcolor: 'background.paper' }}>
          {patient.appointments.length > 0 && (
            <Typography variant="h6" gutterBottom component="div">
              Total ({patient.appointments ? patient.appointments.length : 0})
            </Typography>
          )}
          <Paper variant="outlined" sx={{ my: 2 }}>
            <Box sx={{ width: '100%', bgcolor: 'background.paper' }}>
              <nav aria-label="appointments list">
                <List sx={{ p: 0 }}>
                  {patient.appointments
                    .slice(0)
                    .sort((t1, t2) =>
                      t1.createdAt < t2.createdAt
                        ? 1
                        : t1.createdAt > t2.createdAt
                        ? -1
                        : 0
                    )
                    .map((appointment, i) => (
                      <div key={appointment._id}>
                        <ListItem disablePadding>
                          <ListItemButton
                            onClick={() =>
                              handleViewAppointment(appointment._id)
                            }
                          >
                            <ListItemText
                              primary={
                                <Grid
                                  container
                                  justifyContent="space-between"
                                  alignItems="center"
                                  spacing={0}
                                >
                                  <Grid item xs={12} sm={9}>
                                    <Stack
                                      direction="row"
                                      alignItems="center"
                                      spacing={1}
                                      sx={{ mb: 1 }}
                                    >
                                      <Typography>Órgano Dental:</Typography>
                                      {appointment.dentalOrgan.map((t) => (
                                        <Chip key={t} label={t} size="small" />
                                      ))}
                                    </Stack>
                                    {appointment.comments.map(
                                      (comment, index) => (
                                        <Stack
                                          key={index}
                                          direction="row"
                                          alignItems="center"
                                          spacing={1}
                                        >
                                          <Typography
                                            variant="body2"
                                            color="textSecondary"
                                            sx={{ fontWeight: 'medium' }}
                                          >
                                            {`Cita ${index + 1}:`}
                                          </Typography>
                                          <Typography
                                            variant="body2"
                                            color="textSecondary"
                                          >
                                            {comment ? comment : `-`}
                                          </Typography>
                                        </Stack>
                                      )
                                    )}
                                  </Grid>
                                  <Grid item xs={12} sm={3}>
                                    <Stack
                                      direction={{ xs: 'row', sm: 'column' }}
                                      justifyContent={{
                                        xs: 'flex-start',
                                        sm: 'flex-end',
                                      }}
                                      alignItems="center"
                                      spacing={0}
                                      sx={{ mt: 1 }}
                                    >
                                      <Typography
                                        variant="caption"
                                        color="textSecondary"
                                      >
                                        {moment(appointment.createdAt).format(
                                          'dddd hh:mm a'
                                        )}
                                      </Typography>
                                      <Typography
                                        variant="caption"
                                        color="textSecondary"
                                      >
                                        {moment(appointment.createdAt).format(
                                          'DD MMM YYYY'
                                        )}
                                      </Typography>
                                    </Stack>
                                  </Grid>
                                </Grid>
                              }
                            />
                          </ListItemButton>
                        </ListItem>
                        {patient.appointments.length - i !== 1 && <Divider />}
                      </div>
                    ))}
                </List>
              </nav>
            </Box>
          </Paper>
        </Box>
      )}
    </>
  )
}

export default AppointmentsList
