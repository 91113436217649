import axios from 'axios'

const API_URL = '/api/v1/appointments/'

// Create new appointment
const createAppointment = async (data, token) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  }

  const response = await axios.post(`${API_URL}`, data, config)

  return response.data
}

// Get appointment
const getAppointment = async (id, token) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  }

  const response = await axios.get(`${API_URL}${id}`, config)

  return response.data
}

// Update appointment
const updateAppointment = async (appointmentData, token) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  }

  const { id, data } = appointmentData
  const response = await axios.put(`${API_URL}${id}`, data, config)

  return response.data
}

// Delete appointment
const deleteAppointment = async (id, token) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  }

  const response = await axios.delete(`${API_URL}${id}`, config)

  return response.data
}

const appointmentsService = {
  createAppointment,
  getAppointment,
  updateAppointment,
  deleteAppointment,
}

export default appointmentsService
